import React, { useState } from "react";
import firebase from '../../../../config/firebase'
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { setFieldsForRace as setFieldsForRaceInRedux } from '../../../../actions/racesByOrgId'
// import { incrementPendingRequests, decrementPendingRequests } from '../../../actions/requestsPending'
import ImageInputNoCrop from './ImageInputNoCrop'


const RaceImages = ({ match, settings, race, setFieldsForRaceInRedux }) => {
    const { handleSubmit } = useForm();

    const { racecode } = match.params;
    const [imageURL, setImageURL] = useState('');
    

    const orgId = settings.currentOrgId;

    
    // const page = "Race Edit";
    // const raceDateStart = race && moment(race.RaceDateStart);
    // const raceDateStartTime = raceDateStart && raceDateStart.format("dddd, MMMM Do YYYY, h:mm:ss a");
    // const raceDateStartTime = raceDateStart && raceDateStart.format("MM/DD/YYYY hh:mm:ss");
    // console.log("raceDateStartTime",raceDateStartTime);
    // const raceYear = raceDateStart && raceDateStart.year();



    const onSubmit = data => {
        // console.log('data.RaceDateStart:', data.RaceDateStart);
        // console.log('moment(data.RaceDateStart)', moment(data.RaceDateStart));
        // console.log('moment(data.RaceDateStart).toDate().toISOString():', moment(data.RaceDateStart).toISOString());
        
        
        // this.props.incrementPendingRequests();
        
        // const tmpMomentRaceDateStart = moment(data.RaceDateStart);
        
        const sponsorImages = [
            {
                caption: 'Race Logo',
                // src: 'https://firebasestorage.googleapis.com/v0/b/my-race-com-portal.appspot.com/o/mvm-ma-2010%2Fimages%2Fracelogo?alt=media&token=298923bd-fcbe-47a9-8bc3-7520f9230001',
                src: imageURL,
                altText: race.RaceName,
                linkURL: race.RaceWebsiteURL ? race.RaceWebsiteURL : '',
                tabIndex: -1,
                role: 'top'
            }
        ];
        
        const updateData = {
            RaceCode: racecode,
            sponsorImages
        }
        console.log('submitted data:', updateData);
        
        const funcUpdateRaceSponsorImages = firebase.functions().httpsCallable('updateRaceSponsorImages');
        funcUpdateRaceSponsorImages({ orgId, updateData }).then(result=>{
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // this.props.decrementPendingRequests();
            console.log('funcUpdateRaceSponsorImages result:', racecode, result.data.data);
            toast("Race Sponsor Images Saved!");
            setFieldsForRaceInRedux({ orgId, RaceCode: racecode, payload: updateData })
            // dispatch({ type: SET_DATA, racecode, data: result.data.data  });
        }).catch(e => {
            // this.props.decrementPendingRequests();
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.warn('error making request in funcUpdateRace',e)
        });
        
    }

    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>




            <ImageInputNoCrop 
                race={race} 
                setImageURL={setImageURL}
            />

            

            <div className="buttons">
                <button value="continue" type="submit">Save</button>
            </div>
            
        </form>
    )
}


const mapState = ({settings, racesByOrgId})=>({settings, racesByOrgId});
const mapDispatch = ({ setFieldsForRaceInRedux, 
    //incrementPendingRequests, // <- this.props.incrementPendingRequests()
    // decrementPendingRequests 
});


export default withRouter(connect(mapState, mapDispatch)(RaceImages));
