import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});


export const SET_IMAGE_URL = 'RACE_IMAGES/SET_IMAGE_URL'

export default (state = initialState.raceImages, action) => {
    switch (action.type) {
        case SET_IMAGE_URL: {

            return update(state, {
                [action.RaceCode]: { $auto: {
                    main: { $set: action.main },
                }}
            });

        }
        default: 
            return state;
    }
};