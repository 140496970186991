import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});



export const SET_STATUS = "TIMING_DATASETS/SET_STATUS";
export const SET_DATA = "TIMING_DATASETS/SET_DATA";
export const SET_PROPERTIES = "TIMING_DATASETS/SET_PROPERTIES";
// export const SET_NAME = "TIMING_DATASETS/SET_NAME";


export default (state = initialState.timingDatasets, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.id
        // expected: action.requestStatus

        return update(state, {
            [action.id]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_DATA: {
        // expected: action.id
        // expected: action.data

        return update(state, {
            [action.id]: { $auto: {
                requestStatus: { $set: 'received' },
                data: { $set: action.data },
            }}
        });
    }

    case SET_PROPERTIES: {
        // EXPECTED: action.id
        // expected: action.payload - object - fields and values to update

        let updatedData = {...state[action.id].data, ...action.payload};

        return update(state, {
            [action.id]: { $auto: {
                data: { $set: updatedData },
            }}
        });
    }



    // const currentStepIndex = steps.findIndex(step => step.current);

    // const setStepProperties = (stepIndex, properties) => setSteps(steps => [
    //     ...steps.slice(0, stepIndex),
    //     {...steps[stepIndex], ...properties},
    //     ...steps.slice(stepIndex+1)
    // ]);

    // const setCurrentStep = (newCurrentStepIndex) => {
    //     setSteps(steps => steps.map((step,index) => {
    //         if(index!==newCurrentStepIndex && step.current) return {...step, current: false};
    //         if(index===newCurrentStepIndex && !step.current) return {...step, current: true};
    //         return step;
    //     }));
    // }

    // const goToNextStep = () => {
    //     setStepProperties(currentStepIndex, { completed: true });
    //     setStepProperties(currentStepIndex+1, { available: true });
    //     setCurrentStep(currentStepIndex+1)
    // }
    // const goToPrevStep = () => setCurrentStep(currentStepIndex-1);
    
    
    

    // case SET_NAME: {
    //     // expected: action.id
    //     // expected: action.name

    //     return update(state, {
    //         [action.id]: { $auto: {
    //             requestStatus: { $set: 'received' },
    //             // data: { $set: action.data },
    //             data: { name: {
    //                 $set: action.name
    //             } },
    //         }}
    //     });
    // }

        
    default:
      return state;
  }
};
