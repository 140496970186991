import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
// import firebase from 'firebase/app'
import {auth} from '../../config/firebase'
import {withRouter} from 'react-router-dom'
import { bindActionCreators } from "redux";
import { initBeacon } from '../../actions/fbuser'
import { connect } from 'react-redux'
import steeltoe from 'steeltoe';
import * as orgActions from '../../actions/org';
import imgHelp from '../../images/question-03.svg';
import imgHelpHover from '../../images/question-01.svg';
import imgWhatIs from '../../images/more-03.svg';
import imgWhatIsHover from '../../images/more-01.svg';
// import imgMail from '../../images/mail-03.svg';
// import imgMailHover from '../../images/mail-01.svg';
import imgFeedback from '../../images/hotjar-03.svg';
import imgFeedbackHover from '../../images/hotjar-01.svg';
// import imgFeedback from '../../images/comment-03.svg';
// import imgFeedbackHover from '../../images/comment-01.svg';
// import imgPartner from '../../images/mr-02.svg';
// import imgPartnerHover from '../../images/mr-01.svg';
// import imgRequest  from '../../images/login-05.svg';
// import imgRequestHover  from '../../images/login-03.svg';


const Option = ({title, onClick, primary, secondary, bold, icon, iconHover}) => (
    <div className={classNames("Option", {primary, secondary, bold})} onClick={onClick}>
        {icon && (
            <>
            <div className="icon"><img src={icon} alt='MyRace' /></div>
            <div className="icon hover"><img src={iconHover} alt='MyRace' /></div>
            </>
        )}
        <div className="title">{title}</div>
    </div>
)

class DropdownMenu extends Component {
    constructor(props){
        super(props);
        this.dropdownContentEl = React.createRef();
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        this.dropdownEl = window.document.createElement('div');
        window.document.body.appendChild(this.dropdownEl);
    }

    componentDidMount(){
        this.setUp();
    }
    componentDidUpdate(prevProps){
        const { fbuser } = this.props;
        const prevUid = steeltoe(prevProps).get(`fbuser.uid`);
        const uid = fbuser ? fbuser.uid : null;
        if(uid!==prevUid){
          this.setUp();
        }
      }
    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        window.document.body.removeChild(this.dropdownEl);
    }

    setUp() {
        const {accessRolesByUser} = this.props;
        const uid  = this.props.fbuser && this.props.fbuser.uid;
    
        if(uid && !steeltoe(accessRolesByUser).get(`${uid}.requestStatus`)){
          this.props.orgActions.requestOrgs(uid);
        }
      }

    // componentDidUpdate(prevProps){
    //     // if(!prevProps.fbuser && this.props.fbuser){
    //         this.props.onCloseRequest();
    //     // }
    // }
    renderModalCover = () => {
        return (
            ReactDOM.createPortal(
                <div className="LoginDropdownCover" onClick={this.props.onCloseRequest}></div>
            , this.coverEl)
        )
    }

    renderDropdown = () => {
        return (
            ReactDOM.createPortal(
                this.renderDropdownContent()
            , this.dropdownEl)
        )
    }


    openLogin = () => {
        this.props.onCloseRequest();
        this.props.openLoginRequest();
    }
    openSignup = () => {
        this.props.onCloseRequest();
        this.props.openSignupRequest();
    }
    
    openSignupModal = () => {
        this.props.openSignupModal();
        this.props.onCloseRequest();
    }
    openSwitchOrgModal = () => {
        this.props.openSwitchOrgModal();
        this.props.onCloseRequest();
    }

    getCurrentOrgId = () => {
        const { settings, fbuser, accessRolesByUser } = this.props;
        if(!fbuser) return null;
        let currentOrgId = settings.currentOrgId;
  
  
        if(!currentOrgId){
            currentOrgId = steeltoe(accessRolesByUser).get(`${fbuser.uid}.orgs.0.orgId`);
        }
        return currentOrgId;
    }





    handleOption = optionName => e => {
        // console.log('selected:', optionName);
        const {history, location, onCloseRequest, fbuserActions} = this.props;
        // const {fbuserProfile, history, location, onCloseRequest, fbuserActions,
        //     openKeepInTouch,//: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),  
        //     openPartnerWithUs,//: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),  
        //     openRequestRace,//: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),  
        // } = this.props;
        
        onCloseRequest();

        switch(optionName) {
            case 'home':
                history.push('/');
                break;
            case 'about': 
                window.open(`https://about.myrace.com/article/4-what-is-myrace`);
                // onCloseRequest();
                break;
            case 'profile':
                history.push({
                    pathname: '/account/profile',
                    state: { from: location }
                });
                break;
            case 'events':
                history.push({
                    pathname: '/events',
                    state: { from: location }
                });
                break;
            case 'members':
                history.push({
                    pathname: '/admin/members',
                    // state: { from: location }
                });
                break;
            case 'logout':
                auth.signOut();
                history.push('/');
                break;
            case 'help':
                fbuserActions.initBeacon();
        
                // window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
                window.Beacon("open");
                window.Beacon("once", "close", ()=>window.Beacon('destroy'));
                // window.Beacon('348e8d61-f31b-49a6-8dd0-22b721d423f2');
                break;
            case 'feedback':
                window.hj('trigger', 'hotjar-feedback');
                break;
            default:
                // no-op
                break;

        } // switch
    }

    renderDropdownContent = () => {
        const { fbuser, show, 
            anchorEl, 
            accessRolesByUser, settings: { windowW } } = this.props;
        const anchorPos = anchorEl.current.getBoundingClientRect();

        const currentOrgId = this.getCurrentOrgId();

        let left = anchorPos.left + (anchorPos.width/2) - 140;
        let top = anchorPos.top + (anchorPos.height) + 10;

        if(windowW<600){
            left -= 40;
        }
        let style = {
            left,
            top,            
        }

        // console.log('accessRolesByUser:', accessRolesByUser, steeltoe(fbuser).get('uid'));

        const accessRoles = steeltoe(accessRolesByUser).get(`${steeltoe(fbuser).get('uid')}.orgs`) || [];
        

        // // Only show the Members Menu item if their role is owner or admin for this org (need to check all of their accessRole docs for this uid / org)
        // const isOwnerOrAdmin = accessRoles.filter(accessRole => ['admin','owner'].includes(accessRole.role)).length > 0;

        // Get the User ID
        // const currentuseruid = steeltoe(this.props).get('fbuser.uid');
        // Get their access roles
        // const accessRoles = steeltoe(this.props).get(`accessRolesByUser.${currentuseruid}.orgs`) || [];
        
        let accessRolesByUid = {};
        let accessRolesForThisOrgId = [];
        accessRoles.length &&  accessRoles.forEach(accessRole => {
          const { orgId } = accessRole;
          if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
          accessRolesByUid[orgId].push(accessRole);

          if (orgId === currentOrgId) {
            accessRolesForThisOrgId.push(accessRole.role);
          }
        })
    
        const numOrgs = Object.keys(accessRolesByUid).length;
        const isOwner = accessRolesForThisOrgId.includes('owner');
        const isAdmin = accessRolesForThisOrgId.includes('admin');


        return (
            <div
                ref={this.dropdownContentEl}
                className={classNames("DropdownMenu", { show })} style={style}>

                { !fbuser && 
                    ( <>
                        <Option title="LOG IN" onClick={this.openLogin} bold />
                        
                        <Option title="SIGN UP" onClick={this.openSignupModal} bold />
                        
                    </>
                    )
                }

                { fbuser && 
                    ( <>
                        {/* <Option title="My Home" onClick={this.handleOption('home')} /> */}
                        <Option title="Home" onClick={this.handleOption('home')} />
                        <div className="hr spacer" />
                        {(isOwner || isAdmin) && 
                            <Option title="My Members" onClick={this.handleOption('members')} />
                        }
                        <Option title="My Account" onClick={this.handleOption('profile')} />
                    
                        { numOrgs > 1 && 
                            <>
                                <div className="hr spacer" />
                                <Option title="SWITCH ORG" onClick={this.openSwitchOrgModal} bold />
                            </>    
                        }
                        <div className="hr spacer" />
                        <Option title="LOG OUT" onClick={this.handleOption('logout')} bold />
                        
                    </>
                    )
                }

                <div className="hr spacer" />

                <Option title="Help" icon={imgHelp} iconHover={imgHelpHover} secondary onClick={this.handleOption('help')} />
                <Option title="What is MyRace?" icon={imgWhatIs} iconHover={imgWhatIsHover} secondary onClick={this.handleOption('about')} />

                <div className="hr" />

                <Option title="Leave Feedback" icon={imgFeedback} iconHover={imgFeedbackHover} secondary onClick={this.handleOption('feedback')} />


                
                {/* <a href={aboutPageLink} onClick={this.handleOption('about')} className="Option" target="_blank" alt="About MyRace" title="About MyRace" rel="noopener noreferrer">About MyRace</a> */}

            </div>
        )
    }
    
    
    render(){
        const { show } = this.props;

        return (
            <>
            {/* <SignUpModal onClick={this.props.onCloseRequest} /> */}
            
            { show && this.renderDropdown() }
            { show && this.renderModalCover() }
            </>
        )
    }
}



const mapState = ({settings, fbuserProfile, fbuser, accessRolesByUser}) => ({settings, fbuserProfile, fbuser, accessRolesByUser})



const mapDispatch = dispatch => ({
    fbuserActions: bindActionCreators({initBeacon}, dispatch),
    // openAboutThisPage: ()=>dispatch({type:'SET_PAGE_INFO_MODAL_OPEN', open: true}),  
    openSignupModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: true}),
    openSwitchOrgModal: ()=>dispatch({type:'SET_SETTING', isSwitchOrgModalOpen: true}),
    closeSwitchOrgModal: ()=>dispatch({type:'SET_SETTING', isSwitchOrgModalOpen: false}),
    closeSignupModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: false}),
    openKeepInTouch: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),  
    openPartnerWithUs: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),  
    openRequestRace: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),   
    orgActions: bindActionCreators(orgActions, dispatch),
})
export default connect(mapState, mapDispatch)(withRouter(DropdownMenu))
