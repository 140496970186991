import { useEffect } from 'react'
import { useSelector, useDispatch }from 'react-redux'
// import { setSettings } from '../../actions/settings'
import { requestRacesByOrgId } from '../../actions/racesByOrgId';
import { requestRacesAnalyticsByOrgId } from '../../actions/racesAnalyticsByOrgId';
import { requestLogs, setLogs } from '../../actions/logs';
import steeltoe from 'steeltoe'
import initialState from '../../reducers/initialState';




export default function LogsRequestor(){
    const fbuser = useSelector( state=> state.fbuser);
    const fbuserProfile = useSelector( state=> state.fbuserProfile);
    const settings = useSelector( state=> state.settings);
    const racesByOrgId = useSelector( state=> state.racesByOrgId);
    const racesAnalyticsByOrgId = useSelector( state=> state.racesAnalyticsByOrgId);
    const dispatch = useDispatch();

    // GET RACES
    useEffect(()=>{
        // console.log('[LogsRequestor] effect:', fbuser, settings.currentOrgId);

        if(!fbuser || fbuser.isAnonymous) {
            dispatch(setLogs(initialState.logs));
        } else if(fbuser && !fbuser.isAnonymous && settings.currentOrgId && !steeltoe(racesByOrgId).get(`${settings.currentOrgId}.requestStatus`)) {
            // console.log('[LogsRequestor] preparing to request logs...');
            // this.props.racesByOrgIdActions.requestRacesByOrgId(settings.currentOrgId)
            dispatch(requestRacesByOrgId(settings.currentOrgId))
                .then(races => {
                    // console.log("RACES are back in [LogsRequestor]:", races);
                    // EventSeriesCode
                    let eventSeriesCodes = [];
                    races.forEach(race => {
                        if(!eventSeriesCodes.includes(race.EventSeriesCode)) { eventSeriesCodes.push(race.EventSeriesCode) }
                    });
                
                    const isSuperAdmin = (fbuserProfile && fbuserProfile.email === 'angelo@myrace.com') ? true : false;
  
                    // console.log('[LogsRequestor] requesting logs...');
                    dispatch(requestLogs(isSuperAdmin, eventSeriesCodes));
            
                }).catch(err=>{});
        }
    }, [fbuser, settings.currentOrgId]);



    // GET ANALYTICS
    useEffect(()=>{
        // console.log('[LogsRequestor] effect:', fbuser, settings.currentOrgId);



        const races = steeltoe(racesByOrgId).get(`${settings.currentOrgId}.data`);
        // console.log('races:', races, '- get analytics');

        if(!fbuser || fbuser.isAnonymous || !races || !races.length) {
            // dispatch(setLogs(initialState.logs));
            // console.log('races:', races, '- get analytics - cancelled', racesByOrgId);

        } else if(fbuser && !fbuser.isAnonymous && settings.currentOrgId && !steeltoe(racesAnalyticsByOrgId).get('${settings.currentOrgId}.requestStatus')) {
            // console.log('[LogsRequestor] preparing to request logs...');
            // this.props.racesAnalyticsByOrgIdActions.requestRacesAnalyticsByOrgId(settings.currentOrgId)
            
            // console.log('races:', races, '- get analytics - started');
            
            const raceCodes = races.map( race => race.RaceCode );
            
            dispatch(requestRacesAnalyticsByOrgId(settings.currentOrgId, raceCodes));
                // .then(races => {
                //     // console.log("RACES are back in [LogsRequestor]:", races);
                //     // EventSeriesCode
                //     // let eventSeriesCodes = [];
                //     // races.forEach(race => {
                //     //     if(!eventSeriesCodes.includes(race.EventSeriesCode)) { eventSeriesCodes.push(race.EventSeriesCode) }
                //     // });
                
                //     // const isSuperAdmin = (fbuserProfile && fbuserProfile.email === 'angelo@myrace.com') ? true : false;
  
                //     // console.log('[LogsRequestor] requesting logs...');
                //     // dispatch(requestLogs(isSuperAdmin, eventSeriesCodes));
            
                // }).catch(err=>{});
        }
    }, [fbuser, settings.currentOrgId, racesByOrgId]);








    return null;
}
