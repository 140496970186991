import React, {Component} from 'react'
import {connect} from 'react-redux'
// import {auth} from '../../config/firebase'
import axios from 'axios';
import {Redirect} from 'react-router-dom'

class ValidateInvite extends Component {
    state = {
        done: false,
        error: null,
        redirectTo: "/login/signUpFromToken",
    }
    componentDidMount(){
        this.checkForValidToken();
    }

    
    checkForValidToken = () => {

        // this.setState({done:true, redirectTo:'/'});
        //                 this.props.setLoginPopupOpen('login');
        //                 return;

        // QUESTION
        // Instead of this validateInvite page being the landing page from the invite URL, 
        // should we build a Login Page and have it be the landing page?

        // get the token
        const token = this.props.match.params.token;

        // const token = "S4pesn0c";

        axios.get('https://us-central1-my-race-com-portal.cloudfunctions.net/getInviteFromToken', {
        // axios.get('https://myrace.com/validateEmailAgainstHash', {
            params: {
                token
            }
        }).then((response) => {
            // this.props.decrementPendingRequests();
            // const EmailHash = response.data.EmailHash;
            const responseData = response.data;

            // status: 'no invite found'
            // status: 'token expired'
            // status: 'could not write to firestore'
            // status: 'access role assigned to an existing user',
            // status: 'signup allowed',
            
            if(responseData.status){
                this.props.setInviteStatus(responseData.status);
                
                console.log("response",responseData.status);
                switch(responseData.status){
                    case 'access role assigned to an existing user':
                        // redirect login
                        this.setState({done:true, redirectTo:'/'});
                        this.props.setLoginPopupOpen('login');
                        break;
                        
                    case 'signup allowed':
                        // redirect signup
                        this.setState({done:true, redirectTo:'/'});
                        // this.props.setInviteeEmail(responseData.inviteeEmail);
                        const { inviteeEmail, inviteeFirstName, inviteeLastName, invitedFromOrgId, invitedFromOrgName } = responseData;
                        this.props.setSettings({
                            inviteToken: token,
                            inviteeEmail, inviteeFirstName, inviteeLastName, invitedFromOrgId, invitedFromOrgName
                        })
                        this.props.openSignupModal();
                        break;
                    
                    case 'no invite found':
                    case 'token expired':
                    case 'could not write to firestore':
                        // display error, redirect somewhere
                        break;
                    default:
                        break;
                    
                }

                // TODO: Redirect
            }

            
        }, (error) => {
            // this.props.decrementPendingRequests();
            console.log(error);
          });


        // this.setState({ done: true });

    }
    render(){
        const { done, error } = this.state;
        // const { fbuser } = this.props;
        if(error){
            return (
                <div>
                    <p>There was an error trying to confirm email:</p>
                    <p>{error}</p>
                </div>
            )
        }

        if(!done){
            return (
                <div>processing...</div>
            )
        }

        return <Redirect to={{
            pathname: this.state.redirectTo,
            state: { from: this.props.location }
        }} />
    }
}

const mapState = ({fbuser})=>({fbuser})

const mapDispatchToProps = (dispatch) => ({
    setFbUser: user => dispatch({ type: 'SET_FB_USER', user }),
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
    openSignupModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: true}),
    setInviteeEmail: (inviteeEmail) => dispatch({ type: 'SET_SETTING', inviteeEmail }),
    setActionCode: code => dispatch({ type: 'SET_SETTING', actionCode: code }),
    setInviteStatus: inviteStatus => dispatch({ type: 'SET_SETTING', inviteStatus}),
    setSettings: settings => dispatch({ type: 'SET_SETTING', ...settings }),
})

export default connect(mapState, mapDispatchToProps)(ValidateInvite);
  