import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});

export const SET_STATUS = "RACES_ANALYTICS_BY_ORG_ID/SET_STATUS";
export const SET_DATA = "RACES_ANALYTICS_BY_ORG_ID/SET_DATA";


export default (state = initialState.racesAnalyticsByOrgId, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.orgId
        // expected: action.requestStatus

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_DATA: {
        // expected: action.orgId
        // expected: action.members

        if(typeof state[action.orgId].data === 'object' && state[action.orgId].data.length){
            // there's already data eg. state.data[0]._id
            let updatedData = [...state[action.orgId].data];
            action.data.forEach(item => {
                const alreadyInState = updatedData.findIndex( i => i._id === item._id );
                if(alreadyInState>-1){
                    updatedData[alreadyInState] = item;
                } else {
                    updatedData.push(item);

                }
            });
            return update(state, {
                [action.orgId]: { $auto: {
                    requestStatus: { $set: 'received' },
                    data: { $set: updatedData },
                }}
            });
        }


        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: 'received' },
                data: { $set: action.data },
            }}
        });
    }

        
    default:
      return state;
  }
};
