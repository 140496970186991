import {db, auth} from '../config/firebase'
import { SET_STATUS, SET_DATA, SET_NAME } from '../reducers/orgsByIdReducer'


export const setNameForOrg = ({id, name}) => {
    return {
        type: SET_NAME,
        id, name
    }
}

export const requestOrgById = (id) => {
    return (dispatch, getState) => {
        // console.log('[requestOrgById]', id);

        if(!id || !auth.currentUser){
            return;
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});

        dispatch({ type: SET_STATUS, id, requestStatus: 'pending' });

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error reading Org document: ", e);
            dispatch({ type: SET_STATUS, id, requestStatus: 'error' });
        };

        const handleSuccess = snap => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // console.log("Got Org by id data!", id, snap);

            const data = {...snap.data(), id: snap.id};
            
            dispatch({ type: SET_DATA, id, data  });
        }

        
        db
            .collection("orgs")
            .doc(id)
            .get()
            .then(handleSuccess)
            .catch(handleError);
        
    }
}