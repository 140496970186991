import React from "react";
// import steeltoe from 'steeltoe'
// import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
// import classNames from 'classnames'

import { optionCheckboxesAndGroups } from './Step3'
import { getCheckpoints } from './Step4'
// const optionCheckboxesThatNeedMapping = optionCheckboxes.filter(o => !!o.mayNeedMapping);


const tryToFindMatchingField = (csvHeaderFields, fieldName, race) => {
    // console.log('[tryToFindMatchingField]', fieldName, csvHeaderFields)
    let matches = {
        Bib: ['bib','number','no.'],
        FirstName: ['first', 'first name', 'given name', 'first_name','fname','firstname'],
        LastName: ['last', 'last name', 'surname','lastname','last_name','lname','lastname'],
        Age: ['age','race_age','race age'],
        Suffix: ['suffix'],
        Email: ['email','email address','email_address','emailaddress'],
        Gender: ['gender','sex'],
        City: ['city','hometowncity','hometown city'],
        // State: ['state','state_code','state code','statecode','region','regioncode','region_code','region code'],
        StateFullName: ['state','state_code','state code','statecode','region','regioncode','region_code','region code','st','hometownstate','hometown state'],
        StateCode: ['state','state_code','state code','statecode','region','regioncode','region_code','region code','st','hometownstatecode'],
        MilitaryCodeFullName: ['state','state_code','state code','statecode','region','regioncode','region_code','region code'],
        MilitaryCode: ['state','state_code','state code','statecode','region','regioncode','region_code','region code'],
        // Country: ['country','countrycode','country code','country_code','countryname','country name','country_name'],
        CountryFullName: ['country','countrycode','country code','country_code','countryname','country name','country_name','cntry'],
        CountryTwoCharCode: ['country','countrycode','country code','country_code','countryname','country name','country_name','hometowntwocharcountrycode'],
        CountryThreeCharCode: ['country','countrycode','country code','country_code','countryname','country name','country_name'],
        ProvinceFullName: ['province'],
        ProvinceCode: ['province'],
        // OtherRegionCode: [],

        DateOfBirth: ['dateofbirth','date of birth','date_of_birth','dob'],
        FinishTime: ['scoringtime_rounded','scoringtime','scoring time','scoring_time','finish','chip','finish time','finishtime','finish_time','finish net time','finish_net_time','chip time','chiptime','chip_time','scoringtime'],
        SwimTime: ['swim','swimtime','swim time','swim_time'],
        T1Time: ['t1','t1time','t1 time','t1_time'],
        BikeTime: ['bike','biketime','bike time','bike_time'],
        T2Time: ['t2','t2time','t2 time','t2_time'],
        RunTime: ['run','runtime','run time','run_time'],
        FinishTime: ['scoringtime_rounded','scoringtime','scoring time','scoring_time','finish','chip','finish time','finishtime','finish_time','finish net time','finish_net_time','chip time','chiptime','chip_time','scoringtime','time'],
        PenaltyTime: ['penalty','penaltytime','penalty time','penalty_time'],
        DQ: ['dq','disqualified'],
        GunTime: ['gun time','guntime','gun_time','gun finish','gun_finish','gun finish time','gun_finish_time'],
        StartTimeOfDay: ['start time of day','starttod','start tod','start_tod','starttime_tod','starttime tod','starttime','start time','start_time'],
        PrimaryBracket: ['primary_bracket', 'division','category','code'],
        // '5 km': ['5k net time'],
        Half: [`half ${race.RaceDistance.toLowerCase()} net time`]
    };


    if(!matches[fieldName]) matches[fieldName] = [];
    matches[fieldName] = [ ...matches[fieldName],
        `${fieldName} net time`, 
        `${fieldName}_net_time`,
        `${fieldName.replace(' km', 'k')} net time`,
        `${fieldName.replace(' mi', 'mi')} net time`,
        `${fieldName.replace(' mi', 'm')} net time`,
        `${fieldName.replace(' mi', ' mile')} net time`,
    ];
    // console.log('matches', fieldName, matches[fieldName]);



    if(matches[fieldName]){
        for(let i = 0; i < matches[fieldName].length; i++) {
            const suggestedFieldName = matches[fieldName][i];
            // console.log('suggestedFieldName:', suggestedFieldName);

            for(let j = 0; j < csvHeaderFields.length; j++){
                const csvHeaderField = csvHeaderFields[j];
                // console.log('csvHeaderField:', csvHeaderField);
                if(csvHeaderField.toLowerCase().trim() === suggestedFieldName.trim()){
                    // console.log('FOUND MATCH:', csvHeaderField)
                    return csvHeaderField;
                }
            }
        }
    }
}

const FieldMapDropdown = ({ field, register, timingDataset, csvHeaderFields, race, step}) => {
    return (
        <div key={field.name}>
            <label>
                <p>{field.title} {!field.required && `(optional)`}</p>
                <select 
                    // style={{display:'inline', width: 20}} 
                    disabled={!!step.completed}
                    name={`map_field_${field.name}`} 
                    type="checkbox" 
                    ref={register} 
                    defaultValue={ timingDataset[`map_field_${field.name}`] || tryToFindMatchingField(csvHeaderFields, field.name, race)}
                    // defaultChecked={!!timingDataset.TimingDataOption1}
                    >
                        <option></option>
                        {csvHeaderFields.map(csvField => {
                            return (
                                <option key={csvField} value={csvField}>{csvField}</option>
                            )
                        })}
                </select>
            </label>
        </div>
    )
}



export const getFileFields = (o) => {
    const {raceDiscipline} = o;

    let fields = [
        { name: 'Bib', title: 'Bib', required: true, raceDisciplines: ['All'] },
        { name: 'FirstName', title: 'First Name', required: true, raceDisciplines: ['All'] },
        { name: 'LastName', title:'Last name', required: true, raceDisciplines: ['All'] },
        { name: 'Age', title:'Age', required: true, raceDisciplines: ['All']},
        { name: 'Gender', title: 'Gender', required: true, raceDisciplines: ['All']},
        { name: 'City', title: 'City', required: true, raceDisciplines: ['All']},
        // { name: 'State', title: 'State/Region', required: true, },
        // { name: 'Country', title: 'Country/CountryCode', required: true, },
        { name: 'Email', title: 'Email Address', required: true, raceDisciplines: ['All']},
        { name: 'DateOfBirth', title:'Date of Birth', required: false, raceDisciplines: ['All']},
        { name: 'SwimTime', title: 'Swim Time', required: true, raceDisciplines: ['Triathlon']},
        { name: 'T1Time', title: 'T1 Time', required: true, raceDisciplines: ['Triathlon']},
        { name: 'BikeTime', title: 'Bike Time', required: true, raceDisciplines: ['Triathlon']},
        { name: 'T2Time', title: 'T2 Time', required: true, raceDisciplines: ['Triathlon']},
        { name: 'RunTime', title: 'Run Time', required: true, raceDisciplines: ['Triathlon']},
        { name: 'PenaltyTime', title: 'Penalty Time', required: false, raceDisciplines: ['Triathlon']},
        { name: 'DQ', title: 'DQ', required: false, raceDisciplines: ['Triathlon']},

        { name: 'FinishTime', title: 'Finish (Scoring) Time', required: true, raceDisciplines: ['All']},
        { name: 'GunTime', title: 'Gun Time', required: false, raceDisciplines: ['All']},
        { name: 'StartTimeOfDay', title: 'Start Time of Day', required: false, raceDisciplines: ['All']},
    ];


    return fields.filter(function(field) {
        return field.raceDisciplines.includes(raceDiscipline) || field.raceDisciplines.includes('All');
    });
}

const Step5 = ({race, goToNextStep, goToPrevStep, timingDataset, currentTimingDatasetId, update, step}) => {
    const { register, handleSubmit } = useForm();
    const { csvHeaderRow } = timingDataset;

    const checkpointsSwim = getCheckpoints(timingDataset,'swim');
    console.log("checkpointsSwim",checkpointsSwim)
    const checkpointsBike = getCheckpoints(timingDataset,'bike');
    const checkpointsRun = getCheckpoints(timingDataset,'run');
    // console.log("checkpointsSwim",checkpointsSwim)
    // console.log("checkpoints step5",checkpoints)

    const fields = getFileFields({raceDiscipline: race.RaceDiscipline, fieldType: 'mapping'});
    console.log("fields returned",fields)

    const csvHeaderFields = csvHeaderRow.split(',');

    const onSubmit = data => {
        console.log('STEP 5 - submitted data:', data);

        // const { TimingDataOption1 } = data;

        update(data);

        goToNextStep();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>


            { fields.map(field => {
                if (field.raceDisciplines.includes('All') || field.raceDisciplines.includes(race.RaceDiscipline)) {
                    return <FieldMapDropdown key={field.name} {...{ field, register, timingDataset, csvHeaderFields, race, step}} />
                }
            })}

            { checkpointsSwim && checkpointsSwim.length > 0 && <div>
                <p>Swim Checkpoints</p>
                {checkpointsSwim.map(checkpoint => {
                    const field = { name: checkpoint, title: checkpoint, required: true };
                    console.log("field",field)
                    return <FieldMapDropdown key={field.name} {...{ field, register, timingDataset, csvHeaderFields, race, step}} />
                })}
            </div> }


            { checkpointsBike && checkpointsBike.length > 0 && <div>
                <p>Bike Checkpoints</p>
                {checkpointsBike.map(checkpoint => {
                    const field = { name: checkpoint, title: checkpoint, required: true };
                    console.log("field",field)
                    return <FieldMapDropdown key={field.name} {...{ field, register, timingDataset, csvHeaderFields, race, step}} />
                })}
            </div> }


            { checkpointsRun && checkpointsRun.length > 0 && <div>
                <p>Run Checkpoints</p>
                {checkpointsRun.map(checkpoint => {
                    const field = { name: checkpoint, title: checkpoint, required: true };
                    console.log("field",field)
                    return <FieldMapDropdown key={field.name} {...{ field, register, timingDataset, csvHeaderFields, race, step}} />
                })}
            </div> }


            { optionCheckboxesAndGroups.filter(
                opt => opt.mayNeedMapping && timingDataset[`_needs_mapping_${opt.name}`]
            ).map( opt=> {
                const field = { name: opt.name, title: opt.title, required: true };
                return <FieldMapDropdown key={field.name} {...{ field, register, timingDataset, csvHeaderFields, race, step}} />
            }) }




            <div className="buttons">
                <button onClick={goToPrevStep}>Previous</button>
                <button type="submit">Next</button>
            </div>
        </form>
    )
}
export default Step5