import React, { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from 'classnames'
import { connect } from 'react-redux'
import firebase, {storage} from '../../../../config/firebase'
import { incrementPendingRequests, decrementPendingRequests } from '../../../../actions/requestsPending'


export const getStorageFilePath = (RaceCode, version=1) => `${RaceCode}/data-set-${version}`;

const Step1 = ({race, goToNextStep, timingDataset, currentTimingDatasetId, update}) => {
    const { handleSubmit } = useForm();
    const [fileIsUploading, setFileIsUploading] = useState(false);
    // const [uploadedFile, setUploadedFile] = useState(false);

    const onSubmit = data => {
        if(fileIsUploading) return;

        console.log('STEP 1 - submitted data:', data);
        incrementPendingRequests()

        const timingDataFile = getStorageFilePath(race.RaceCode, 1/*TODO: set to version dynamically */);

        // call the backend function that will process the uploaded file
        const funcProcessUploadedTimingFile = firebase.functions().httpsCallable('processUploadedTimingFile');
        funcProcessUploadedTimingFile({ timingDataFile, timingDatasetId: currentTimingDatasetId}).then(result=>{
            console.log('processUploadedTimingFile result:', race.RaceCode, result.data);
        }).catch(e => {
            console.warn('error calling backend function',e)
        });

        goToNextStep();
        decrementPendingRequests();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            
            {timingDataset.timingDataFile ? (
                <div>
                    File's uploaded!
                </div>
            ):null}

            { !timingDataset.step_1_completed ? <input type="file" 
                disabled={fileIsUploading}
                onChange={e => {
                    const file = e.target.files && e.target.files[0];
                    console.log('FILE', file );

                    // const reader = new FileReader();
                    // reader.onload = ev => {
                    //     const text = ev.target.result;
                    //     const header = text.split('\n')[0];
                    //     const columns = header.split(',');
                    //     console.log('filecontents', columns);
                    // }
                    // reader.readAsText(file, 'UTF-8');

                    
                    setFileIsUploading(true);
                    const storagePath = getStorageFilePath(race.RaceCode, 1/*TODO: set to version dynamically */);//`${race.RaceCode}/data-set-1`;
                    storage.ref().child(storagePath).put(file)
                        .then(snap =>{
                            setFileIsUploading(false);
                            console.log('done uploading file');
                            update({ timingDataFile: storagePath });
                        })
                        .catch(err => {
                            setFileIsUploading(false);
                            console.log('error uploading file:', err);
                        });
                }} 
            /> : null }


            <div className="buttons">
                <button disabled>Previous</button>
                <button disabled={fileIsUploading} className={classNames({ disabled: fileIsUploading })} type="submit">Next</button>
            </div>
        </form>
    )
}

const mapDispatch = ({
    incrementPendingRequests, // <- this.props.incrementPendingRequests()
    decrementPendingRequests 
})



export default connect(null, mapDispatch)(Step1)

// export default Step1