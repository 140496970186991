import React, {Component} from 'react'
import {connect} from 'react-redux'


class ResetPassword extends Component {
    state = {
        done: false,
        error: null
    }
    
    
    render(){
        const { done } = this.state;


        if(!done){
            return (
                <div>processing...</div>
            )
        }

        return (
            <div>Done</div>
        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    setFbUser: user => dispatch({ type: 'SET_FB_USER', user }),
})

export default connect(null, mapDispatchToProps)(ResetPassword);
  