import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});

export const SET_STATUS = "RACES_BY_ORG_ID/SET_STATUS";
export const SET_DATA = "RACES_BY_ORG_ID/SET_DATA";
export const SET_CHECKPOINTS = "RACES_BY_ORG_ID/SET_CHECKPOINTS";
export const SET_FIELDS_FOR_RACE = "RACES_BY_ORG_ID/SET_FIELDS_FOR_RACE";


export default (state = initialState.racesByOrgId, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.orgId
        // expected: action.requestStatus

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_DATA: {
        // expected: action.orgId
        // expected: action.members

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: 'received' },
                data: { $set: action.data },
            }}
        });
    }

    case SET_CHECKPOINTS: {
        // expected: action.orgId
        // expected: action.RaceCode
        // expected: action.payload - array of Checkpoints - []


        // state.[orgId].data.[index where RaceCode matches].Checkpoints <- update this with action.payload

        const raceIndex = state[action.orgId].data.findIndex( race => race.RaceCode === action.RaceCode);

        return update(state, {
            [action.orgId]: { $auto: {
                data: {
                    [raceIndex]: {
                        Checkpoints: {
                            $set: action.payload
                        }
                    }
                },
            }}
        });
    }

    

    case SET_FIELDS_FOR_RACE: {
        // expected: action.orgId
        // expected: action.RaceCode
        // expected: action.payload - object - fields and values to update


        // state.[orgId].data.[index where RaceCode matches].Checkpoints <- update this with action.payload

        const raceIndex = state[action.orgId].data.findIndex( race => race.RaceCode === action.RaceCode);

        let updatedRace = {...state[action.orgId].data[raceIndex], ...action.payload};

        return update(state, {
            [action.orgId]: { $auto: {
                data: {
                    [raceIndex]: {
                        $set: updatedRace
                    }
                },
            }}
        });
    }



        
    default:
      return state;
  }
};
