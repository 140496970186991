import React, { useEffect } from 'react'
import './TimingDataUpload.scss'
import classnames from 'classnames'
import throttle from 'lodash.throttle'
import firebase from '../../../../config/firebase'
import { connect } from 'react-redux'
import { updateTimingDataset, getLatestTimingDataset, setSteps } from '../../../../actions/timingDatasets'
import { incrementPendingRequests, decrementPendingRequests } from '../../../../actions/requestsPending'

import {getCurrentTimingDataset} from '../../../../selectors/getCurrentTimingDataset'
import TimingDocListener from './TimingDocListener'

import { getStorageFilePath } from './Step1'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'

import './Container.scss'





// const persistSteps = throttle((steps, race, dataSetVersion=1)=>{
//     console.log('[persistSteps]', steps);
//     updateStepsDoc({steps}, race, dataSetVersion);
// }, 500);


// const updateStepsDoc = async (properties, race,  dataSetVersion=1) => {
//     const ref = db.collection('timingSetSteps').doc(`${race.RaceCode}-${dataSetVersion}`);
//     try {
//         await ref.update(properties);
//     } catch (err) {
//         console.log('[updateStepsDoc]', err);
//     }
// }


const StepTab = ({ index, currentStepIndex, step, onClick }) => {
    return (
        <li 
            onClick={onClick}
            className={classnames({
            active: index === currentStepIndex,
            available: step.available,
            completed: step.completed
        })}>{step.name}</li>
    )
}

const TimingDataUploadContainer = ({ race, timingDataset, getLatestTimingDataset,incrementPendingRequests,decrementPendingRequests, updateTimingDataset, settings: {currentTimingDatasetId} }) => {
    // const [activeStep, setActiveStep] = useState(0);
    // const [steps, setSteps] = useState(null);

    useEffect(()=>{
        // get initial steps data from firestore
        getLatestTimingDataset(race.RaceCode);
    },[]);


    if(!currentTimingDatasetId || !timingDataset) return (
        <div>... loading ...</div>
    )


    const stepProperties = Object.keys(timingDataset).filter(p=>p.startsWith('step_'));
    const steps = [];
    stepProperties.forEach(prop =>{
        // step_1_name: 'Step 1',
        // step_1_available: true,
        // step_1_completed: false,
        // step_1_current: true,
        const [xx, stepNum, propName] = prop.split('_');
        const index = +stepNum - 1;
        if(!steps[index]) steps[index] = {};
        steps[index][propName] = timingDataset[prop];
    }, [])
    
    // const { steps } = timingDataset;

    const update = data => updateTimingDataset(data, currentTimingDatasetId);

    const currentStepIndex = steps.findIndex(step => step.current);

    const currentStepProp = stepProperties.find( prop => prop.endsWith('_current') && timingDataset[prop] );
    let [st, currentStep] = currentStepProp ? currentStepProp.split('_') : [];
    currentStep = +currentStep;

    const goToNextStep = () => update({
        [`step_${currentStep}_current`]: false,
        [`step_${currentStep}_completed`]: true,
        [`step_${currentStep+1}_current`]: true,
        [`step_${currentStep+1}_available`]: true,
    })

    const goToPrevStep = () => update({
        [`step_${currentStep-1}_current`]: true
    });

    const setCurrentStep = newCurrentStepIndex => update({
        [`step_${currentStep}_current`]: false,
        [`step_${newCurrentStepIndex+1}_current`]: true,
    });

    const reset = () => {
        const confirmed = window.confirm('Are you sure?');
        if(!confirmed) return;

        const timingDataFile = getStorageFilePath(race.RaceCode, 1/*TODO: set to version dynamically */);

        incrementPendingRequests()
        
        const funcDeleteTimingDataset = firebase.functions().httpsCallable('deleteTimingDataset');
        funcDeleteTimingDataset({ timingDataFile, timingDatasetId: currentTimingDatasetId}).then(result=>{
            console.log('deleteTimingDataset result:', result.data);
            decrementPendingRequests()
            window.location.reload();
        }).catch(e => {
            decrementPendingRequests()

            console.warn('error calling backend function',e)
        });
        
    }
    const resetToAfterFileLoaded = () => {
        const confirmed = window.confirm('Are you sure?');
        if(!confirmed) return;

        incrementPendingRequests()
        
        const funcDeletePartialTimingDataset = firebase.functions().httpsCallable('deletePartialTimingDataset');
        funcDeletePartialTimingDataset({ timingDatasetId: currentTimingDatasetId}).then(result=>{
            console.log('deletePartialTimingDataset result:', result.data);
            decrementPendingRequests()
            window.location.reload();
        }).catch(e => {
            decrementPendingRequests()

            console.warn('error calling backend function',e)
        });
        
    }
    

    // const setStepProperties = (stepIndex, properties) => setSteps([
    //     ...steps.slice(0, stepIndex),
    //     {...steps[stepIndex], ...properties},
    //     ...steps.slice(stepIndex+1)
    // ], currentTimingDatasetId);

    // const setCurrentStep = (newCurrentStepIndex) => {
    //     setSteps(steps.map((step,index) => {
    //         if(index!==newCurrentStepIndex && step.current) return {...step, current: false};
    //         if(index===newCurrentStepIndex && !step.current) return {...step, current: true};
    //         return step;
    //     }), currentTimingDatasetId);
    // }

    // const goToNextStep = () => {
    //     setStepProperties(currentStepIndex, { completed: true });
    //     setStepProperties(currentStepIndex+1, { available: true });
    //     setCurrentStep(currentStepIndex+1)
    // }
    // const goToPrevStep = () => setCurrentStep(currentStepIndex-1);


    return (
        <div>
            <div className="buttons">
                <button className="alert" onClick={reset}>FULL RESET</button>
                <button className="alert" onClick={resetToAfterFileLoaded}>RESET TO STEP 3</button>
            </div>
            
            <TimingDocListener timingDatasetId={currentTimingDatasetId}/>
            <ul className="stepTabs">
                {steps.map( (step,index) => {
                    const onClick = () => {
                        if(step.available) setCurrentStep(index);
                    }
                    return (
                        <StepTab key={index} {...{index, currentStepIndex, step, onClick}} />
                    )
                })}
            </ul>

            {(()=>{
                const stepProps = {goToNextStep, goToPrevStep, race, timingDataset, currentTimingDatasetId, update};

                switch (currentStepIndex){
                    case 0: return <Step1 {...stepProps} step={steps[0]} />
                    case 1: return <Step2 {...stepProps} step={steps[1]} />
                    case 2: return <Step3 {...stepProps} step={steps[2]} />
                    case 3: return <Step4 {...stepProps} step={steps[3]} />
                    case 4: return <Step5 {...stepProps} step={steps[4]} />
                    case 5: return <Step6 {...stepProps} step={steps[5]} />
                    case 6: return <Step7 {...stepProps} step={steps[6]} />
                    case 7: return <Step8 {...stepProps} step={steps[7]} />
                    case 8: return <Step9 {...stepProps} step={steps[8]} />
                    default: return <div>...</div>;
                }
            })()}
            
            
        </div>
    )
}

const mapState = (state) => ({
    settings: state.settings,
    timingDataset: getCurrentTimingDataset(state)
})
const mapDispatch = ({
    updateTimingDataset, 
    getLatestTimingDataset, 
    setSteps,
    incrementPendingRequests, // <- this.props.incrementPendingRequests()
    decrementPendingRequests 
})



export default connect(mapState, mapDispatch)(TimingDataUploadContainer)