import React, { useEffect } from 'react'
import firebase from '../../config/firebase'
import { Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { useForm, ErrorMessage } from "react-hook-form";
import steeltoe from 'steeltoe'
import { connect } from 'react-redux'
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import Headroom from 'react-headroom';
import classNames from 'classnames'
import moment from "moment";
import { toast } from 'react-toastify';
import LogsPerDay from './LogsPerDay'
import LogsPerDayZoomable from './LogsPerDayZoomable'
import RaceCodeFilters from './RaceCodeFilters';
import CheckpointAnalyticsMissing from './CheckpointAnalyticsMissing';
import {
    LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell
  } from 'recharts';

import "./Analytics.scss";


const CheckpointAnalytics = ({ match, history, settings, racesByOrgId, racesAnalyticsByOrgId }) => {
        
    const page = "YourRace Analytics";
    const orgId = steeltoe(settings).get(`currentOrgId`);
    const races = steeltoe(racesByOrgId).get(`${orgId}.data`);
    const raceCodeFilterOption = steeltoe(settings).get(`raceCodeFilterOption`);
    const raceAnalytics = steeltoe(racesAnalyticsByOrgId).get(`${orgId}.data`);
    
    const raceCode = raceCodeFilterOption && raceCodeFilterOption[0];

    let raceAnalyticForRaceCode = null;

    let checkpointAnalytics = null;
    let countRegistered = 0;
    let countNotRegistered = 0;
    let checkpointAnalyticsMissedNotMissed = {};
    checkpointAnalyticsMissedNotMissed.Checkpoints=[];
    
    if (raceCode && raceAnalytics) {
        raceAnalyticForRaceCode = raceAnalytics.find(r=>r._id === raceCode);
        if (raceAnalyticForRaceCode && raceAnalyticForRaceCode.checkpointsAnalysis) {
            raceAnalyticForRaceCode.checkpointsAnalysis.Checkpoints.splice(-1,1);
            checkpointAnalytics = raceAnalyticForRaceCode.checkpointsAnalysis;


            countRegistered = checkpointAnalytics.Checkpoints.reduce(function (accumulator, checkpoint) {
                return accumulator + checkpoint.TotalAthletesRegistered;
              }, 0);
            countNotRegistered = checkpointAnalytics.Checkpoints.reduce(function (accumulator, checkpoint) {
                return accumulator + checkpoint.TotalAthletesMissed;
              }, 0);

            checkpointAnalyticsMissedNotMissed.Checkpoints.push({
                
                LabelToUse: 'Missed',
                TotalAthletesMissed: countNotRegistered,
            })
            checkpointAnalyticsMissedNotMissed.Checkpoints.push({
                LabelToUse: 'Registered',
                TotalAthletesMissed: countRegistered,
            })
        }

    }

    // checkpointAnalytics && console.log("checkpointAnalytics",checkpointAnalytics);
    // checkpointAnalyticsMissedNotMissed && console.log("checkpointAnalyticsMissedNotMissed",checkpointAnalyticsMissedNotMissed);

    const toPercent = (tickItem) => {
        return parseFloat(tickItem).toFixed(2)+"%";
      
      }

    let getValue = (x)=>{
        // console.log("x",x);
         return x.PercentAthletesMissed * 100;
    }


    
    return (

        <div className="SelectRace">
            
            
            <Headroom>
                <Header title={`Analytics`} page={page}/>
            </Headroom>


            <div className="AnalyticsContent">
                <div className="container">

                    <RaceCodeFilters races={races} />

                    <h2>Analytics</h2>
                    


                    {(checkpointAnalytics && checkpointAnalytics.Checkpoints) ?
                    (
                        <>
                            <p>Checkpoints for this Race (not including finish):</p>
                            <p>
                            {   checkpointAnalytics.Checkpoints.map((checkpoint, i) => {
                                return (i==0 ? 
                                    (parseFloat(checkpoint.CheckpointDistanceMi).toFixed(2) + ' mi')
                                    
                                    :
                                    (', ' + parseFloat(checkpoint.CheckpointDistanceMi).toFixed(2) + ' mi')
                                )
                                })
                            }

                            </p>
                        </>
                    )
                      : null
                    }

              

                    { checkpointAnalytics && checkpointAnalytics.Checkpoints && countNotRegistered == 0 && 
                         <p>All {countRegistered} Athlete Checkpoints were Successfully Registered.</p>
                     }

                    { countNotRegistered > 0 && 
                    <>
                        <CheckpointAnalyticsMissing chartTitle='Checkpoints Registered vs. Missed' checkpointAnalytics={checkpointAnalyticsMissedNotMissed} />
                        <CheckpointAnalyticsMissing chartTitle='Total athletes did not register per Checkpoint' checkpointAnalytics={checkpointAnalytics} />
                    </> 
                    }

                  


                    { countNotRegistered > 0 && checkpointAnalytics && checkpointAnalytics.Checkpoints && 
                    <>
                        <h3>Total athletes that did not register per Checkpoint</h3>
                        <BarChart
                            width={1200}
                            height={300}
                            data={checkpointAnalytics.Checkpoints}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            {/* <XAxis dataKey={  'welcome'  } tick={{fontSize: 10}} interval={0} /> */}
                            <XAxis dataKey="CheckpointDistanceMi" tick={{fontSize: 10}} interval={0} />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="TotalAthletesMissed" fill="#8884d8" />
                        </BarChart>
                    </>
                    }
                    { countNotRegistered > 0 && checkpointAnalytics && checkpointAnalytics.Checkpoints && 
                    <>
                        <h3>% of total athletes that did not register per Checkpoint</h3>
                        <BarChart
                            width={1200}
                            height={300}
                            data={checkpointAnalytics.Checkpoints}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            {/* <XAxis dataKey={  'welcome'  } tick={{fontSize: 10}} interval={0} /> */}
                            <XAxis dataKey="CheckpointDistanceMi" tick={{fontSize: 10}}  interval={0} />
                            {/* <YAxis tickFormatter={toPercent} /> */}
                            <YAxis />
                            {/* <Tooltip /> */}
                            {/* <Legend /> */}
                            {/* <Bar dataKey="PercentAthletesMissed" fill="#8884d8" /> */}
                            <Tooltip formatter={toPercent} />
                            <Bar dataKey={getValue} fill="#8884d8" />
                        </BarChart>
                    </>
                    }
                    
                    {/* { checkpointAnalytics && checkpointAnalytics.Checkpoints &&
                        
                            checkpointAnalytics.Checkpoints.map((checkpoint, i) => (
                                <>
                                    <p>Checkpoint: {i}</p>
                                    <p>Checkpoint: {checkpoint.CheckpointDistanceMi} mi ({checkpoint.CheckpointDistanceKm} km)</p>
                                    <p>Total Athletes Missed: {checkpoint.TotalAthletesMissed} ({checkpoint.PercentAthletesMissed * 100} %)</p>
                                </>
                            ))
                    } */}
                    

                  
                </div>
            </div>

            <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>

    )
}


const mapState = ({settings, racesByOrgId, racesAnalyticsByOrgId})=>({settings, racesByOrgId, racesAnalyticsByOrgId});

export default withRouter(connect(mapState)(CheckpointAnalytics));