import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});



export const SET_STATUS = "ORGS_BY_ID/SET_STATUS";
export const SET_DATA = "ORGS_BY_ID/SET_DATA";
export const SET_NAME = "ORGS_BY_ID/SET_NAME";


export default (state = initialState.orgsById, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.id
        // expected: action.requestStatus

        return update(state, {
            [action.id]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_DATA: {
        // expected: action.id
        // expected: action.data

        return update(state, {
            [action.id]: { $auto: {
                requestStatus: { $set: 'received' },
                data: { $set: action.data },
            }}
        });
    }

    case SET_NAME: {
        // expected: action.id
        // expected: action.name

        return update(state, {
            [action.id]: { $auto: {
                requestStatus: { $set: 'received' },
                // data: { $set: action.data },
                data: { name: {
                    $set: action.name
                } },
            }}
        });
    }

        
    default:
      return state;
  }
};
