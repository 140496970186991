module.exports = {
    formatNumber: function(num) {
      return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
  
  
    roundDistance: function (number, precision) {
      let oper = 4;
      switch (precision) {
          case 4:
              oper = 1000;
              break;
          case 3:
              oper = 100;
              break;
          default:
              oper = 10
              break;
      }
      return Math.round(number * oper) / oper;
      }, 
  
      secondsToTimeStrip: function(sec_num, isMilli) {
        // var sec_num = parseInt(this, 10); // don't forget the second param
  
    
        if (isMilli) sec_num = Math.floor(sec_num / 1000);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
  
        const hasHours = hours > 0 ? true : false;
        const hasMinutes = minutes > 0 ? true : false;
        const hasSeconds = seconds > 0 ? true : false;
        let returnTime = '';
  
        if (hasHours > 0) {
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          returnTime = hours + ":" + minutes + ":" + seconds;
        } else if (hasMinutes) {
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          returnTime = minutes + ":" + seconds;
        } else if (hasSeconds) {
          returnTime = seconds;
        }
  
        
        return returnTime;
      },
  
      secondsToTimeStripWithSign: function(sec_num, isMilli) {
        // var sec_num = parseInt(this, 10); // don't forget the second param
  
        var isNegative = sec_num < 0 ? "–" : "+";
        sec_num = Math.abs(sec_num);
    
        if (isMilli) sec_num = Math.floor(sec_num / 1000);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
  
        const hasHours = hours > 0 ? true : false;
        const hasMinutes = minutes > 0 ? true : false;
        const hasSeconds = seconds > 0 ? true : false;
        let returnTime = '';
  
        if (hasHours > 0) {
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          returnTime = hours + ":" + minutes + ":" + seconds;
        } else if (hasMinutes) {
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          returnTime = minutes + ":" + seconds;
        } else if (hasSeconds) {
          returnTime = seconds;
        }
  
        if (hasMinutes || hasHours) {
          returnTime = isNegative + returnTime;
        } else {
          returnTime = isNegative + returnTime + " s";
        }
        return returnTime;
      },
  
      secondsToTime: function(sec_num, isMilli) {
        // var sec_num = parseInt(this, 10); // don't forget the second param
    
        if (isMilli) sec_num = Math.floor(sec_num / 1000);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
    
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + ":" + minutes + ":" + seconds;
      },
  
      secondsToPossibleLongMinutesSeconds: function(sec_num) {
  
        let val = "";
        if (!sec_num) {
          return val;
        }
        var isNegative = sec_num < 0 ? "–" : "+";
        sec_num = Math.abs(sec_num);
  
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
  
        if (hours > 0) {
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          val = isNegative + hours + ":" + minutes + ":" + seconds;
        } else if (minutes > 0) {
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          val = isNegative + minutes + ":" + seconds;
        } else if (seconds > 0) {
          val = isNegative + seconds + " s";
        } else {
          val = "<1 s"
        }
  
  
        return val;
        
      },
  
      secondsToLongMinutesSeconds: function(sec_num) {
  
        let val = "";
        if (!sec_num) {
          return val;
        }
        var isNegative = sec_num < 0 ? "– " : "+ ";
        sec_num = Math.abs(sec_num);
  
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
        // console.log("sec_num",sec_num);
        // console.log("hours",hours);
        // console.log("minutes",minutes);
        // console.log("seconds",seconds);
    
        // if (hours < 10) {
        //   hours = "0" + hours;
        // }
        // if (minutes < 10) {
        //   minutes = "0" + minutes;
        // }
        // if (seconds < 10) {
        //   seconds = "0" + seconds;
        // }
        if (hours > 0) {
          val = isNegative + hours + " h " + minutes + " m " + seconds + " s";
        } else if (minutes > 0) {
          if (seconds > 0) {
            val = "    " + isNegative + minutes + " m " + seconds + " s";
          } else {
            val = "    " + isNegative + minutes + " m ";
          }
        } else {
          val=  "      " + isNegative + seconds + " s";
        }
        // console.log("val",val);
        if (val.includes("– 0 s") || val.includes("+ 0 s")) {
          val = "     < 1 s";
        }
  
        return val;
        
      }
    
    
      
    };
    