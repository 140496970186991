import React from 'react';
// import firebase from 'firebase/app'

// import classNames from 'classnames'
// import EmailPassFields from './EmailPassFields'
import {connect} from 'react-redux'


const EmailSent = ({close, setLoginPopupOpen, email}) => {
    
    return (
        <div className="EmailSent">
            <h1>Email Sent</h1>
            <div className="Label"><i></i><span>Password Reset</span><i></i></div>

            <p>An email has been sent to <strong>{email}</strong></p>

            <p>In the email, click <strong>Reset Password</strong>, and you will be redirected to a page where you can enter a new password.</p>

            <div className="buttons" style={{marginTop: 40}}>
                {/* <input type="submit" value="Sign In" style={{ backgroundImage: `url(${imgArrow})` }}/> */}
                <button type="button" className="secondary" onClick={()=>setLoginPopupOpen('emailsent')}>Log In</button>
                <button type="submit" onClick={close}>OK</button>
            </div>

        </div>

    )

}

const mapStateToProps = ({settings})=>({settings});
const mapDispatchToProps = (dispatch) => ({
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(EmailSent);

