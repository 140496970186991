import React from 'react'
import navNext from '../../../images/next-big-03.svg';
// import generic_skyline from '../../../images/generic@3x.png';
import generic_skyline from '../../../images/generic-02.svg';
import timePng from '../../../images/swatch-04.svg'
import imgUserAvatar from '../../../images/m-1-07.svg'


import './MainAthleteBlock.scss'
import './WelcomeBib.scss'


const MainAthleteBlock = () => {
    

    return (
        <>
            <div className="MainAthleteBackground" style={{
                backgroundImage: `url(${generic_skyline})`
            }} />

            <div className="MainAthleteBlock">


                <div className="imgLeft">
                    <div className="backgroundImage"></div>
                </div>

                <div className="card">
                    <a className="prevPage tooltip" href="/select-athlete/cim-2019/cim-ma-2019/All-Genders">
                        <span className="background"></span>
                        <img src={navNext} />
                        <span className="tooltiptext">Back to<em>Event &amp; Athletes</em></span>
                    </a>
                    <div className="cardcontent">
                        <div>
                            <div>
                                <a className="MarathonTitle" href="/select-athlete/cim-2019/cim-ma-2019">
                                    <span className="tooltip">
                                        <span className="tooltiptext">Race's <em>Home Page</em>
                                        </span>
                                        <strong>California International Marathon</strong>
                                    </span>
                                    </a>
                                    <span className="MarathonDates">Sun<i className="dot">•</i>Dec 8, 2019</span>
                                    <div className="Label"><i></i><span>Marathon</span><i></i></div>
                                    <div className="EdgesLine">
                                        <div>
                                            <strong>#468</strong>
                                        </div>
                                        <div>
                                            <strong>
                                                <div>M 30-34</div>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="WelcomeBib">
                                    <a className="userAvatarContainer tooltip" href="/athlete/2b10my6m6ff9l4ehmxmnecfcfowkun7xw93rbtinpzccit1mmgv7d9sum">
                                        <span className="tooltiptext">Go to <em>Athlete's Home</em></span>
                                        <img src={imgUserAvatar} alt="user-avatar" className="userAvatar" />
                                    </a>
                                    <p className="cityStateCountryLabel"><span className="countryFlag">🇺🇸</span>Boise, ID</p><h1><span><a className="athleteName tooltip" href="#">Stephen Wolf<span className="tooltiptext">Athlete's Home Page</span></a></span></h1><span className="timeContainer">
                                <span className="time" 
                                    // style={{
                                    //backgroundImage: `url(${})`
                                    style={{backgroundImage:`url(${timePng})`
                                    }}>4:16:17</span></span></div></div></div>
                                    <a className="nextPage tooltip" href="/results-summary/cim-ma-2019/athletes/468">
                                        <span className="background"></span><img src={navNext} />
                                        <span className="tooltiptext">Go to <em>Results Summary</em></span>
                                    </a>
                </div>

                <div className="imgRight">
                    <div className="backgroundImage"></div>
                </div>
            </div>
        </>
    )
}
export default MainAthleteBlock