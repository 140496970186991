// import firebase from 'firebase/app'
import {db} from '../config/firebase'
import { SET_STATUS, SET_INVITES } from '../reducers/invitesReducer'


export const setInvites = ({orgId, invites}) => ({ type: SET_INVITES, orgId, invites  });

export const requestInvites = (orgId) => {
    return (dispatch, getState) => {

        if(!orgId){
            return;
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        

        // let saveData = {};
        // Object.keys(data).forEach(key => {
        //     if(typeof data[key]!=='undefined') saveData[key] = data[key];
        // });


        dispatch({ type: SET_STATUS, orgId, requestStatus: 'pending' });

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error Invite document: ", e);
            dispatch({ type: SET_STATUS, orgId, requestStatus: 'error' });
        };

        const handleSuccess = snap => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});

            
            let invites = [];
            snap.forEach( doc => {
                invites.push({...doc.data(), id: doc.id});
            });

            // dispatch({ type: SET_INVITES, orgId, invites });
            dispatch(setInvites({ orgId, invites  }));
        }

        
        
        db
            .collection("invites")
            .where('orgId', '==', orgId)
            .where('status', '==', 'invited')
            // .where('status', 'in', ['invited','invalid'])
            .get()
            .then(handleSuccess)
            .catch(handleError);
        
    }
}
