
import React, { Component, useReducer } from "react";
import firebase from '../../config/firebase'
import moment from 'moment';

import { Link, withRouter } from 'react-router-dom'
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import steeltoe from 'steeltoe'
import {connect} from 'react-redux'
import RaceStatusFilters from './RaceStatusFilters';
import { toast } from 'react-toastify';
import { incrementPendingRequests, decrementPendingRequests } from '../../actions/requestsPending'
import "./SelectRace.scss";
import Headroom from 'react-headroom';
import { getStorageFilePath } from '../Races/RaceEdit/TimingDataUpload/Step1'
// import landscape from "/images/city-generic/generic-02.svg";



class SelectRace extends Component {


  handleNewRaceButton = e => {
    e.preventDefault();

    this.props.history.push(`/races-admin/new`)
    // this.props.openInviteMemberForm();

  }


  handleDeleteRaceButton = raceCode => e => {

    const { settings, history } = this.props;
    const orgId = settings.currentOrgId;

    console.log('delete race:', raceCode);

    e.preventDefault();

    incrementPendingRequests()

    const funcDeleteRace = firebase.functions().httpsCallable('deleteRace');
        funcDeleteRace({ orgId, raceCode }).then(result=>{
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
          
            decrementPendingRequests()
            history ? history.push(`/races-admin`) : console.log('history missing...');            
            toast("Race Deleted!");

            // toast.info('Race Data Saved!',{
            //     className: 'black-background',
            //     autoClose: 8000
            // });
            // dispatch({ type: SET_DATA, racecode, data: result.data.data  });
        }).catch(e => {
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            decrementPendingRequests()
            console.warn('error making request in funcUpdateRace',e)
        });



    const timingDataFile = getStorageFilePath(raceCode, 1/*TODO: set to version dynamically */);
    const currentTimingDatasetId = raceCode + '-1';

    incrementPendingRequests()
    
    const funcDeleteTimingDataset = firebase.functions().httpsCallable('deleteTimingDataset');
    funcDeleteTimingDataset({ timingDataFile, timingDatasetId: currentTimingDatasetId}).then(result=>{
        console.log('deleteTimingDataset result:', result.data);
        decrementPendingRequests()
        window.location.reload();
    }).catch(e => {
        decrementPendingRequests()

        console.warn('error calling backend function',e)
    });

  }


  render() {

    const { settings, racesByOrgId } = this.props;


    const { raceListFilterStatusOption } = this.props.settings;

    const orgId = steeltoe(settings).get(`currentOrgId`);
    const races = steeltoe(racesByOrgId).get(`${orgId}.data`);
    // console.log('races:', races)
    // console.log('raceListFilterStatusOption:', raceListFilterStatusOption)


    
    
    const page = "Portal Home";
    return (
      <div className="SelectRace">
        <Headroom>
          <Header title={`Select Race`} page={page}/>
        </Headroom>


        <div className="MainContent">
            <div className="container">


                {!races && (<div className="InfoCard">

                    <h2>No Races</h2>


                </div>)}

                <p></p>
                <div className="buttons">
                  <button onClick={this.handleNewRaceButton}>+</button>
                </div>
                <p></p>
                <RaceStatusFilters races={races} />
                <div className="InfoCards">
                  {races && races.map( race => {
                    const raceImage = steeltoe(this.props.raceImages).get(`${race.RaceCode}.main`);
                    const raceDateStartYear = race.RaceDateStart && moment(race.RaceDateStart) && moment(race.RaceDateStart).year();
                    return (
                      raceListFilterStatusOption.includes(race.AdminStatus) && 
                      <Link key={race.RaceCode} to={`/races-admin/edit/${race.RaceCode}`}>
                        <div key={race.RaceCode} className="InfoCard">
                          { raceImage ? <img style={{maxWidth: '100%', maxHeight: 100}} alt='Race Logo' src={raceImage} /> : null}
                          <p>{raceDateStartYear} • {race.RaceName}</p>
                      
                          {/* <p>{race.AdminStatus}</p> */}


                            {(race.AdminStatus !== 'Public-Unofficial' && race.AdminStatus !== 'Public') &&
                              <div className="buttons">
                                  <button onClick={this.handleDeleteRaceButton(race.RaceCode)}>Delete</button>
                              </div>
                            }
       

                        </div></Link>
                    )
                  })}
                </div>

              

                
            </div>
          
        </div>


        <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>
    );
  }
}



const mapStateToProps = ({raceEvents, raceEventsFilter, requestsPending, fbuserProfile, settings, racesByOrgId, raceImages}) => ({raceEvents, raceEventsFilter, requestsPending, fbuserProfile, settings, racesByOrgId, raceImages})


// const mapState = ({fbuser, fbuserProfile})=>({fbuser, fbuserProfile})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
//   setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
  incrementPendingRequests, // <- this.props.incrementPendingRequests()
  decrementPendingRequests 
//   sponsorsActions: bindActionCreators(sponsorsActions, dispatch),

    // allRacesActions: bindActionCreators(allRacesActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectRace));





