export default {
    settings: {
      
      isMenuDropdownOpen: false, // the top menu dropdown, opened on the user icon
      isInviteMemberModalOpen: false,
      
      // isLoginPopupOpen: 'resetpassword',
      isLoginPopupOpen: false, // false | 'login' | 'signup'
  
      windowW: window.innerWidth,
      modalsDone: {},

      inviteStatus: null,

      currentOrgId: null,
      raceListFilterStatusOption: ['Draft','Private','Public-Unofficial','Public'],
      raceEventSeriesCodeFilterOption: [],
      raceCodeFilterOption: [],
      raceEditOption: 'Race',
      signupOrgBrandOption: 'Event Organizer',

      currentTimingDatasetId: ''

      // inviteeEmail: 'angelomatheou+1@gmail.com',
      // invitedFromOrgId: 'aTfsqUG2m8Gpo9x4qpUr',
      // invitedFromOrgName: 'Sacramento Running Association (SRA)',
      
    },

    adBuilder: {
      selectedLogoId: '01',
      hoveredLogoId: '01',
      selectedCraftId: '09',
      hoveredCraftId: '09',
      craftScale: 0, // 100%
      harnessWidth: 35, // 35px
      orientation: 'v', // v - 'vertical', h - 'horizontal'
      harnessColor: '#ff3838',
      bannerHeight: 120,  // px
      bannerWidth: 120,  // px
      bannerTypeId: '02', 
      bannerColor: '#ffffff',
      logoScale: 0, // %
      logoOffsetY: 0, // px
      logoOffsetX: 0, // px 
      logoUrl: null, 
      targetUrl: 'https://runbetterapp.com/', 
      animationSpeed: 300,
      leftPanelOpen: true,
      rightPanelOpen: true,
    },

  
   
  
    
    fbuser: 0,
    fbuserProfile: null,
    fborgProfile: null,

    invites: {
      /*
        [orgId]:  {
          invites: [{}],
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      }
      */
    },

    usersByUid: {
      /*
        [uid]: {
          data: {
            ... user data doc ...
          },
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      */
    },

    logs: [],
    

    orgsById: {
      /*
        [id]: {
          data: {
            ... org data doc ...
          },
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      */
    },

    raceImages: {
      /*
      [raceCode]: {
        main: '' // race image url
      }
      */
    },
    
    accessRolesByUser: {
      /*
        [uid]: {
          orgs: [{
            orgId,
            role: ,
              owner - all race codes - '*'
              admin - one race code
            raceCodes: [{ - this doesn't matter if they are owner
              raceCode:  '',
              roles: [''] - 'admin', 'timer', 'marketer'
            }]
          }],
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      */
    },
    
    orgMembers: {
      /*
        [orgId]:  {
          members: [{
            uid: userId,
            role: ,
              owner - all race codes - '*'
              admin - one race code
            raceCodes: [{ - this doesn't matter if they are owner
              raceCode:  '',
              roles: [''] - 'admin', 'timer', 'marketer'
            }]
          }]
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      */
    },

    racesByOrgId: {
      /*
        [orgId]:  {
          data: [{
            }]
          }]
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      */
    },
    racesAnalyticsByOrgId: {
      /*
        [orgId]:  {
          data: [{
            }]
          }]
          requestStatus: '' -- 'pending', 'received', 'error'
        }
      */
    },


    users: {
      /*[uid]: {
        email: ,
        orgs: [ array of org ids ]
      }*/
    },
    
    requestsPending: 0,
    

    timingDatasets: {
      /*[mvm-ma-2010-1]: {
        steps: [],

      } */
    },




  raceAthleteFilter: {
    page: 1,
    perPage: 50,
    overallFilter: 'All-Genders',
    divisionFilter: 'All-DivisionType1s',
    locationFilter: 'All-Locations',
    splitTypeFilter: 'All-Splits',
    sorterCol: 'IndexFinish',
    sorterColDir: 'down',
    onlyFavorite: false,
    isDirty: false
  },



  athletesByFilter: {
    /*
      COMBINED_FILTER_KEY will include filters (gender, division, location...), sort (field, direction), number of items per page
    
      [COMBINED_FILTER_KEY]: {
        status: '', -- 'pending', 'received', 'error'
        
        totalItems: 1234,
                // EXAMPLE for totalItems:
                // - athletesByFilter[FILTERKEY].totalItems - maybe check this here too (or maybe in pagination action), and request totalItems from mongodb
                // totalItems - undefined - need to make the request
                // totalItems - "pending" - string - 
                // totalItems - 0 - number
                // totalItems - 1234 - number

        totalPages: 24,
        pages: {
          1: [ {Bib:"1"}, {Bib:"2"} ]
        }
      } 
    */
  },
  
  athletesByRacebib: { // i.e. racecode-bib-slug
    /*'mvm-ma-2018--100': { // EXAMPLE
        status: 'pending' OR 'received', 
        data: {} 
        ranking_status: 'pending' OR 'received', 
        ranking_data: {} 
      }
    }*/
  },  
  
    /*
    users: {
        [EmailHash] : {
          username: '',
          profile: {},
          avatarImageUrl: '',
          athleteRaceResults: []
  
          status: {
            username: '', -- 'pending', 'received', 'error'
            profile: '', -- 'pending', 'received', 'error'
            avatarImageUrl: '', -- 'pending', 'received', 'error'
          }
        }
      }
     */
  
    
  };
  