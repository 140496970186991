import React, { Component, useEffect } from 'react'
import './Header.scss'
import { withRouter } from 'react-router-dom'
import {connect, useDispatch, useSelector} from 'react-redux'
import LoginPopup from "../Login/LoginPopup";
import Sidebar from '../Sidebar/Sidebar'
import steeltoe from 'steeltoe';
import { bindActionCreators } from "redux";
// import { requestOrgById } from '../../actions/orgsById';
import { getCurrentOrgId, getCurrentOrgName } from "../../re-selectors/getCurrentOrgId";


// const OrgsByIdGetter = () => {
//     const dispatch = useDispatch();
//     // currentOrgId: getCurrentOrgId(state)
//     const currentOrgId = useSelector(getCurrentOrgId(state));
//     useEffect(()=>{
//         console.log('OrgsByIdGetter requestOrgById');
//         dispatch(requestOrgById(currentOrgId));
//     },[currentOrgId]);
//     return null;
// };


class Header extends Component {

    // componentDidMount(){
    //     console.log('Header mounted')
    // }



    // _prevOrgId = null;
    
    // componentDidUpdate(prevProps){
    //     const currentOrgId = this.getCurrentOrgId();
    //     // console.log('currentOrgId;', currentOrgId);
    //     if(this._prevOrgId!==currentOrgId){
    //         this._prevOrgId=currentOrgId;
            
    //         console.log('requestOrgById in header', currentOrgId)
    //         this.props.orgsByIdActions.requestOrgById(currentOrgId);
    //     }
    // }

    // getCurrentOrgId = () => {
    //     const { settings, fbuser, accessRolesByUser } = this.props;
    //     if(!fbuser) return null;
    //     let currentOrgId = settings.currentOrgId;


    //     if(!currentOrgId){
    //         currentOrgId = steeltoe(accessRolesByUser).get(`${fbuser.uid}.orgs.0.orgId`);
    //     }
    //     return currentOrgId;
    // }
    // getCurrentOrgName = () => {
    //     // const currentOrgId = this.getCurrentOrgId();
    //     const {currentOrgId} = this.props;
    //     return steeltoe(this.props).get(`orgsById.${currentOrgId}.data.name`) || '...';
    // }
   

    render(){

        const { fbuser, currentOrgId, currentOrgName } = this.props;
        // let currentOrgId = settings.currentOrgId;

        // if(!currentOrgId){
        //     currentOrgId = steeltoe(accessRolesByUser).get(`${fbuser.uid}.orgs.0.orgId`);
        // }

        // const orgName = this.getCurrentOrgName();

        return (
            <div className="Header">
                {/* <OrgsByIdGetter orgId={currentOrgId} /> */}
                
                <div className="container">
                    {/* <Sidebar /> */}
                    <Sidebar page={"Portal Home"} />


                    <div className="HeaderTitle">
                        {fbuser && currentOrgName ? currentOrgName : 'Welcome to the MyRace Portal'}
                        
                    </div>

            

                    <div className="rightbar-container">
                      

                        <div className="rightbar-container-block">
                            <div className="rightbar-container-row rightbar-container-row-1">
                 
                            &nbsp;

                            </div>

                        </div>
                        <div className="rightbar-container-block profile-icon-container">
                            <LoginPopup />
                        </div>
                    </div>
                    

                        
                </div>
            </div>
        )
    }
}

// const mapStateToProps = state => {
//     // console.log('raceAthleteFilter:', JSON.stringify(state.raceAthleteFilter));

//     // const { totalPages, totalItems } = getAthletesByFilterState(state);
    
// }

const mapStateToProps = (state) => {
    const {fbuser, fbuserProfile, orgMembers, accessRolesByUser, orgsById, settings} = state;
    return {fbuser, fbuserProfile, orgMembers, accessRolesByUser, orgsById, settings,
        currentOrgId: getCurrentOrgId(state),
        currentOrgName: getCurrentOrgName(state),
    }
};


const mapDispatch = dispatch => ({
    // orgsByIdActions: bindActionCreators(orgsByIdActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatch)(Header));