import { combineReducers } from "redux";
import settings from "./settingsReducer";
import fbuser from "./fbUserReducer";
import fbuserProfile from "./fbUserProfileReducer";
import orgMembers from "./orgMembersReducer";
import accessRolesByUser from "./accessRolesByUserReducer";
import usersByUid from "./usersByUidReducer";
import orgsById from "./orgsByIdReducer";
import racesByOrgId from "./racesByOrgIdReducer";
import racesAnalyticsByOrgId from "./racesAnalyticsByOrgIdReducer";
import invites from "./invitesReducer";
import timingDatasets from "./timingDatasetsReducer";
import requestsPending from "./requestsPendingReducer";
import raceImages from "./raceImagesReducer";
import logs from "./logsReducer";
import athletesByFilter from "./athletesByFilterReducer";
import raceAthleteFilter from "./raceAthleteFilterReducer";
import adBuilder from "./adBuilderReducer";


const rootReducer = combineReducers({
    settings,
    fbuser,
    fbuserProfile,
    orgMembers,
    accessRolesByUser,
    usersByUid,
    orgsById,
    invites,
    racesByOrgId,
    racesAnalyticsByOrgId,
    timingDatasets,
    requestsPending,
    raceImages,
    logs,
    athletesByFilter,
    raceAthleteFilter,
    adBuilder,
});

export default rootReducer;
