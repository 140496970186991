// import firebase from 'firebase/app'
import {db} from '../config/firebase'
import { SET_STATUS, SET_MEMBERS } from '../reducers/orgMembersReducer'
import { ORGS_BY_USER_SET_STATUS, SET_ORGS } from '../reducers/accessRolesByUserReducer'



export const requestOrgMembers = (orgId) => {
    return (dispatch, getState) => {

        if(!orgId){
            return;
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        

        // let saveData = {};
        // Object.keys(data).forEach(key => {
        //     if(typeof data[key]!=='undefined') saveData[key] = data[key];
        // });


        dispatch({ type: SET_STATUS, orgId, requestStatus: 'pending' });

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error writing User document: ", e);
            dispatch({ type: SET_STATUS, orgId, requestStatus: 'error' });
        };

        const handleSuccess = snap => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            
            let accessRoles = [];
            snap.forEach( doc => {
                accessRoles.push({...doc.data(), id: doc.id});
            })
            
   
            dispatch({ type: SET_MEMBERS, orgId, members: accessRoles  });

            // TODO: rewrite this to properly name accessRoles in redux
            // TODO: request user data for these accessRoles, before displaying them on the page

            // TODO: get data from invites too
        }

        
        db
            .collection("accessRoles")
            .where('orgId', '==', orgId)
            .get()
            .then(handleSuccess)
            .catch(handleError);
        
    }
}


export const setOrgsByUid = ({uid, orgs}) => ({ type: SET_ORGS, uid, orgs });

export const requestOrgs = (uid) => {
    return (dispatch, getState) => {
        // console.log('[requestOrgs]', uid)

        if(!uid){
            return;
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        
        // console.log("About to save profile")

        // let saveData = {};
        // Object.keys(data).forEach(key => {
        //     if(typeof data[key]!=='undefined') saveData[key] = data[key];
        // });

        dispatch({ type: ORGS_BY_USER_SET_STATUS, uid, requestStatus: 'pending' });

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error writing User document: ", e);
            dispatch({ type: ORGS_BY_USER_SET_STATUS, uid, requestStatus: 'error' });
        };

        const handleSuccess = snap => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // console.log("Got Orgs data!", snap);
            
            let accessRoles = [];
            snap.forEach( doc => {
                accessRoles.push({...doc.data(), id: doc.id});
            })
            
            // const orgData = snap.doc();
            // dispatch({ type: SET_ORGS, uid, orgs: accessRoles  });
            dispatch(setOrgsByUid({ uid, orgs: accessRoles }));

            // TODO: rewrite this to properly name accessRoles in redux
            // TODO: request user data for these accessRoles, before displaying them on the page

            // TODO: get data from invites too
        }

        
        db
            .collection("accessRoles")
            .where('uid', '==', uid)
            .get()
            .then(handleSuccess)
            .catch(handleError);
        
    }
}