import React, { useState, useEffect } from 'react'


import { crafts, craftsArr, logos } from "../assets";
import { useSelector } from "react-redux";


import harnessSVG from '../../../images/harness.svg'
import imgRunBetter from '../../../images/adsImages/RunBetter.png'

import styled from 'styled-components'
import classnames from 'classnames'

/**
       selectedCraftId: '09',
      craftScale: 0, // 100%
      harnessWidth: 35, // 35px
      harnessOrientation: 'v', // v - 'vertical', h - 'horizontal'
      harnessColor: '#ff3838',
      bannerHeight: 110,  // px
      bannerWidth: 60,  // px
      bannerTypeId: '01', 
      bannerColor: '#ffffff',
      logoScale: 0, // %
      logoOffsetY: 0, // px
      logoOffsetX: 0, // px 
      logoUrl: null, 
      targetUrl: null, } param0 
 */



const AirshipAnimated = styled.div`
    position: absolute;
    display: flex;
    top: 120px;
    /* right: -300px; */
    right: 300px;
    z-index: 9999;
    transform: scale(1) translateX(0px);
    transition: transform ${props => props.adSpeed}s;
    
    &.move {
        transform: scale(1) translateX(-2500px);
    }
`;




const AirshipBody_ = ({className})=>{
    const id = useSelector(s => s.adBuilder.hoveredCraftId);
    if(!id) return null;
    return (
        <div className={classnames('AirshipBody',className)}>
            <img src={crafts[id].img} />
        </div>
    )
};
const AirshipBodyStyled = styled(AirshipBody_)`
    position: relative;
    width: ${p => p.orientation==='h' ? ( `${200+p.scale}px`) : 'auto'};
    height: ${p => p.orientation==='v' ? ( `${200+p.scale}px`) : 'auto'};
    display:flex;
    align-items: center;
    justify-content: center;
    &.h img {
        width: 100%;
    }
    &.v img {
        height: 100%;
    }
`;

const AirshipBody = () => {
    const scale = useSelector(s => s.adBuilder.craftScale);
    const orientation = useSelector(s => s.adBuilder.orientation);
    return <AirshipBodyStyled {...{scale,orientation}} className={orientation} />
}





const AirshipHarness_ = ({className})=>{
    const harnessWidth = useSelector(s => s.adBuilder.harnessWidth);
    return (
        <div className={classnames(className, 'AirshipHarness')}>
            <img src={harnessSVG} />
        </div>
    )
};
const AirshipHarness = styled(AirshipHarness_)`
    position: relative;
    display:flex;
    align-items: center;
`;





const BannerStickStyled = styled.div`
    position: relative;
    width: ${p=> p.orientation==='v' ? `${p.harnessWidth}` : 6}px;
    height: ${p=> p.orientation==='h' ? `${p.harnessWidth}` : 6}px;
    background-color: ${p=>p.harnessColor};
    border-radius: 3px;
    align-self: center;
`;
const BannerStick = () => {
    const harnessColor = useSelector(s => s.adBuilder.harnessColor);
    const harnessWidth = useSelector(s => s.adBuilder.harnessWidth);
    const orientation = useSelector(s => s.adBuilder.orientation);
    return <BannerStickStyled {...{harnessColor,harnessWidth,orientation}} className="BannerStick" />
}


const Marquee = styled(({className}) => {
    return (
        <div className={className}>
            Run better using real course data.
        </div>
    )
})`
    padding: 10px;
    font-size: 12px;
`;



const AirshipLogo = ({className})=>{
    const id = useSelector(s => s.adBuilder.hoveredLogoId);
    if(!id) return null;
    return (
        <div className={classnames('Logo',className)}>
            <img src={logos[id].img} />
        </div>
    )
};


const AnimatedCard_ = ({className, orientation, type}) => {
    const targetUrl = useSelector(s=>s.adBuilder.targetUrl);
    return (
        <div className={classnames('AirshipBanner',`type${type}`,className, {
            horizontal: orientation==='h',
            vertical: orientation==='v',
        })}
            onClick={()=>window.open(targetUrl)}
        >
            <div className="CardContent">
                <AirshipLogo/>
                <Marquee />                
            </div>
            <div className="Cut">
            </div>
        </div>
    )
}

const AnimatedCardStyled = styled(AnimatedCard_)`
    position:relative;
    font-size: 3em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px rgba(50,50,0,0.2));
    height: ${p => p.height ? `${p.height}px` : 'auto'};
    width: ${p => p.width ? `${p.width}px` : 'auto'};
    align-self: center;

    .CardContent {
        background-color: #8888ce;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .Logo {
        padding: 0 10px;
        max-width: 200px;
        margin-bottom: -24px;
        position: relative;
        z-index: 1;
        img {
            top: -9px;
            position: relative;
            max-width: 100%;
            transform: scale(${p=>p.logoScale/100 + 1}) translateX(${p=>p.logoOffsetX}px) translateY(${p=>p.logoOffsetY}px);
        }
    }

    .Cut{
        background-color: ${p => p.color ? p.color : '#ffffff'};
        padding: 20px;
    }
    
    &.vertical {
        &.type02 {
            .Cut {
                clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 100%, 0 0);
            }
        }
        &.type03 {
            .Cut {
                clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
            }
        }
        &.type04 {
            .Cut {
                border-bottom-left-radius: 200px;
                border-bottom-right-radius: 200px;
            }
        }
    }

    &.horizontal {
        
        &.type02 {
            .Cut {
                clip-path: polygon(100% 0, 50% 50%, 100% 100%, 0 100%, 0 0);
            }
        }
        &.type03 {
            .Cut {
                clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 100%, 0 0);
            }
        }
        &.type04 {
            .Cut {
                border-top-right-radius: 200px;
                border-bottom-right-radius: 200px;
            }
        }

    }
`;

const AnimatedCard = () => {
    const orientation = useSelector(s => s.adBuilder.orientation);
    const type = useSelector(s => s.adBuilder.bannerTypeId);
    const color = useSelector(s => s.adBuilder.bannerColor);
    const width = useSelector(s => s.adBuilder.bannerWidth);
    const height = useSelector(s => s.adBuilder.bannerHeight);
    const logoScale = useSelector(s => s.adBuilder.logoScale);
    const logoOffsetX = useSelector(s => s.adBuilder.logoOffsetX);
    const logoOffsetY = useSelector(s => s.adBuilder.logoOffsetY);

    return (
        <AnimatedCardStyled {...{orientation, type, color, width, height, logoScale, logoOffsetX, logoOffsetY}} />
    )
}



export const Airship = () => {
    const [move, setMove]=useState(false);

    const animationSpeed = useSelector(s => s.adBuilder.animationSpeed);

    useEffect(()=>{
        window.setTimeout(()=>{
            setMove(true)
        },300);
    }, [])
    

    return ( 
        <AirshipAnimated
            adSpeed={animationSpeed}
            className={classnames('Airship', {
            move
        })}>
            <AirshipBody />
            <AirshipHarness />
            <BannerStick />
            <AnimatedCard />
        </AirshipAnimated>
    )

}

export default Airship;
