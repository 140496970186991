// SignUpOrgBrandFilter

import React, { Component } from "react";
import "./SignUpModal.scss"
import "./SignupOrgBrandFilters.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
// import TooltipText from '../Tooltip/TooltipText'

class SignupOrgBrandFilter extends Component {


  componentDidMount(){

  }

  selectFilterOption = filterOption => e => {

    this.props.setSignupOrgBrandFilterOption(filterOption)
  }

  render() {
    const { signupOrgBrandOption } = this.props.settings;

    const filters = ['Event Organizer','Brand / Advertiser'];
    
    return (
      <div className="SignupOrgBrandFilters">
        
        <div className="line"/>

        { filters.map( filterOption => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={filterOption} onClick={this.selectFilterOption(filterOption)} 
            className={classnames("button", { selected: signupOrgBrandOption===filterOption})}>

            {filterOption} 
          </div>
        ))}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = ({ settings, requestsPending }) => ({ settings,requestsPending })

const mapDispatchToProps = dispatch => ({
//   setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE_FILTER_STATUS', race}),
setSignupOrgBrandFilterOption: signupOrgBrandOption => dispatch({type:'SET_SETTING', signupOrgBrandOption}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupOrgBrandFilter));

