import React, { Component } from 'react'
import firebase from '../../config/firebase'
import {withRouter} from 'react-router-dom'
import Headroom from 'react-headroom';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as fbuserActions from '../../actions/fbuser'
import * as orgsByIdActions from '../../actions/orgsById';
// import { clearUserStatusProfileFlag } from '../../actions/users'
import debounce from 'lodash.debounce'
// import TooltipText from '../../components/Tooltip/TooltipText'
// import MainAccountProfileBlock from './MainAccountProfileBlock-deleteme';
import './AccountProfile.scss'
import imgLandscape from '../../images/generic-mobile.svg';
import ToggleGeneric from '../../components/ToggleButton/ToggleGeneric'
import classNames from 'classnames'
// import CountryDropdown from './CountryDropdown'
// import statesList from './statesList'
// import moment from 'moment'
// import ProfileImage from './ProfileImage/ProfileImage'
import ChangePasswordForm from './ChangePasswordForm'
import { useForm } from "react-hook-form";



import iconUserBlue from "../../images/user-04.svg"
import iconUserRed from "../../images/user-05.svg"
import iconUserBlack from "../../images/user-06.svg"
// import iconDateBlue from "../../images/date-01.svg"
// import iconDateRed from "../../images/date-02.svg"
// import iconDateBlack from "../../images/date-03.svg"


import steeltoe from 'steeltoe'
import { getCurrentOrgId, getCurrentOrgName } from '../../re-selectors/getCurrentOrgId';

// import imgUserBlack from '../../images/user-06.svg';
// import imgMailBlack from '../../images/mail-03.svg';


const ManageUserContent = props => {

    // const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
    const { register, errors, triggerValidation } = useForm();

    // const []
    // const [selectedState, setSelectedState] = useState('');

    // useEffect(()=>{
    //     if(props.fbuserProfile&&props.fbuserProfile.stateCode){
    //         setSelectedState(props.fbuserProfile.stateCode);
    //     }
    // }, [props.fbuserProfile&&props.fbuserProfile.stateCode])
    
    const { fbuserProfile, updateField, parentState } = props;
    const { firstname, lastname } = parentState;
    console.log("fbuserProfile",fbuserProfile)

    if(!fbuserProfile) {
        return null;
    }

    
    return (
        
    <div className="ManageUserContent">

        

        <div className={classNames("control", {
            invalid: !!steeltoe(errors).get('firstname.message'),
            valid: !!firstname
             })}>
            <label> 
                <span className="label">First Name</span>
                <input name="firstname" type="text" 
                    placeholder={ 'Your First Name...'}
                    onChange={(e)=>{
                        updateField('firstname')(e);
                        triggerValidation('firstname');
                    }} 
                    ref={register({
                        validate: value => value ? false : 'Enter First Name'
                    })}
                    value={firstname}
                    // style={{
                    //     backgroundImage: `url(${iconUserBlue})`
                    // }}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconUserBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${iconUserRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${iconUserBlack})`}}/>
                    </div>
                </div>
            </label>
        </div>
        
        <div className={classNames("control", {
            invalid: !!steeltoe(errors).get('lastname.message'),
            valid: !!lastname
             })}>
            <label> 
                <span className="label">Last Name</span>
                <input name="lastname" type="text" 
                    placeholder={ 'Your Last Name...'}
                    onChange={(e)=>{
                        updateField('lastname')(e);
                        triggerValidation('lastname');
                    }} 
                    value={lastname} 
                    ref={register({
                        validate: value => value ? false : 'Enter Last Name'
                    })}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconUserBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${iconUserRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${iconUserBlack})`}}/>
                    </div>
                </div>
            </label>
        </div>
        
    </div>
    )
}

const ManageOrgContent = props => {

    const { register, errors, triggerValidation } = useForm();

    // const { orgsById, settings, fbuserProfile } = this.props;
    const { fbuserProfile, updateField, parentState } = props;
    const { orgname } = parentState;

    if(!fbuserProfile) {
        return null;
    }

    // const currentOrgId = settings.currentOrgId;
        
    // const orgName = steeltoe(props).get(`orgsById.${currentOrgId}.data.name`) || '...';

    // const []
    // const [selectedState, setSelectedState] = useState('');

    // useEffect(()=>{
    //     if(props.fbuserProfile&&props.fbuserProfile.stateCode){
    //         setSelectedState(props.fbuserProfile.stateCode);
    //     }
    // }, [props.fbuserProfile&&props.fbuserProfile.stateCode])
    

    console.log("fbuserProfile",fbuserProfile)

    if(!fbuserProfile || !orgname || orgname==='...' ) {
        return null;
    }

    
    return (
        
    <div className="ManageUserContent">

        

        <div className={classNames("control", {
            invalid: !!steeltoe(errors).get('orgname.message'),
            valid: !!orgname
             })}>
            <label> 
                <span className="label">Org Name</span>
                <input name="orgname" type="text" 
                    placeholder={ 'Org Name...'}
                    onChange={(e)=>{
                        updateField('orgname')(e);
                        triggerValidation('orgname');
                    }} 
                    ref={register({
                        validate: value => value ? false : 'Enter Org Name'
                    })}
                    value={orgname}
                    // style={{
                    //     backgroundImage: `url(${iconUserBlue})`
                    // }}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconUserBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${iconUserRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${iconUserBlack})`}}/>
                    </div>
                </div>
            </label>
        </div>
        
       
        
    </div>
    )
}




// const ManageAdminContent = props => {
const ManageAdminContent = ({ fbuser, fbuserProfile, parentState, showChangePasswordForm, showChangePasswordFormCall, hideChangePasswordFormCall }) => {

    // const username = "amatheou"
    // const email = "angelo@myrace.com"
    // console.log("fbuser1",fbuser)
    // const username = fbuserProfile && fbuserProfile.username;
    const email = fbuser && fbuser.email;

    return (
        <div className="ManageAdminContent">
            


            {/* <div className={classNames("control", {
            //  invalid: !!this.state.errors.firstname 
            })}>
                <label> 
                    <span className="label with-right-label">
                        <span>User Name</span>
                        <Link to={`/${username}`}><span>myrace.com/<strong>{username}</strong></span></Link>
                    </span>
                    <input name="username" type="text" 
                        value={username}
                        disabled
                        // onChange={this.updateField('firstname')} 
                        // value={fbuserProfile.firstname} 
                        style={{
                            // backgroundImage: `url(${iconUserBlue})`
                        }}
                        />
                </label>
            </div> */}
            

            <div className={classNames("control", {
            //  invalid: !!this.state.errors.firstname 
            })}>
                <label> 
                    <span className="label">Email Address</span>
                    <input name="email" type="text" 
                        value={email}
                        disabled
                        // onChange={this.updateField('firstname')} 
                        // value={fbuserProfile.firstname} 
                        style={{
                            // backgroundImage: `url(${iconUserBlue})`
                        }}
                        />
                </label>
            </div>
        
            

            { showChangePasswordForm ? (
                <ChangePasswordForm onHideRequest={hideChangePasswordFormCall} />
            ) : 
                <div className="change-password-link control" onClick={showChangePasswordFormCall}>Change Password</div>
            }
            



        </div>
    )
}





class AccountProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            showChangePasswordForm: false,
            firstname: '',
            lastname: '',
            orgname: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',


            activeTab: 'User',
        }
        this.save = debounce(this.save, 2000)
    }

    // _prevOrgId = null;
    _prevOrgName = null;

    componentDidMount(){

        this.getInitialProfileData();


        
    }


    componentDidUpdate(prevProps){

        if(!prevProps.fbuserProfile && this.props.fbuserProfile){
            // console.log("HERE")
            this.getInitialProfileData();
        }
        
        // const currentOrgId = this.getCurrentOrgId();
        // if(this._prevOrgId!==currentOrgId){
            //     this._prevOrgId=currentOrgId;
            //     this.props.orgsByIdActions.requestOrgById(currentOrgId);
            // }
            
        const {currentOrgName} = this.props;
        // const orgname = this.getCurrentOrgName();
        console.log("orgname:", currentOrgName);
        if(!this._prevOrgName && this._prevOrgName !== currentOrgName && currentOrgName){
            this._prevOrgName = currentOrgName;
            this.setState({ orgname: currentOrgName });
            console.log("UPDATE orgname:", currentOrgName);
        }
    }


    getInitialProfileData = () => {
        // const {fbuserProfile, fbuser, settings, orgsById} = this.props;
        const {fbuserProfile} = this.props;
        
        if(!fbuserProfile) { return; }

        let set = {};

        const fields = ['firstname', 'lastname'];
        fields.forEach(field => {
            if (fbuserProfile[field]) { set[field] = fbuserProfile[field]; }
        })
        

        this.setState(set);
        
    }
    
    // getCurrentOrgId = () => {
    //     const { settings, fbuser, accessRolesByUser } = this.props;
    //     if(!fbuser) return null;
    //     let currentOrgId = settings.currentOrgId;

    //     if(!currentOrgId){
    //         currentOrgId = steeltoe(accessRolesByUser).get(`${fbuser.uid}.orgs.0.orgId`);
    //     }
    //     return currentOrgId;
    // }
    // getCurrentOrgName = () => {
    //     const currentOrgId = this.getCurrentOrgId();
    //     return steeltoe(this.props).get(`orgsById.${currentOrgId}.data.name`) || '';
    // }
   





    setTab = tabTitle => this.setState({activeTab: tabTitle})


    updateField = field => e => {
        this.setState({ [field]: e&&e.target ? e.target.value : e }, this.save)
    }

    save = () => {
        // const {fbuser, fbuserActions, userActions, fbuserProfile} = this.props;
        const {fbuser, fbuserActions, currentOrgId} = this.props;
        const {firstname, lastname, orgname } = this.state;

        // console.log('SAVE', firstname, lastname, orgname)

        fbuserActions.saveProfileFields({
            fbuser, 
            data:{ firstname, lastname  }
        });

        // const orgId = this.getCurrentOrgId();

        const funcSaveOrgInfo = firebase.functions().httpsCallable('saveOrgInfo');
        funcSaveOrgInfo({ orgId: currentOrgId, name: orgname }).then(result=>{
            this.props.orgsByIdActions.setNameForOrg({ id: currentOrgId, name: orgname});
            //dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // console.log('funcSaveOrgInfo result:', orgId, result.data.data);
            // dispatch({ type: SET_DATA, orgId, data: result.data.data  });
        }).catch(e => {
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.warn('error making request in funcSaveOrgInfo',e)
        });
        
    }

    // saveOrgData = () => {
    //     fborgActions.saveProfileFields({
    //         data:{ name, ownerId, raceCodes, signedUpDate  }
    //     })
    // }

    showChangePasswordFormCall = () => { this.setState({ showChangePasswordForm: true }) }
    hideChangePasswordFormCall = () => { this.setState({ showChangePasswordForm: false }) }

    
    render(){
        const {activeTab, showChangePasswordForm} = this.state;
        const { fbuserProfile, fbuser, settings, orgsById, currentOrgId,currentOrgName} = this.props;

        //const orgName = this.getCurrentOrgName();





        const isOrgOwner = true;
      
        const page = this.props.match.path.split('/')[1];
        
        

        return (
            <div className="AccountProfile ">
                <Headroom>
                    <Header title={`ACCOUNT PROFILE`} page={page} />
                </Headroom>

                <div className="MainAthleteProfileBlock">

                    <div className="ProfileCard">

                        {/* <div className="UserCard" style={{ backgroundImage: `url(${imgLandscape})`}}>
                            <div className="UserInfoTable">

                            </div>
                        </div> */}

                        <div className="ProfileManageCard" style={{ backgroundImage: `url(${imgLandscape})`}}>


                            <ToggleGeneric
                                titles={isOrgOwner ? ['User','Org', 'Admin'] : ['User','Org','Admin']}
                                value={activeTab}
                                onChange={this.setTab}
                            />

                            { activeTab==='User' && (
                                
                                    <ManageUserContent
                                        fbuserProfile={fbuserProfile}
                                        updateField={this.updateField}
                                        parentState={this.state}
                                    />

                            )}
                            { activeTab==='Org' && (
                                
                                    <ManageOrgContent
                                        fbuserProfile={fbuserProfile}
                                        orgsById ={orgsById}
                                        settings={settings}
                                        updateField={this.updateField}
                                        parentState={this.state}
                                    />

                            )}


                            { activeTab==='Admin' && (

                                <ManageAdminContent 
                                    fbuser={fbuser}
                                    fbuserProfile={fbuserProfile}
                                    parentState={this.state}
                                    showChangePasswordForm = {showChangePasswordForm}
                                    showChangePasswordFormCall = {this.showChangePasswordFormCall}
                                    hideChangePasswordFormCall = {this.hideChangePasswordFormCall}
                                />
                                
                            )}

                            


                        </div>
                    </div>
                </div>

                <div className="container flexgrow">

                </div>


                <Footer />
                
            </div>
        )
    }
}

const mapState = (state)=>{
    const {fbuser, fbuserProfile, orgsById, settings, users, accessRolesByUser} = state;

    return {fbuser, fbuserProfile, orgsById, settings, users, accessRolesByUser,
        currentOrgId: getCurrentOrgId(state),
        currentOrgName: getCurrentOrgName(state),
    }
};
const mapDispatch = dispatch => ({
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    // userActions: bindActionCreators({clearUserStatusProfileFlag}, dispatch),
    setSetting: (props) => dispatch({ type: 'SET_SETTING', ...props }),
    orgsByIdActions: bindActionCreators(orgsByIdActions, dispatch),
})
export default withRouter(connect(mapState, mapDispatch)(AccountProfile))