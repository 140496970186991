import { Component } from "react";
import { connect } from "react-redux";
import {db} from '../../../../config/firebase'
import { updateTimingDatasetRedux } from '../../../../actions/timingDatasets'
import {getCurrentTimingDataset} from '../../../../selectors/getCurrentTimingDataset'

class TimingDocListener extends Component {
    componentDidMount(){
        this.startListening();
    }
    componentWillUnmount(){
        this.stopListening();
    }

    // on timing dataset doc in firestore change:
    onChange = (doc) => {
        const { timingDataset, timingDatasetId, updateTimingDatasetRedux } = this.props;
        if(!timingDataset) return;

        const data = doc.data();
    
        // Only update redux when certain fields changed - this should only be fields updated on backend!
    
        const watchedFields = ['csvHeaderRow', 'csvNumRows'];

        let updates = null;
        watchedFields.forEach( field => {
            // old value: timingDataset[field]
            // new value: data[field]

            if(timingDataset && data && timingDataset[field] !== data[field]){
                if(!updates) updates = {};
                updates[field] = data[field];
            }
        });

        if(updates){
            updateTimingDatasetRedux(updates, timingDatasetId);
        }


    }
    
    startListening = () => {
        this.unsubscribe = db.collection('timingSetSteps').doc(this.props.timingDatasetId).onSnapshot( this.onChange );
    }
    stopListening = () => {
        this.unsubscribe();;
    }

    render = () => null;
}

const mapState = (state)=>({
    timingDataset: getCurrentTimingDataset(state)
});
const mapDispatch = {updateTimingDatasetRedux};

export default connect(mapState, mapDispatch)(TimingDocListener);