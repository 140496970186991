import React from "react";
import { Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";
// import { useForm, ErrorMessage } from "react-hook-form";
import steeltoe from 'steeltoe'
import { connect } from 'react-redux'
import Header from "../../../components/Header/Header";
import Footer from '../../../components/Footer/Footer'
import Headroom from 'react-headroom';
import moment from "moment";
// import { toast } from 'react-toastify';
import RaceEditFilters from './RaceEditFilters';
// import { toast } from 'react-toastify';
import RaceEditMainForm from './RaceEditMainForm'
import TimingDataUploadContainer from './TimingDataUpload/Container'
import RaceImagesContainer from './RaceImages/RaceImages'
import ElevationsContainer from './Elevations/Elevations'

import "./RaceEdit.scss";

const RaceEdit = ({ match, settings, racesByOrgId }) => {
    
    // const { register, errors, triggerValidation, handleSubmit } = useForm();

    const { racecode } = match.params;

    const orgId = settings.currentOrgId;

    const { raceEditOption } = settings;


    const races = steeltoe(racesByOrgId).get(`${orgId}.data`);
    let race;
    if(races){
        race = races.find(r=>r.RaceCode === racecode);
    }
    
    const page = "Race Edit";
    const raceDateStart = race && moment(race.RaceDateStart);
    // const raceDateStartTime = raceDateStart && raceDateStart.format("dddd, MMMM Do YYYY, h:mm:ss a");
    // const raceDateStartTime = raceDateStart && raceDateStart.format("MM/DD/YYYY hh:mm:ss");
    // console.log("raceDateStartTime",raceDateStartTime);
    const raceYear = raceDateStart && raceDateStart.year();

    
    return (

        <div className="SelectRace">
            
            
            <Headroom>
                <Header title={`Edit Race`} page={page}/>
            </Headroom>


            <div className="RaceEditContent">
                <div className="container">

                    {/* <h1>Race Edit: {match.params.racecode}</h1> */}
                    <Link to={`/races-admin`}>
                        Back to all Races</Link>

                    {race && race.AdminStatus === 'Private' &&
                        <div>
                            <br/>
                            <a target="_blank" rel="noopener noreferrer" href={`https://myrace.com?private=true&orgid=${orgId}`}>Show Private Races in MyRace</a>
                        </div>
                    }
                    <h3>{raceYear} {race && race.RaceName}</h3>

                    {race && <RaceEditFilters race={race} /> }

                    {race && raceEditOption==='Race' && (
                        <RaceEditMainForm race={race} />

                    
                    )}
                    


                    {race && raceEditOption==='Timing Data' && 
                        (
                            <TimingDataUploadContainer race={race} />
                        )
                    }
                    {race && raceEditOption==='Race Images' && 
                        (
                            <RaceImagesContainer race={race} />
                        )
                    }
                    {race && raceEditOption==='Elevations' && 
                        (
                            <ElevationsContainer race={race} />
                        )
                    }
                    
                    {/* {race && raceEditOption==='Geo Points' && 
                        (
                            // <TimingDataUploadContainer race={race} />
                        )
                        } */}


                </div>
            </div>

            <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>

    )
}





const mapState = ({settings, racesByOrgId})=>({settings, racesByOrgId});

export default withRouter(connect(mapState)(RaceEdit));