// import firebase from 'firebase/app'
import {db} from '../config/firebase'
import axios from 'axios';


export const destroyBeacon = () => {
    return (dispatch, getState) => {
        const { fbuser } = getState();
        // Close Beacon if User Exists
        if(fbuser){
            window.Beacon('destroy');
        }
    }
}

export const initBeacon = () => {
    return (dispatch, getState) => {
        console.log('INIT BEACON')
        // const { fbuser } = getState();
        window.Beacon('config', { color: "#008dfb" })
        window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
        // if(fbuser){
        //     window.Beacon("open");
        // }
        window.Beacon("once", "close", ()=>dispatch(destroyBeacon()));
    }
}



export const createUserFromInvite = ({ fbuser, data}) => {
    const { email, firstname, lastname, title, phone, inviteToken, username, isEventOrg} = data;

    return dispatch => {
        // const signedUpDate = new Date();

        const uid = fbuser.uid;

        let batch = db.batch();

        // const orgRef =  db.collection('orgs').doc();

        // const orgId = orgRef.id;

        // batch.set(orgRef, {
        //     //name: `${firstname} ${lastname}`,
        //     name: orgName,
        //     raceCodes: [],
        //     signedUpDate,
        // });
        
        const userRef =  db.collection('users').doc(uid);
        batch.set(userRef, {
            email, phone, title,
            firstname, lastname, username,
            inviteToken,
            isEventOrg
            // orgId,  //QsolpDPOnR53JAcdah4d
        });
        
        // const accessRoleRef =  db.collection('accessRoles').doc();
        
        // batch.set(accessRoleRef, {
        //     uid,
        //     orgId,
        //     raceCode: '',
        //     role: 'owner',
        // });

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        batch.commit()
            .then(function() {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                console.log("User Document successfully written!");
                applyNewUserAccess();
            })
            .catch(e => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                console.error("Error writing User document: ", e);
            });
        
        function applyNewUserAccess() {
            dispatch({type:'INCREMENT_PENDING_REQUESTS'});
            axios.get('https://us-central1-my-race-com-portal.cloudfunctions.net/getInviteFromToken', {
                // axios.get('https://myrace.com/validateEmailAgainstHash', {
                    params: {
                        token: inviteToken
                    }
                }).then((response) => {
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                    // this.props.decrementPendingRequests();
                    // const EmailHash = response.data.EmailHash;
                    const responseData = response.data;
                    console.log('User created and access applied:', responseData)
                }).catch(err => {
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                    console.log('[getInviteFromToken backend call] error:', err)
                });
        }
    }


}



export const createOrgAndUser = ({ fbuser, data }) => {
    const { email, firstname, lastname, title, phone, orgName, username, isEventOrg} = data;

    return dispatch => {
        const signedUpDate = new Date();

        const uid = fbuser.uid;

        let batch = db.batch();

        const orgRef =  db.collection('orgs').doc();

        const orgId = orgRef.id;

        batch.set(orgRef, {
            //name: `${firstname} ${lastname}`,
            name: orgName,
            raceCodes: [],
            signedUpDate,
        });
        
        const userRef =  db.collection('users').doc(uid);
        batch.set(userRef, {
            email, phone, title,
            firstname, lastname, username, isEventOrg
            // orgId,  //QsolpDPOnR53JAcdah4d
        });
        
        const accessRoleRef =  db.collection('accessRoles').doc();
        
        batch.set(accessRoleRef, {
            uid,
            orgId,
            raceCode: '',
            role: 'owner',
        });

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        batch.commit()
            .then(function() {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                // console.log("User Document successfully written!");
            })
            .catch(e => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                console.error("Error writing User document: ", e);
            });
    }
}



export const saveProfileFields = (o) => {
    const { fbuser, data } = o;
    
    return dispatch => {

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error writing User document: ", e);
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        

        let saveData = {};
        Object.keys(data).forEach(key => {
            if(typeof data[key]!=='undefined') saveData[key] = data[key];
        })
        
        db
            .collection("users")
            .doc(fbuser.uid)
            .set(saveData, { merge: true })
            .then(function() {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                console.log("User Document successfully written!");
            })
            .catch(handleError);
    }
}


