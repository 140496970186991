import { createSelector } from 'reselect'
import moment from 'moment'

const getSelectedRaceCode = (state) => state.settings.selectedRaceCode;
const getRacesByOrgId = (state) => state.racesByOrgId;
const getCurrentOrgId = (state) => state.settings.currentOrgId;


export const getCurrentOrgRaces = createSelector(
    getRacesByOrgId, getCurrentOrgId,
    (racesByOrgId, currentOrgId) => {
        // console.log('SELECTOR [getCurrentOrgRaces]', racesByOrgId, currentOrgId);
        return racesByOrgId[currentOrgId] && racesByOrgId[currentOrgId].data;
    }
);

export const getSelectedRace = createSelector(
    getSelectedRaceCode, getCurrentOrgRaces,
    (RaceCode, races) => {
        // console.log('SELECTOR [getSelectedRace]', RaceCode, races);
        if(!races) { return null; }
        return races.find(race => race.RaceCode === RaceCode);
    }
);


export const getSeriesRaces = createSelector(
    getCurrentOrgRaces,
    races => {
        if(!races) return null;
        
        let events = {};
    
        races.forEach( race => { /* races - race rows in db */
            const { EventCode } = race;
            if(!events[EventCode]) {
                
                events[EventCode] = { // default event object
                    ...race,
                    TotalRaces: 0,
                    TotalParticipants: 0,
                    races: [], // races in this event
                }
            }
       
    
            // console.log("events[EventCode].EventDateStart",events[EventCode].EventDateStart);
            if (events[EventCode].EventDateStart) {
              
              // const tmpStartDate = moment.unix(race.RaceDateStart.seconds);
              const tmpStartDate = moment(race.RaceDateStart);
              // console.log("Event DATE START",race.RaceDateStart);
              // console.log("Event DATE START seconds",race.RaceDateStart.seconds);
              // console.log("Event DATE START tmpStartDate",moment.unix(race.RaceDateStart.seconds));
    
              const curEventStartDate = moment(events[EventCode].EventDateStart);
              const curEventEndDate = moment(events[EventCode].EventDateEnd);
              // const curEventStartDate = moment.unix(events[EventCode].EventDateStart.seconds);
              // const curEventEndDate = moment.unix(events[EventCode].EventDateEnd.seconds);
    
              const diffFromCurrentStartDate = curEventStartDate.diff(tmpStartDate, 'days');
              const diffFromCurrentEndDate = tmpStartDate.diff(curEventEndDate, 'days');
    
              // console.log("diffFromCurrentStartDate",diffFromCurrentStartDate);
              // console.log("diffFromCurrentEndDate",diffFromCurrentEndDate);
              if (diffFromCurrentStartDate > 0) {
                events[EventCode].EventDateStart = race.RaceDateStart;
              }
              if (diffFromCurrentEndDate > 0) {
                events[EventCode].EventDateEnd = race.RaceDateStart;
              }
    
            } else {
              events[EventCode].EventDateStart = race.RaceDateStart;
              events[EventCode].EventDateEnd = race.RaceDateStart;
    
              // console.log("Event DATE START",race.RaceCode, race.RaceDateStart);
              
            }
    
            events[EventCode].TotalRaces += 1;
            events[EventCode].TotalParticipants += (race.Participants ? race.Participants : 0);

            //be sure there is at least race has defaultRaceCode
            if (events[EventCode].TotalRaces === 1) {
               events[EventCode].DefaultRaceCode = race.RaceCode;
            } else {
              if (race.DefaultRaceCodeForEvent) {
                events[EventCode].DefaultRaceCode = race.RaceCode;
              }
            }
            
            events[EventCode].races.push(race)
        });

        return events;
        
    }
)
/*

input: racesByOrgId
output: reformatted races
[
    [seriesCode]: {

        races: []
    }
]*/

