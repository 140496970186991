import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ScrollerItems from './ui/ScrollerItems'
import {decrementPendingRequests, incrementPendingRequests} from '../../../../actions/requestsPending'
import firebase from '../../../../config/firebase'
import { connect } from 'react-redux'
import { getCheckpoints } from './Step4'
import { getFileFields } from "./Step5";

const Step7 = ({race, goToNextStep, goToPrevStep, timingDataset, currentTimingDatasetId, update, decrementPendingRequests, incrementPendingRequests}) => {
    const { handleSubmit } = useForm();
    const [scrollerKey, setScrollerKey] = useState(Date.now());
   

    const onSubmit = data => {
        console.log('STEP 7 - submitted data:', data);

        // const { TimingDataOption1 } = data;

        update(data);

        goToNextStep();
    }


    const addSuggestedValues = () => {
        // const confirmed = window.confirm('Are you sure?');
        // if(!confirmed) return;


        incrementPendingRequests()
        
        setScrollerKey(null);
        const funcTimingDataCheck = firebase.functions().httpsCallable('timingDataCheck');
        funcTimingDataCheck({ 
            timingDatasetId: currentTimingDatasetId
        }).then(result=>{
            console.log('funcTimingDataCheck result:', result.data);
            setScrollerKey(Date.now());
            decrementPendingRequests()
            // window.location.reload();
        }).catch(e => {
            decrementPendingRequests()

            console.warn('error calling backend function',e)
        });
        
    }

    const checkpoints = getCheckpoints(timingDataset);
    
    const checkpointFields = checkpoints.map(checkpoint => {
        return { name: checkpoint, title: checkpoint, editable: true };
    });

  
    return (
        <>
            
            <div className="buttons">
                <button onClick={addSuggestedValues} disabled={!scrollerKey}>TIME CHECK</button>
            </div>


            { scrollerKey ? <ScrollerItems key={scrollerKey} {...{timingDataset, currentTimingDatasetId, fields: [
                    {name:'Bib', title: 'Bib'},
                    {name:'Age', title: 'Age'},
                    {name:'Gender', title: 'Gender'},
                    {name:'SwimTime', title: 'Swim', editable: true},
                    {name:'T1Time', title: 'T1', editable: true},
                    {name:'BikeTime', title: 'Bike', editable: true},
                    {name:'T2Time', title: 'T2', editable: true},
                    {name:'RunTime', title: 'Run', editable: true},
                    {name:'FinishTime', title: 'Finish', editable: true},
                    // {name:'StartTimeOfDay', title: 'Start Time of Day', editable: true},
                    // {name:'StartTimeOfDay', title: 'Start Time of Day', editable: true},
                    ...checkpointFields
            ]}}/> : null}


            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="buttons">
                    <button onClick={goToPrevStep} disabled={!scrollerKey}>Previous</button>
                    <button type="submit" disabled={!scrollerKey}>Next</button>
                </div>
            </form>
        </>
    )
}

export default connect(null,{decrementPendingRequests, incrementPendingRequests})(Step7)