import React from 'react'
import { useSelector } from 'react-redux'

// import { LineChart, Line } from 'recharts'
import { getLogsByDay } from '../../selectors/getLogsByDay'

import {
    LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';


export default () => {
    const logs = useSelector(state=>getLogsByDay(state));
    // console.log("logs",logs)
    const hourLogs = logs.logsByHour;
    const dayLogs = logs.logsByDay;
    const pageLogs = logs.logsByPage;
    const raceCodeLogs = logs.logsByRaceCode;
    // console.log(dayLogs);

    return (
        <div>
            
            
            {/* <ResponsiveContainer width={"100%"} height={300}> */}

                {/* <LineChart

                    data={hourLogs}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="hour" tick={{fontSize: 9}} interval={0} />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    {/* <Line type="monotone" dataKey="numberOfLogs" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}


                {/* </LineChart>  */}
            {/* </ResponsiveContainer> */}

            <h3>Page Views per Day</h3>

            {/* <div>{logs.map((log,i) => {
                return (
                    <div key={i}>{JSON.stringify(log)}</div>
                    )
                })}</div> */}

            {/* <LineChart width={290} height={100} data={logs}>
                <Line type="monotone" dataKey="numberOfLogs" stroke="#8884d8" />
            </LineChart> */}

            {/* <ResponsiveContainer width={"100%"} height={300}> */}
                <LineChart
                    width={1200}
                    height={300}
                    data={dayLogs}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Line type="monotone" dataKey="numberOfLogs" stroke="#8884d8" activeDot={{ r: 8 }} />
                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}


                </LineChart>
            {/* </ResponsiveContainer> */}

           
            <h3>Page Views per Page Name</h3>

            <BarChart
                width={1200}
                height={300}
                data={pageLogs}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey={  'welcome'  } tick={{fontSize: 10}} interval={0} /> */}
                <XAxis dataKey="pageName" tick={{fontSize: 10}} interval={0} />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="numberOfLogs" fill="#8884d8" />
            </BarChart>
            
            <h3>Page Views per Race Code</h3>
            <BarChart
                width={1200}
                height={300}
                data={raceCodeLogs}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="raceCode" tick={{fontSize: 10}} interval={0} />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="numberOfLogs" fill="#8884d8" />
            </BarChart>

        </div>
    )
}


// import React, { PureComponent } from 'react';
// import {
//   LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
// } from 'recharts';

// const data = [
//   {
//     name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
//   },
//   {
//     name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
//   },
//   {
//     name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
//   },
//   {
//     name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
//   },
//   {
//     name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
//   },
//   {
//     name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
//   },
//   {
//     name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
//   },
// ];

// export default class Example extends PureComponent {
//   static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

//   render() {
//     return (
//       <LineChart
//         width={500}
//         height={300}
//         data={data}
//         margin={{
//           top: 5, right: 30, left: 20, bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
//         <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
//       </LineChart>
//     );
//   }
// }
