import React, { Component } from 'react'
import './Account.scss'
import {auth, EmailAuthProvider} from '../../config/firebase'
import imgPreviewReveal from '../../images/preview-2.svg';
import imgPreviewNoReveal from '../../images/preview-1.svg';
import classNames from 'classnames'


class PasswordField extends Component {
    state = {
        reveal: false
    }
    toggle = () => this.setState( s => ({ reveal: !s.reveal }))

    render(){
        const { reveal } = this.state;
        const { title, name, onChange, value, error, disabled }  = this.props;
        return (
            <div className={classNames("control", "PasswordField", {error: !!error})}>
                <label> 
                    <span>{ title }</span>
                    { reveal ?
                    <input name={ name } type="text" 
                        onChange={onChange} 
                        disabled={disabled}
                        value={value} 
                        />
                    :
                    <input name={ name } type="password" 
                        onChange={onChange} 
                        disabled={disabled}
                        value={value} 
                        />
                    }
                </label>
                
                <div className="RevealButton" onClick={this.toggle}>
                {reveal ?
                    <img src={imgPreviewNoReveal} alt='reveal' />
                    :
                    <img src={imgPreviewReveal} alt='reveal' />
                }
                </div>
            </div>
        )
    }
}


class ChangePasswordForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            // confirmPassword: '',
            errors: {
                currentPassword: false,
                newPassword: false,
                // confirmPassword: false,
            },
            processing: false,
            passwordChanged: false
        }
    }

    updateField = field => e => {
        this.setState({ [field]: e.target.value })
    }

    setError = error => {
        this.setState(s => ({ errors: { ...s.errors, ...error }  }))
    }


    updatePassword = () => {
        const {currentPassword, newPassword } = this.state;
        const credential = EmailAuthProvider.credential( auth.currentUser.email, currentPassword );


        this.setState({processing: true})
        auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
        .then(()=>{
            console.log('REAUTHENTICATED');
            this.setError({currentPassword: null})
            auth.currentUser.updatePassword(newPassword)
            .then(()=>{
                    this.setError({newPassword: null});
                    this.setState({ passwordChanged: true, processing: false });
                    // password updated
                    console.log('PASSWORD UPDATED')
                })
                .catch(err => {
                    console.warn('Error updating password:', err)
                    this.setError({newPassword: err.message})
                    this.setState({processing: false})
                })
            })
            .catch(err=>{
                console.warn('Error reauthenticating:', err);

                this.setError({currentPassword: err.message})
                this.setState({processing: false})
            })
    }
    
    save = (e) => {
        e.preventDefault();
        
        const {currentPassword, newPassword, processing, passwordChanged } = this.state;
        
        if(processing || passwordChanged) return;

        if(!currentPassword) {
            this.setError({currentPassword: 'Confirm password field is empty'})
            return;
        } else {
            this.setError({currentPassword: null})
        }
        if(!newPassword) {
            this.setError({newPassword: 'New password field is empty'})
            return;
        } else {
            this.setError({newPassword: null})
        }
        // if(confirmPassword !== newPassword){
        //     this.setError({confirmPassword: 'Confirm password does not match'})
        //     return;
        // }
        this.setError({newPassword: null, currentPassword: null})

        this.setState({processing: true})

        this.updatePassword();

    }
    

    render(){
        const {currentPassword, newPassword, errors, processing, passwordChanged } = this.state;

        const errorMessages = Object.keys(errors).map( field => errors[field] ).filter( errorMessage => !!errorMessage );
        const errorMessage = (!passwordChanged && errorMessages.length) ? errorMessages[0]: null;

        return (
            <div className="AccountProfileChangePassword">


                {!passwordChanged ? <form onSubmit={this.save}>

                    <PasswordField title="Current Password" name="currentPassword"
                        onChange={this.updateField('currentPassword')}
                        error={errors.currentPassword && !passwordChanged}
                        disabled={processing || passwordChanged}
                        value={currentPassword} />


                    <PasswordField title="New Password" name="newPassword"
                        onChange={this.updateField('newPassword')}
                        error={errors.newPassword && !passwordChanged}
                        disabled={processing || passwordChanged}
                        value={newPassword} />


                    {/* <PasswordField title="Confirm Password" name="confirmPassword" 
                        onChange={this.updateField('confirmPassword')} 
                        error={errors.confirmPassword && !passwordChanged}
                        disabled={processing || passwordChanged}
                        value={confirmPassword} /> */}

                    {!!errorMessage && <div className="ErrorMessage">
                        {errorMessage}
                    </div>}

                    {!passwordChanged && <div className="FormFooter">
                        <button className="secondary" onClick={this.props.onHideRequest}>Cancel</button>
                        <button className="primary" type="submit" disabled={processing}>Apply</button>

                    </div>}

                </form> : <div className="SuccessMessage">
                        Password changed successfully!
                    </div>
                }
            </div>
        )
    }
}


export default ChangePasswordForm