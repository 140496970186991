import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom';
import moment from "moment";
// import logoimg from "../../images/logo-05.svg";
import Dropdown from "../Dropdown/Dropdown";
import { bindActionCreators } from "redux";
import {connect} from 'react-redux'
import "./SelectAthleteHeader.scss";
// import * as allRacesActions from '../../actions/allRaces'
import TooltipText from '../../components/Tooltip/TooltipText'
import { getSelectedRace, getSeriesRaces } from "../../re-selectors/getSelectedRace";

class SelectAthleteHeader extends Component {

  componentDidMount(){
    // this.props.allRacesActions.getAllRaces();
  }

  state ={
    EventDateStart: null, 
    EventDateEnd: null
  }

  eventDropdownOptions = () => {
    const { raceEvents, raceSelected } = this.props;
    if(!raceEvents) return null;

    // raceSelected.EventSeriesCode;
    // const arrEvents = Object.keys(raceEvents);

    // raceEvents && console.log("raceEvents",raceEvents);
    // arrEvents[0] && console.log("raceEvents",raceEvents);
    // arrEvents[0] && console.log("TEST",raceEvents[arrEvents[0]]["EventDateStart"]);
    // arrEvents[0] && console.log("TEST",moment(raceEvents[arrEvents[0]]["EventDateStart"]).utc().seconds());

    // const compare = (a,b) => {// number sorter
    //   if(moment(a["EventDateStart"]).utc() < moment(b["EventDateStart"]).utc()) return 1;
    //   if(moment(a["EventDateStart"]).utc() > moment(b["EventDateStart"]).utc()) return -1;
    //   return 0;
    // }

    // Object.keys(raceEvents).sort(compare);

    return Object.keys(raceEvents)
      // .sort(compare)
      .filter(eventKey => raceSelected.EventSeriesCode === raceEvents[eventKey].EventSeriesCode)
      .map(eventKey => {
        const ev = raceEvents[eventKey];



        const startDate = moment(ev.EventDateStart);
        const endDate = moment(ev.EventDateEnd);

        const duration = endDate.diff(startDate, 'days');
        // console.log('DURATION:', duration )
        
        

        let eventDate = duration >= 1 ? 
          startDate.format("MMM D") + `–` + endDate.format("D • YYYY") : 
          endDate.format('MMM D • YYYY');
        


        // https://momentjs.com/docs/#/parsing/string-format/
        return {
          id: eventKey,
          title: `${eventDate}`
        }
      })

    

    
    // [
    //   {
    //     id: 2017,
    //     title: 'Dec 1, 2017'
    //   },{
    //     id: 2018,
    //     title: 'Dec 2, 2018'
    //   }, {
    //     id: 2019,
    //     title: 'Dec 3, 2019'
    //   },
    // ]
  } 

  renderTopImage = (img) => {


    const {raceSelected} = this.props;
    // console.log("img 1",img);
    const image = <img 
      className="topSponsorImage tooltip" alt={`${img.altText}`} src={`${img.src}` } />
      
    // console.log("img 2",img);
    // console.log("img 3",img.linkURL);
    if(img.linkURL) {
      return (
        <a href={img.linkURL} target="_blank" alt={img.altText} title={img.altText} rel="noopener noreferrer" className="topHeaderSponsorLink"><span />
          {image}
      
        </a>
        // <a href={`/select-athlete/${race.EventCode}/${race.RaceCode}/All-Genders`} alt={img.altText} title={img.altText} rel="noopener noreferrer" className="topSponsorLink"><span />{image}</a>
      )
    }
    
    // console.log("image 12345",image);
    return image;
  }

  static getDerivedStateFromProps(props, state) {
    // props.races

    if(props.races){
      let EventDateStart;
      let EventDateEnd;
      
      props.races.forEach( race => {
        
        if (EventDateStart) {
            
          const tmpStartDate = moment(race.RaceDateStart);
          const curEventStartDate = moment(EventDateStart);
          const curEventEndDate = moment(EventDateEnd);
          // const tmpStartDate = moment.unix(race.RaceDateStart.seconds);
          // const curEventStartDate = moment.unix(EventDateStart.seconds);
          // const curEventEndDate = moment.unix(EventDateEnd.seconds);

          const diffFromCurrentStartDate = curEventStartDate.diff(tmpStartDate, 'days');
          const diffFromCurrentEndDate = tmpStartDate.diff(curEventEndDate, 'days');

          // console.log("diffFromCurrentStartDate",diffFromCurrentStartDate);
          // console.log("diffFromCurrentEndDate",diffFromCurrentEndDate);
          if (diffFromCurrentStartDate > 0) {
            EventDateStart = race.RaceDateStart;
          }
          if (diffFromCurrentEndDate > 0) {
            EventDateEnd = race.RaceDateStart;
          }

        } else {
          EventDateStart = race.RaceDateStart;
          EventDateEnd = race.RaceDateStart;
          
        }
      });
      return { EventDateStart, EventDateEnd }
    }
    return null;
}

  handleEventSeriesDate = EventCode => {
    // console.log('Select Race:', EventCode)
    let race = this.props.raceEvents[EventCode].races.find(race => !!race.DefaultRaceCodeForEvent);
    //if there is no default then just pick first
    if (!race) {
      race = this.props.raceEvents[EventCode].races[0];
    }
    this.props.history.push(`/select-athlete/${race.EventCode}/${race.RaceCode}`)
    this.props.updateSelectedRace(race);
  }

  render() {
    
    const { raceSelected, raceLogo } = this.props;
    const { EventDateStart, EventDateEnd } = this.state;

    if(!raceSelected){ return null; }

    // console.log("EventDateStart-End",EventDateStart,EventDateEnd);

    // console.log("raceSelected",raceSelected);
    // console.log("raceLogo",raceLogo);
    // console.log("raceSelected",raceSelected);

    let header;
    let eventDate = null;
    let startDate = null;
    let endDate = null;


    if (EventDateStart && EventDateEnd) {
      startDate = moment(EventDateStart);
      endDate = moment(EventDateEnd);
      // startDate = moment.unix(EventDateStart.seconds);
      // endDate = moment.unix(EventDateEnd.seconds);

      const duration = endDate.diff(startDate, 'days');
      // console.log('DURATION:', duration )
      

      if ( duration >= 1 ) {
        eventDate = startDate.format("MMM D") + `–` + endDate.format("D, YYYY");  
      }
      // else if (duration > 1) {

      //   eventDate = startDate.format("MMM D") + ` thru ` + endDate.format("D, YYYY");  
      else {
        // eventDate = endDate.format('LL');
        eventDate = endDate.format('MMM D, YYYY');
      }
    }
      
    
    header = ( (raceSelected && raceSelected.RaceDateStart) ? 
    <div className="athlete-info event-header">
        <strong>
          <span className="event-name">{raceSelected.EventName}</span>
          {/* <span className="dot">•</span> */}
          <i className="dot">•</i>
          <span className="event-location"> <span className="event-location-flag">{`${raceSelected.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397))}`}</span>{` ${raceSelected.Location}`}</span>
          {/* <span className="dot">•</span> */}
          <i className="dot">•</i>
        </strong>
        {/* <span className="event-date">{eventDate}</span> */}
        <span className="event-date">
          <div className="tooltip"><span className="tooltiptext">Select different year</span>

            <Dropdown
              options={this.eventDropdownOptions()} value={raceSelected.EventCode}
              onChange={this.handleEventSeriesDate}
              />
          </div>
        </span>
    </div>
    : null)

    // console.log("raceLogo",raceLogo);
    // console.log("raceLogo.linkURL",raceLogo && raceLogo.linkURL);

    // const image = ( (raceSelected && raceSelected.EventSeriesCode && raceLogo && raceLogo.linkURL) ? 
    const raceImageObject = raceSelected && raceSelected.sponsorImages && raceSelected.sponsorImages[0];
    const image = (raceImageObject && raceImageObject.src ) ? 
      <a href={raceImageObject.linkURL} target="_blank" alt={raceImageObject.altText} title={raceImageObject.altText} rel="noopener noreferrer" className="topSponsorLinkAthleteList tooltip">
        <TooltipText>Event's Home Page</TooltipText>
        <span />
        {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${raceSelected.EventSeriesCode}/partners/${raceLogo.src}` } /> */}
        <img className="topSponsorImage" alt={`${raceImageObject.altText}`} src={`${raceImageObject.src}` } />
        {/* <TooltipText>Event's Home Page</TooltipText> */}
      </a> 
      : null
      


    return (
      <div className="header">
        <div className="logo-container">

          {/* <div className="logo-img-container">
            {raceLogo && this.renderTopImage(raceLogo)}
          </div> */}

          
          <div className="container">
              {image}
          </div>
        </div>

          <>
            <div className="athlete-info-container">
                {header}     
            </div>
            
            <div className="rightbar-container">
              
            </div>


          </>
        
      </div>
    );
  }
}


const mapStateToProps = state => ({
  // raceSelected: state.raceSelected,
  raceSelected: getSelectedRace(state),
  raceEvents: getSeriesRaces(state),
  allRaces: state.allRaces,
  // raceEvents: state.raceEvents
})

const mapDispatchToProps = dispatch => ({
  // allRacesActions: bindActionCreators(allRacesActions, dispatch),
  updateSelectedRace: race => dispatch({type:'UPDATE_SELECTED_RACE', race}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectAthleteHeader));
