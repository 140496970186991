import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});



export const SET_STATUS = "USER_BY_UID/SET_STATUS";
export const SET_DATA = "USER_BY_UID/SET_DATA";


export default (state = initialState.usersByUid, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.uid
        // expected: action.requestStatus

        return update(state, {
            [action.uid]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_DATA: {
        // expected: action.uid
        // expected: action.data

        return update(state, {
            [action.uid]: { $auto: {
                requestStatus: { $set: 'received' },
                data: { $set: action.data },
            }}
        });
    }

        
    default:
      return state;
  }
};
