import React, { useEffect, useState, createContext } from 'react'
import { connect } from 'react-redux'
// import { setSettings } from '../../actions/settings'
import { db } from '../../config/firebase';


export const BackendPingContext = createContext(null);


function ListenerServerPingAlive({ fbuser, children }){
    const [backendPing, setBackendPing] = useState(null);
    useEffect(()=>{
        if(fbuser) {
            // console.log('[ListenerServerPingAlive] start listening')
            return db.collection('z_dashboard').doc('BackendPing').onSnapshot( doc => {
                // console.log('[ListenerServerPingAlive] snapshot:', doc.data());
                // setSettings({ BackendPing: doc.data() });
                setBackendPing(doc.data());
            });
        }
    }, [fbuser]);
    // return null;
    return (
        <BackendPingContext.Provider value={backendPing} >
            {children}
        </BackendPingContext.Provider>
    )
}

export default connect(({ fbuser })=>({ fbuser }))(ListenerServerPingAlive)