import React, { useState, useReducer } from "react";
import firebase, {storage} from '../../../../config/firebase'
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from 'react-redux'
// import { setFieldsForRace as setFieldsForRaceInRedux } from '../../../../actions/racesByOrgId'
import { incrementPendingRequests, decrementPendingRequests } from '../../../../actions/requestsPending'


const getStorageFilePath = (RaceCode) => `${RaceCode}/elevations`;


const stepInitialState = {
    callStatus: '', // pending, success, error
};

const stepStateReducer = (state, action) => {
    // action.type
    return {...state, ...action};
}


const ElevationsContainer = ({ race }) => {
    const { handleSubmit } = useForm();
    const [state, setState] = useReducer(stepStateReducer, stepInitialState);
    const [fileIsUploading, setFileIsUploading] = useState(false);

    const onSubmit = data => {
        if(fileIsUploading) return;

        console.log('ELEVATION DATA - submitted data:', data);
        incrementPendingRequests()

        const elevationsDataFile = getStorageFilePath(race.RaceCode);
        console.log("elevationDataFile",elevationsDataFile)

        // call the backend function that will process the uploaded file
        const funcProcessElevationsFile = firebase.functions().httpsCallable('processElevationsFile');
        funcProcessElevationsFile({ elevationsDataFile, RaceCode:race.RaceCode }).then(result=>{
            console.log('processElevationsFile result:', race.RaceCode, result.data);
        }).catch(e => {
            console.warn('error calling backend function',e)
        });

        decrementPendingRequests();
    }

    const processElevations = () => {
        // const confirmed = window.confirm('Are you sure?');
        // if(!confirmed) return;


        incrementPendingRequests();
        
        setState({ callStatus: 'pending' });
        const funcaddTask = firebase.functions().httpsCallable('addTask');
        funcaddTask({ 
            payload: {
                raceCode: race.RaceCode,
            },
            type:'processElevations'
            // orgId
        }).then(result=>{
            console.log('funcaddTask result:', result.data);
            setState({ taskId: result.data.id });
            
            
            decrementPendingRequests()
            // window.location.reload();
        }).catch(e => {
            setState({ callStatus: 'error' });
            decrementPendingRequests();

            console.warn('error calling backend function',e)
        });
        
    }

    
    return (
        <>

            { !!state.errorMessage && <p style={{color:'red'}}>ERROR: {state.errorMessage}</p>}
        

            <form onSubmit={handleSubmit(onSubmit)}>

                <p>Upload your elevations file.</p>
                <p>The file must be csv and have two columns named distance and feet (values must be in miles and feet.)</p>
                <p>For example</p>
                <table>
                    <tr>
                        <td>distance,elevation</td>
                    </tr>
                    <tr>
                        <td>0,341.502</td>
                    </tr>
                    <tr>
                        <td>0.412,341.502</td>
                    </tr>
                    <tr>
                        <td>0.533,324.803</td>
                    </tr>
                    <tr>
                        <td>0.898,302.821</td>
                    </tr>
                </table>
                <br/>
                <br/>
                <input type="file" 
                    disabled={fileIsUploading}
                    onChange={e => {
                        const file = e.target.files && e.target.files[0];
                        console.log('FILE', file );
                        setFileIsUploading(true);
                        const storagePath = getStorageFilePath(race.RaceCode);
                        storage.ref().child(storagePath).put(file)
                            .then(snap =>{
                                setFileIsUploading(false);
                                console.log('done uploading file');
                                // update({ timingDataFile: storagePath });
                            })
                            .catch(err => {
                                setFileIsUploading(false);
                                console.log('error uploading file:', err);
                            });
                    }} 
                />

                

                <div className="buttons">
                    <button value="continue" type="submit">Save</button>
                </div>
                
            </form>

            <div className="buttons">
                <button onClick={processElevations}>INITIATE LOAD TO MYRACE</button>
            </div>


        </>
    )
}


const mapState = ({settings, racesByOrgId})=>({settings, racesByOrgId});
const mapDispatch = ({ 
    // setFieldsForRaceInRedux, 
    //incrementPendingRequests, // <- this.props.incrementPendingRequests()
    // decrementPendingRequests 
});


export default withRouter(connect(mapState, mapDispatch)(ElevationsContainer));
