import initialState from "./initialState";


export const SET_VALUES = "AD_BUILDER/SET_VALUES";
export const TOGGLE_VALUES = "AD_BUILDER/TOGGLE_VALUES";



export default (state = initialState.adBuilder, action) => {
  switch (action.type) {
    

    case SET_VALUES: {
      // console.log('set setting', action);
      let newState = state;
      // let newState = { ...state };
      for(let prop in action){
        if(prop!=='type'){
          if(state[prop] !== action[prop]) {
            newState = {...newState, [prop]: action[prop] }
          }
          //newState[prop] = action[prop];
        }
      }
      return newState;
    }

    case TOGGLE_VALUES: {
      let newState = { ...state };
      for(let prop in action){
        if(prop!=='type'){
          newState[prop] = !state[prop];
        }
      }
      return newState;
    }
      
    default:
      return state;
  }
};
