import "whatwg-fetch";
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';

// SERVICE WORKER
// import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from "./registerServiceWorker";
import {  unregister } from "./registerServiceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { Provider } from "react-redux";

import reducers from "./reducers";

if (process.env.NODE_ENV === 'production') {
    console.log = function(){};
    console.warn = function(){};
    console.error = function(){};
  }
  
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
  );
  
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );
  unregister();

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
//   serviceWorker.unregister();
  // registerServiceWorker();

// ReactDOM.render(<App />, document.getElementById('root'));


