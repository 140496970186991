import React, { Component } from "react";
import moment from "moment";
import { Link, withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
import {formatNumber,secondsToTimeStripWithSign,secondsToTime,secondsToTimeStrip} from '../../utils/timeUtils'

import imgCamHover from '../../images/cam-1.svg'
// import imgCamHover from '../../images/cam-2.svg'
import imgCam from '../../images/cam-2.svg'
// import imgCertHover from '../../images/flag-1.svg'
// import imgCert from '../../images/flag-2.svg'
import FavIconLink from './FavIconLink'
import classNames from 'classnames'
import { connect } from 'react-redux'
import AthleteDivisionCells from './AthleteDivisionCells'
import TooltipText from '../Tooltip/TooltipText'

const stopPropagation = e => e.stopPropagation();

const PhotoIconLink = props => {
    const { row, race } = props;

    if(!race || !race.PhotoPartnerURL) {
        return null;
    }

    let photoLink;
    if (race.PhotoPartnerURL.includes("$bib")) {

        photoLink = race.PhotoPartnerURL.replace("$bib",row.Bib);
    } else {
        photoLink = race.PhotoPartnerURL + row.Bib;
    }


    return (
        <a href={photoLink} target="_blank" alt="photo" title="" rel="noopener noreferrer" className="PhotoIconLink" onClick={stopPropagation}>
            <div 
                // data-tip="Photos" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                className="IconSwappable tooltip"><TooltipText>Athlete's Photos</TooltipText>
                <div className="icon" style={{ backgroundImage: `url(${imgCam})`}}/>
                <div className="hover" style={{ backgroundImage: `url(${imgCamHover})`}}/>
            </div>
        </a>
    )
}




class Row extends Component {

    goto = row => e => {
        const { raceCode, onClick } = this.props;
        // console.log("row",row);
        // console.log("raceCode ===",raceCode);
        // console.log("about to call onclick",onClick);
        onClick("athleteList");
        
        this.props.history.push(`/welcome/${raceCode}/athletes/${row.Bib}`)
    }

   
    // onCellEntered = colnum => e => {
    //     this.props.setMouseoveredCol(colnum);
    // }
    

    render(){

        // const { index, row } = this.props;
        const { row, race, settings, isMobile, showAgeGrade } = this.props;
        if(!race) { return null; }
        const showHalfSplits = race && race.ShowHalfSplits;
        const raceAthleteCategories = race.RaceAthleteCategories && race.RaceAthleteCategories.split(',');


        // console.log("race",race,useEliteMastersVIPlacing);
        // console.log("row.CountryTwoCharCode",row.CountryTwoCharCode);
        // console.log("showAgeGrade",showAgeGrade);
        const countryFlag =  row.CountryTwoCharCode && (row.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)));
       
        const city = row.City;
        // const newCity = city.length > 19 ? (city.substring(0,10) + ' ... ' + city.substring(city.length-8,city.length) ) : city;
        const newCity = city.length > 19 ? (city.substring(0,7) + ' ... ' + city.substring(city.length-6,city.length) ) : city;
       
       
       let hometownLocation = '';
       if (row.StateCode) {
        hometownLocation = newCity + (row.City ? ', ' : '') + row.StateCode;
       }  
       else if (row.City  && row.CountryThreeCharCode) {
        hometownLocation = newCity + ', ' + row.CountryThreeCharCode;
       }

       if (hometownLocation && hometownLocation.length === 0 && row.CountryName) {
        //    hometownLocation = row.CountryName;
           hometownLocation =  row.CountryName.length > 19 ? 
            (row.CountryName.substring(0,7) + ' ... ' + row.CountryName.substring(row.CountryName.length-6,row.CountryName.length) ) : 
            row.CountryName;
       }

       const selected = settings.lastSelectedAthleteBib === row.Bib;


       // 
       hometownLocation = <><span className="countryFlag">{countryFlag}</span><span>{hometownLocation}</span></>;
    //    console.log("ROWXXX",row);

    
        const absoluteSplitsAchievedDifferenceTimeSeconds = Math.abs(row.SplitsAchievedDifferenceSeconds);
        const splitsDifference = secondsToTime(absoluteSplitsAchievedDifferenceTimeSeconds);
        // const splitsDifference = secondsToTimeStrip(absoluteSplitsAchievedDifferenceTimeSeconds);
        
        const absoluteFirstHalfTimeSeconds = Math.abs(row.FirstHalfTimeSeconds);
        const FirstHalfTime = secondsToTime(absoluteFirstHalfTimeSeconds);
        
        
        const absoluteSecondHalfTimeSeconds = Math.abs(row.SecondHalfTimeSeconds);
        const SecondHalfTime = secondsToTime(absoluteSecondHalfTimeSeconds);

        const athleteName = row.Name.length > 21 ? row.Name.substring(0,19) + "..." : row.Name;
        // const athleteName = row.Name.substring(1,5);


       let splitsDiffFormatted = splitsDifference; // default to full time format 00:00:00
        if (absoluteSplitsAchievedDifferenceTimeSeconds < 600 ) { //show 0:00 if time is less than 10:00 
            splitsDiffFormatted = splitsDifference.substring(4,8);
        } else if (absoluteSplitsAchievedDifferenceTimeSeconds < 3600) { //show 00:00 if time is less than 1:00:00
            splitsDiffFormatted = splitsDifference.substring(3,8);
        } else if (absoluteFirstHalfTimeSeconds < 36000) { //show 0:00:00 if time is less than 1:00:00
            splitsDiffFormatted = splitsDifference.substring(1,8);
        } 

       let FirstHalfTimeFormatted = FirstHalfTime; // default to full time format 00:00:00
        if (absoluteFirstHalfTimeSeconds < 600 ) { //show 0:00 if time is less than 10:00 
            FirstHalfTimeFormatted = FirstHalfTime.substring(4,8);
        } else if (absoluteFirstHalfTimeSeconds < 3600) { //show 00:00 if time is less than 1:00:00
            FirstHalfTimeFormatted = FirstHalfTime.substring(3,8);
        } else if (absoluteFirstHalfTimeSeconds < 36000) { //show 00:00 if time is less than 1:00:00
            FirstHalfTimeFormatted = FirstHalfTime.substring(1,8);
        } 
        
       let SecondHalfTimeFormatted = SecondHalfTime; // default to full time format 00:00:00
        if (absoluteSecondHalfTimeSeconds < 600 ) { //show 0:00 if time is less than 10:00 
            SecondHalfTimeFormatted = SecondHalfTime.substring(4,8);
        } else if (absoluteSecondHalfTimeSeconds < 3600) { //show 00:00 if time is less than 1:00:00
            SecondHalfTimeFormatted = SecondHalfTime.substring(3,8);
        } else if (absoluteSecondHalfTimeSeconds < 36000) { //show 00:00 if time is less than 10:00:00
            SecondHalfTimeFormatted = SecondHalfTime.substring(1,8);
        } 

    //    console.log("FirstHalfTime",FirstHalfTime)
    //    console.log("absoluteFirstHalfTimeSeconds",absoluteFirstHalfTimeSeconds)
    //    console.log("FirstHalfTimeFormatted",FirstHalfTimeFormatted)
        const paceSign = row.SplitsAchievedDifferenceSeconds>0 ? <span className="diffPlus">+</span> : <span className="diffMinus">–</span>
    //    const splitDifferenceLabel = row.SplitsAchievedDifferenceSeconds>0 ? " +" + splitsDiffFormatted : " –" + splitsDiffFormatted;
       

       const pace = `${moment().startOf('day').add(moment.duration({s: row.FinishEnglishPace})).format('m:ss')}`;


       let divList = [];
       let divListNames = [];
       let defaultSelectedOption = null;
       let selectedCategory = null;
       let selectedOptionPlace = null;
       
       if (raceAthleteCategories.includes("EL") && row.DivisionEliteGroup) {
            divList.push("EL");
            divListNames.push("Elite");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "EL";
        }
        if (raceAthleteCategories.includes("MA") && row.DivisionMastersGroup) {
            divList.push("MA");
            divListNames.push("Masters");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "MA";
        }
        if (raceAthleteCategories.includes("WE") && row.DivisionAthenaClydesdaleGroup) {
            divList.push("WE");
            divListNames.push("Weight");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "WE";
        }
        if (raceAthleteCategories.includes("VI") && row.DivisionVisuallyImpairedGroup) {
            divList.push("VI");
            divListNames.push("Visually Impaired");
            
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "VI";
        }
        if (raceAthleteCategories.includes("AG") && row.DivisionAgeGroup) {
            divList.push("AG");
            divListNames.push("Age Group");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "AG";
        }

        // defaultSelectedOption = divList[selectedCategoryIndex];

        if (defaultSelectedOption == "EL") {
            selectedCategory = "ELITE • " + row.Gender;
            selectedOptionPlace = row.PlaceDivisionEliteGroup;
        } else if (defaultSelectedOption == "AG") {
            selectedCategory = row.DivisionAgeGroup.replace('-','–');
            selectedOptionPlace = row.PlaceDivisionAgeGroup;
        } else if (defaultSelectedOption == "MA") {
            selectedCategory = "MAS • " + row.Gender;
            selectedOptionPlace = row.PlaceDivisionMastersGroup;
        } else if (defaultSelectedOption == "WE") {
            selectedCategory = row.Gender === "F" ? "ATH" : "CL";
            selectedOptionPlace = row.PlaceDivisionAthenaClydesdaleGroup;
        } else if (defaultSelectedOption == "VI") {
            selectedCategory = "VI • " + row.Gender;
            selectedOptionPlace = row.PlaceDivisionVisuallyImpairedGroup;
        } 


       if(isMobile){
            return (
                <div className="AthleteMobileCard" onClick={this.goto(row)}>
                    <div className="Line1">
                        <div className="PlaceOverall">{formatNumber(row.PlaceOverall)}</div>
                        <div className="Name">{athleteName}</div>
                        <div className="Bib">{row.Bib}</div>
                    </div>

                    <div className="Line2">
                        <div className="HometownLocation">{hometownLocation}</div>
                        <div className="FinishTime">{row.FinishTime}</div>
                    </div>

                    <dl className="AthleteCardData">
                        <dt>Pace</dt>
                        <dd>{pace}</dd>
                        <dt>Division</dt>
                        {/* <dd>{row.DivisionType1.replace('-','–')}</dd> */}
                        <dd>{selectedCategory}</dd>
                        {showHalfSplits ? 
                            <>
                            <dt>1st Half</dt>
                            <dd>{FirstHalfTimeFormatted}</dd>
                            <dt>Div. Place</dt>
                            <dd>{selectedOptionPlace}</dd>
                            <dt>2nd Half</dt>
                            <dd>{SecondHalfTimeFormatted}</dd>
                            </> : null
                        }
                        <dt>Gender Place</dt>
                        <dd>{row.PlaceGender}</dd>
                        {showHalfSplits ? 
                        <>
                        <dt>Difference</dt>
                        <dd>{paceSign} {splitsDiffFormatted}</dd>
                        </> : null
                        }
                        {showAgeGrade ?  
                            <>
                                <dd>{`${(row.AgeGrade*100).toFixed(2)} %`}</dd>
                                <dt>Age Grade</dt>
                            </>
                            
                            : null}
                    </dl>


                </div>
            )
       }
       
       

       {/* <tr data-tip="Open" data-effect='float' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" */}
        return (
            <tr className={classNames({ selected })} onClick={this.goto(row)}>
                {/* <td style={{ width: '3%'}}>{formatNumber(row.PlaceOverall)} <ReactTooltip  /></td> */}
                <td style={{ width: '3%'}}>{formatNumber(row.PlaceOverall)}</td>
                <td style={{ width: '13%'}}>{athleteName}  <PhotoIconLink race={race} row={row} /> <FavIconLink row={row} /> </td>
                {/* <td style={{ width: '13%'}}>{athleteName}  <PhotoIconLink race={race} row={row} />  </td> */}
                <td style={{ width: '13%'}}>{hometownLocation}</td>
                <td style={{ width: '4%'}}>{row.Bib}</td>
                <td style={{ width: '5%'}}>{row.FinishTime}</td>
                <td style={{ width: '4%'}}>{pace}</td>
                {/* <td style={{ width: '6%'}}>{`${row.SplitsAchieved} ${secondsToTimeStripWithSign(row.SplitsAchievedDifferenceSeconds)}`}</td> */}
                {/* <td style={{ width: '6%'}}>{`${moment().startOf('day').add(moment.duration({s: row.SplitsAchievedDifferenceSeconds})).format('mm:ss')}`}</td> */}
                {showHalfSplits ? 
                    <>
                        {/* <td style={{ width: '12%'}}>{`${row.SplitsAchieved} by ${secondsToTime(Math.abs(row.SplitsAchievedDifferenceSeconds))}`}</td> */}
                        <td style={{ width: '5%'}}>{FirstHalfTimeFormatted}</td>
                        <td style={{ width: '5%'}}>{SecondHalfTimeFormatted}</td>
                        {/* <td style={{ width: '4%'}}>{splitDifferenceLabel}</td> */}
                        <td style={{ width: '4%'}}>{paceSign}{splitsDiffFormatted}</td>
                    </>
                    :
                    null
                }
                {
                    <AthleteDivisionCells raceAthleteCategories={raceAthleteCategories} row={row} isHeader={false} />
                }

                {showAgeGrade ? 
                    <td style={{ width: '6%'}}>{`${(row.AgeGrade*100).toFixed(0)} %`} 
                        {/* <CertificateIconLink race={race} row={row} /> */}
                    </td>
                : null }
                {/* {useEliteMastersVIPlacing ? 
                         <>
                            <td style={{ width: '3%'}}>{row.Age}</td>
                            <td style={{ width: '5%'}}>{row.DivisionEliteGroup}</td>
                            <td style={{ width: '5%'}}>{row.PlaceDivisionEliteGroup}</td>
                            <td style={{ width: '6%'}}>{row.DivisionAgeGroup}</td>
                            <td style={{ width: '5%'}}>{row.PlaceDivisionAgeGroup}</td>
                            <td style={{ width: '5%'}}>{row.DivisionMastersGroup}</td>
                            <td style={{ width: '5%'}}>{row.PlaceDivisionMastersGroup}</td>
                            <td style={{ width: '5%'}}>{row.DivisionVisuallyImpairedGroup}</td>
                            <td style={{ width: '5%'}}>{row.PlaceDivisionVisuallyImpairedGroup}</td>
                            {showAgeGrade ? 
                                <td style={{ width: '3%'}}>{`${(row.AgeGrade*100).toFixed(0)} %`} 
                                    <CertificateIconLink race={race} row={row} />
                                </td>
                            : null }
                         </> : 
                         <>
                            <td style={{ width: '6%'}}>{row.DivisionType1.replace('-','–')}</td>
                            <td style={{ width: '5%'}}>{row.PlaceDivision1}</td>
                            <td style={{ width: '6%'}}>{row.PlaceGender}</td>
                            {showAgeGrade ? 
                                <td style={{ width: '13%'}}>{`${(row.AgeGrade*100).toFixed(2)} %`} <CertificateIconLink race={race} row={row} /></td>
                            : null }
                         </>
                } */}

                
                {/* <td style={{ width: '6%'}}>{`${row.SplitsAchieved} ${secondsToTimeStripWithSign(row.SplitsAchievedDifferenceSeconds)}`}</td> */}
                
                
                {/* raceSelected.HasPhotoPartner? */}
            </tr>
        )
    }
}


const mapState = ({settings}) => ({settings});
// const mapDispatch = dispatch => ({
//     setMouseoveredCol: (colnum)=>dispatch({type:'SET_SETTING', mouseoveredCol: colnum }),
// })


export default withRouter(connect(mapState)(Row));