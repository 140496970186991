import React, { Component } from "react";
// import "./RaceFilters.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
// import TooltipText from '../Tooltip/TooltipText'

class RaceCodeFilters extends Component {


  componentDidMount(){
    if(this.props.races) {
      // this.enableAllRacesOnMount();
    }
  }
  
  componentDidUpdate(prevProps){
    if(!prevProps.races && this.props.races){
      // this.enableAllRacesOnMount();
    }
  }

  enableAllRacesOnMount = () => {
    this.props.setRaceCodeFilterOption(this.getRaceCodes());
  }

  getRaceCodes = () => {

    return this.props.races.reduce((raceCodes, race) => {
      return [...raceCodes, race.RaceCode];
    }, []);
  }

  selectFilterOption = filterOption => e => {
    // if(this.props.requestsPending){ return; }
    const { raceCodeFilterOption } = this.props.settings;
    const newArr = [];
    newArr.push(filterOption)
    this.props.setRaceCodeFilterOption(newArr)


    // const clone = raceCodeFilterOption.slice(0);
    // const index = clone.indexOf(filterOption);

    // if (index > -1) {
    //     clone.splice(index, 1);
    // } else {
    //     clone.push(filterOption);
    // }
    // this.props.setRaceCodeFilterOption(clone)
  }

  render() {
    const { races } = this.props;
    const { raceCodeFilterOption } = this.props.settings;

    const raceOptionFilters =  races ? this.getRaceCodes(): [];// ['TSFM','BHM'];
    
    return (
      <div className="RaceStatusFilters">
        
        <div className="line"/>

        { raceOptionFilters.map( filterOption => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={filterOption} onClick={this.selectFilterOption(filterOption)} 
            className={classnames("button", { selected: raceCodeFilterOption.includes(filterOption)})}>

            {filterOption} 
          </div>
        ))}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = ({ settings, requestsPending }) => ({ settings,requestsPending })

const mapDispatchToProps = dispatch => ({
//   setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE_FILTER_STATUS', race}),
  setRaceCodeFilterOption: raceCodeFilterOption => dispatch({type:'SET_SETTING', raceCodeFilterOption}),
 
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceCodeFilters));

