import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});

export const SET_STATUS = "ORG_MEMBERS/SET_STATUS";
export const SET_MEMBERS = "ORG_MEMBERS/SET_MEMBERS";


export default (state = initialState.orgMembers, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.orgId
        // expected: action.requestStatus

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_MEMBERS: {
        // expected: action.orgId
        // expected: action.members

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: 'received' },
                members: { $set: action.members },
            }}
        });
    }

        
    default:
      return state;
  }
};
