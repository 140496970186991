import React from "react";
import firebase from '../../config/firebase'
import { Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { useForm, ErrorMessage } from "react-hook-form";
import steeltoe from 'steeltoe'
import { connect } from 'react-redux'
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import Headroom from 'react-headroom';
import classNames from 'classnames'
import moment from "moment";
import { toast } from 'react-toastify';
import LogsPerDay from './LogsPerDay'
import LogsPerDayZoomable from './LogsPerDayZoomable'
import RaceEventSeriesCodeFilters from './RaceEventSeriesCodeFilters';

import "./Analytics.scss";

const MyRaceAnalytics = ({ match, history, settings, racesByOrgId }) => {
        
    const page = "MyRace Analytics";
    const orgId = steeltoe(settings).get(`currentOrgId`);
    const races = steeltoe(racesByOrgId).get(`${orgId}.data`);


    
    return (

        <div className="SelectRace">
            
            
            <Headroom>
                <Header title={`MyRace Analytics`} page={page}/>
            </Headroom>


            <div className="AnalyticsContent">
                <div className="container">

                    <RaceEventSeriesCodeFilters races={races} />

                    <h2>Analytics</h2>
                    <LogsPerDay />
                    {/* <LogsPerDayZoomable /> */}

                  
                </div>
            </div>

            <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>

    )
}


const mapState = ({settings, racesByOrgId})=>({settings, racesByOrgId});

export default withRouter(connect(mapState)(MyRaceAnalytics));