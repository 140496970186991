import React, { useEffect } from 'react'
import firebase from '../../config/firebase'
import { Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { useForm, ErrorMessage } from "react-hook-form";
import steeltoe from 'steeltoe'
import { connect } from 'react-redux'
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import Headroom from 'react-headroom';
import classNames from 'classnames'
import moment from "moment";
import { toast } from 'react-toastify';
import LogsPerDay from './LogsPerDay'
import LogsPerDayZoomable from './LogsPerDayZoomable'
import RaceCodeFilters from './RaceCodeFilters';

import "./Analytics.scss";


const YourRaceAnalytics = ({ match, history, settings, racesByOrgId, racesAnalyticsByOrgId }) => {
        
    const page = "YourRace Analytics";
    const orgId = steeltoe(settings).get(`currentOrgId`);
    const races = steeltoe(racesByOrgId).get(`${orgId}.data`);
    const raceCodeFilterOption = steeltoe(settings).get(`raceCodeFilterOption`);
    const raceAnalytics = steeltoe(racesAnalyticsByOrgId).get(`${orgId}.data`);
    
    const raceCode = raceCodeFilterOption && raceCodeFilterOption[0];

    let raceAnalyticForRaceCode = null;
    
    if (raceCode && raceAnalytics) {
        raceAnalyticForRaceCode = raceAnalytics.find(r=>r._id === raceCode);
    }

    raceAnalyticForRaceCode && console.log("raceAnalyticForRaceCode",raceAnalyticForRaceCode);


    
    return (

        <div className="SelectRace">
            
            
            <Headroom>
                <Header title={`Analytics`} page={page}/>
            </Headroom>


            <div className="AnalyticsContent">
                <div className="container">

                    <RaceCodeFilters races={races} />

                    <h2>Analytics</h2>
                    { raceAnalyticForRaceCode && 
                        <>
                    
                            <p>Total Finishers: {raceAnalyticForRaceCode.totalFinishers}</p>
                            <p>Total Males: {raceAnalyticForRaceCode.totalMales} </p>
                            <p>Total Females: {raceAnalyticForRaceCode.totalFemales} </p>
                            <hr/>
                            <p>Average Finish Time: {raceAnalyticForRaceCode.avgFinishTime} </p>
                            <p>Average Finish Time (M): {raceAnalyticForRaceCode.avgFinishTimeM} </p>
                            <p>Average Finish Time (F): {raceAnalyticForRaceCode.avgFinishTimeF} </p>
                            <hr/>
                            <p>Average Median Time: {raceAnalyticForRaceCode.medianTime} </p>
                            <p>Average Median Time (M): {raceAnalyticForRaceCode.medianTimeM} </p>
                            <p>Average Median Time (F): {raceAnalyticForRaceCode.medianTimeF} </p>

                        </>
                    }

                  
                </div>
            </div>

            <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>

    )
}


const mapState = ({settings, racesByOrgId, racesAnalyticsByOrgId})=>({settings, racesByOrgId, racesAnalyticsByOrgId});

export default withRouter(connect(mapState)(YourRaceAnalytics));