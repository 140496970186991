import React, { Component, useEffect } from "react";
import firebase, { db } from '../../config/firebase'
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'

import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import moment from 'moment';
// import landscape from "/images/city-generic/generic-02.svg";
import "./MembersAdminPage.scss";
import {withRouter} from 'react-router-dom';
import steeltoe from 'steeltoe';
import Headroom from 'react-headroom';
import * as orgActions from '../../actions/org';
import * as usersByUidActions from '../../actions/usersByUid';
import * as invitesActions from '../../actions/invites';
import InviteMemberModalForm from "./InviteMemberModalForm";



// firestore security rules
// Allow request to users/{uid}
// when current user is owner/admin of the same org
// how do we know they are in the same org?
//     - look the user up in the accessRoles by uid -- we need to know their orgId
//     - find all users with the same orgId in accessRoles
//     - that allows the user to look all those users in the users collection
    
class MembersAdminPage extends Component {

  componentDidMount(){
    console.log('MembersAdminPage:', this.props.match.params.orgId );

    this.setUp();
  }

  componentDidUpdate(prevProps){
    const { fbuserProfile, fbuser, settings } = this.props;
    const prevUid = steeltoe(prevProps).get(`fbuser.uid`);
    const uid = fbuser ? fbuser.uid : null;

    // if(uid) console.log('UID:', uid)

    if (prevProps.settings.currentOrgId !== settings.currentOrgId) {
      this.props.history.push(`/admin/org/${settings.currentOrgId}/members`);
    }


    if((!prevProps.fbuserProfile && fbuserProfile)
      ||
      uid!==prevUid
      ||
      prevProps.match.params.orgId !== this.props.match.params.orgId
      ||
      steeltoe(prevProps.accessRolesByUser).get(`${uid}.requestStatus`) !== steeltoe(this.props.accessRolesByUser).get(`${uid}.requestStatus`)
    ) {
      this.setUp();
    }
  }

  componentWillUnmount(){
    this.stopListeningToInvitesChanges();
  }
  
  setUp = () => {
    const { fbuserProfile, accessRolesByUser, fbuser, orgsByUid, invites, settings } = this.props;
    if(!fbuser){ return; }
    const { uid } = fbuser;
    // we have fbuserProfile.orgs: []
    // we need orgId

    // this.props.match.params.orgId - if null, then figure which orgId and redirect:
    // /admin/members ->
    // redirect to /admin/org/[orgId]/members

    const {orgId} = this.props.match.params;


    if(!orgId){ // orgId is not in the URL

      if(!steeltoe(accessRolesByUser).get(`${uid}.requestStatus`)){
        this.props.orgActions.requestOrgs(uid);
        return;
      }

      // console.log('THE STATUS', steeltoe(accessRolesByUser).get(`${uid}.requestStatus`))
      
      if(steeltoe(accessRolesByUser).get(`${uid}.requestStatus`)!=='received'){
        return; // NOT RECEIVED YET, WAITING
      }
      
      const orgs = steeltoe(accessRolesByUser).get(`${uid}.orgs`) || [];

      // console.log('WE GOT ORGS:', orgs);
      
      if (orgs.length === 1){
        const {orgId} = orgs[0];
        this.props.history.push(`/admin/org/${orgId}/members`);
      } else { 
        // if more than one - check the settings
        // console.log('settings:', settings);
        if (settings && settings.currentOrgId)
        {
          this.props.history.push(`/admin/org/${settings.currentOrgId}/members`);
        }
      }
    }

    if(orgId && !steeltoe(invites).get(`${orgId}.requestStatus`)){
      // this.props.invitesActions.requestInvites(orgId);

      this.startListeningToInvitesChanges(orgId);
    }

    // console.log('status;', steeltoe(this.props).get(`orgMembers[orgId].requestStatus`));

    // we need list of members for this org


    // DONE? request list of members for orgId, if we didn't start the request already
    // this.props.orgMembers[orgId].requestStatus
    if(orgId && !steeltoe(this.props).get(`orgMembers.${orgId}.requestStatus`)){
      // console.log('// start request');
      // run action
      this.props.orgActions.requestOrgMembers(orgId);
    }
    
    
  }




  startListeningToInvitesChanges = (orgId) => {
    this.stopListeningToInvitesChanges();
    
    // if(this._unsubFromInvitesChanges) {
    //   return;
    // }

    const onInvitesChange = snap => {
      let invites = [];
      snap.forEach( doc => {
          invites.push({...doc.data(), id: doc.id});
      });
      this.props.invitesActions.setInvites({ orgId, invites });
    };
    
    this._unsubFromInvitesChanges = db.collection("invites").where("orgId", "==", orgId)
      .onSnapshot(onInvitesChange);
  }


  stopListeningToInvitesChanges = () => {
    if(typeof this._unsubFromInvitesChanges === 'function'){
      this._unsubFromInvitesChanges();
      this._unsubFromInvitesChanges = null;
    }
  }


    // returns array of strings, eg. ['owner','admin']
    rolesForCurrentOrgId = () => {
      const { fbuser, accessRolesByUser } = this.props;
      const currentOrgId = this.getCurrentOrgId();
      const accessRoles = steeltoe(accessRolesByUser).get(`${steeltoe(fbuser).get('uid')}.orgs`) || [];
      let accessRolesByUid = {};
      let accessRolesForThisOrgId = [];
      currentOrgId && accessRoles.length &&  accessRoles.forEach(accessRole => {
        const { orgId } = accessRole;
        if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
        accessRolesByUid[orgId].push(accessRole);
  
        if (orgId === currentOrgId) {
          accessRolesForThisOrgId.push(accessRole.role);
        }
      });
      return accessRolesForThisOrgId;
    }
  
    numberOfOrgsForCurrentUser = () => {
      const { fbuser, accessRolesByUser } = this.props;
      const currentOrgId = this.getCurrentOrgId();
      const accessRoles = steeltoe(accessRolesByUser).get(`${steeltoe(fbuser).get('uid')}.orgs`) || [];
      let accessRolesByUid = {};
      // let accessRolesForThisOrgId = [];
      currentOrgId && accessRoles.length && accessRoles.forEach(accessRole => {
        const { orgId } = accessRole;
        if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
        accessRolesByUid[orgId].push(accessRole);
      });
      return Object.keys(accessRolesByUid).length;
    }
  

  handleInviteButton = e => {
    e.preventDefault();

    this.props.openInviteMemberForm();

  }



  render() {

    const {orgId} = this.props.match.params;

    const orgMembers = steeltoe(this.props).get(`orgMembers.${orgId}.members`) || [];
    const invites = steeltoe(this.props).get(`invites.${orgId}.invites`) || [];
    // console.log("orgMembers",orgMembers)
    // console.log("invites",invites);

    // let uids = [];
    let accessRolesByUid = {};
    orgMembers.forEach(orgMember => {
      const { uid } = orgMember;
      // if(!uids.includes(uid)) uids.push(uid);
      if(!accessRolesByUid[uid]) accessRolesByUid[uid] = [];
      accessRolesByUid[uid].push(orgMember);
    })
    

    const { orgsById } = this.props;
    const orgName = steeltoe(this.props).get(`orgsById.${orgId}.data.name`) || '...';
    // const orgName = '..';

    
    const page = "Members Admin";
    return (
      <div className="MembersAdminPage">
        <Headroom>
          <Header title={`Welcome`} page={page}/>
        </Headroom>


        <div className="MainContent">
            <div className="container">

              <div>&nbsp;</div>
              <div className="navigation">
                {/* <div>All, Invited</div> */}
                <div>&nbsp;</div>
                <div className="buttons"><button onClick={this.handleInviteButton}>+</button></div>
              </div>

              {Object.keys(accessRolesByUid).map( uid => {
                if(!accessRolesByUid[uid] || !accessRolesByUid[uid].length) return null;
                return (
                  <MemberCard key={uid} orgName={orgName} accessRoles={accessRolesByUid[uid]} />
                )
              })}
              

              {invites.map(invite => {

                if (invite.redeemedOn) {
                  return null;
                }
                return (
                  <MemberInviteCard key={invite.id} invite={invite} orgName={orgName} />
                )
                
              })}


              
            </div>
          

        </div>


        <InviteMemberModalForm />

        <div className="MembersAdminPageFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>
    );
  }
}


const MemberInviteCard = connect(
  ({usersByUid})=>({usersByUid}), // mapStateToProps
  dispatch => ({ // mapDispatchToProps
    usersByUidActions: bindActionCreators(usersByUidActions, dispatch)
  })
  )(
  ({invite, usersByUid}) => {
    

    if(invite){
      // console.log('INVITE:', invite, invite.created ? moment(invite.created.toDate()) : 'none' );

      const mInviteSentOn = invite && invite.created && moment(invite.created.toDate());
      const mInviteExpiresOn = invite && invite.expiresOn && moment(invite.expiresOn.toDate());

      const inviteAuthor = steeltoe(usersByUid).get(`${invite.invitedByUid}.data`);

      const authorTitle = inviteAuthor && '(' + inviteAuthor.title + ')';

      let roles = '';
      if (invite.roles.length === 1) {
        roles = invite.roles[0] + ' role';
      } else {
        invite.roles.forEach( (role,i) => {
          if (i < invite.roles.length -1) {
            roles += role + ', '
          } else {
            roles += ' and ' + role;
          }
        });
        roles += ' roles';
      }

      
      return (
        <div className="InfoCard">
          <p>{invite.firstname} {invite.lastname}</p>
          <p>
            {invite.email}
          </p>
          { inviteAuthor && 
            <>
              <div> 
                Invited by {inviteAuthor.firstname} {inviteAuthor.lastname} {authorTitle}
              </div>
              <div> 
                Invited on {mInviteSentOn && mInviteSentOn.format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </div>
              <div> 
                Invite expires on {mInviteExpiresOn && mInviteExpiresOn.format("dddd, MMMM Do YYYY, h:mm:ss a")}.
              </div>
              <div> 
                <p>

                  When invite is redeemed, {invite.firstname} will have {roles}.
                </p>
              </div>
              <button onClick={deleteInvite}>Delete invite</button>
              
            </>
          }
        </div>
      )
    }

    function deleteInvite(){

      var deleteInviteCall = firebase.functions().httpsCallable('deleteInvite');
      deleteInviteCall({inviteId: invite.id}).then((result) => {
        console.log('response from backend:', result.data);
        // Read result of the Cloud Function.
        // var sanitizedMessage = result.data.text;
        // ...
      }).catch(console.warn);
      
      
      // firebase.auth().currentUser.getIdToken().then( token=> {
      //   console.log(`(Deleting invite) Your uid:`, firebase.auth().currentUser.uid);
      //   axios(`https://us-central1-my-race-com-portal.cloudfunctions.net/deleteInvite`, {
      //     params: {
      //       inviteId: invite.id
      //     },
      //     headers: {
      //       authorization: `Bearer ${token}`
      //     }
      //   })
      //   .then((response) => {
      //     const responseData = response.data;
      //     console.log("response",responseData);
      //   })
      //   .catch(err => {
      //     console.warn("error deleting invite",err)
      //   });

      //   // fetch(`/deleteinvite`, {
      //   //   headers: new Headers({ authorization: `Bearer ${token}` }
      //   // )}).then(r=>r.json()).then(console.log).catch(console.warn);
      // } );
    }
    
    
  }
);

const MemberCard = connect(
  ({usersByUid})=>({usersByUid}), // mapStateToProps
  dispatch => ({ // mapDispatchToProps
    usersByUidActions: bindActionCreators(usersByUidActions, dispatch)
  })
  )( 
  ({orgName, usersByUid, usersByUidActions, accessRoles}) => {
    useEffect(()=>{
      const member = accessRoles[0];
      // member.uid - the member we are rendering here
      // usersByUid[member.uid].data - where the data about this member should be
      const uid = member ? member.uid : null;
      if(uid && !steeltoe(usersByUid).get(`${uid}.requestStatus`)){
        // console.log("requesting",uid)
        usersByUidActions.requestUserByUid(uid);
      }
    },[]);

    if(!accessRoles || !accessRoles[0]){
      return null;
    }
    const member = accessRoles[0];

    const uid = member ? member.uid : null;

    const data = steeltoe(usersByUid).get(`${uid}.data`) || {};
    const {firstname, lastname, email, phone, title} = data;
    
    const fullName = firstname + ' ' + lastname;
    // {member.uid}
    return (
      <div className="InfoCard">
        <p>
          {fullName}  • {title}
        </p>
        <p>
          {email}
        </p>
        <p>
          {phone}
        </p>
        <p>
          Member has access to all { orgName } events / races.
        </p>
        <p>
          Roles: {accessRoles.map(role => role.role).join(', ')}
        </p>
      </div>
    )
  }
);


const mapStateToProps = ({raceEvents, raceEventsFilter, requestsPending, fbuserProfile, orgMembers, accessRolesByUser, orgsById, invites, settings, fbuser}) => ({raceEvents, raceEventsFilter, requestsPending, fbuserProfile, orgMembers, accessRolesByUser, orgsById, invites, settings, fbuser});


// const mapState = ({fbuser, fbuserProfile})=>({fbuser, fbuserProfile})

const mapDispatchToProps = dispatch => ({
  openInviteMemberForm: ()=>dispatch({type:'SET_SETTING', isInviteMemberModalOpen: true }),

    orgActions: bindActionCreators(orgActions, dispatch),
    invitesActions: bindActionCreators(invitesActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersAdminPage));





