import React, { useState, useRef } from "react";
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { db } from '../../../../../config/firebase';
import EditModal from './EditModal'
import classNames from 'classnames'
import './ScrollerItems.scss'


// const ARRAY_SIZE = 200;
// const ARRAY_SIZE = 200;
// const RESPONSE_TIME = 1000;

// function loadItems(prevArray = [], startCursor = 0) {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       let newArray = prevArray;

//       for (let i = startCursor; i < startCursor + ARRAY_SIZE; i++) {
//         const newItem = {
//           key: i,
//           value: `This is item ${i}`,
//         };
//         newArray = [...newArray, newItem];
//       }

//       resolve(newArray);
//     }, RESPONSE_TIME);
//   });
// }


const PER_PAGE = 20;

function InfiniteList({ scrollContainer = window, timingDataset, currentTimingDatasetId, fields }) {
  const refContainer = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [items, setItems] = useState([]);
  const [lastItemOrder, setLastItemOrder] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  function updateItemByIdLocally(id, data){
    const index = items.findIndex(it => it.id === id);
    setItems(items => [
      ...items.slice(0, index),
      {...items[index], ...data},
      ...items.slice(index+1),
    ]);
  }

  function handleLoadMore() {

    let query = db.collection('timingSetSteps').doc(currentTimingDatasetId).collection('csvItems').orderBy('csvorder').limit(PER_PAGE);
    if (lastItemOrder) {
      query = query.startAfter(lastItemOrder);
    }

    setLoading(true);
    console.log('LOADING...', `(now ${items.length} items)`)
    query.get()
      .then(snap => {
        setLoading(false);
        console.log('DONE LOADING...', `(now ${items.length} items + ${snap.size})`)
        if (snap.size < PER_PAGE) {
          setHasNextPage(false);
        }
        if (!snap.size) {
          return;
        }

        let last = snap.docs[snap.docs.length - 1];
        setLastItemOrder(last.data().csvorder);
        let docs = [];
        snap.docs.forEach(doc => {
          docs.push(
            { ...doc.data(), id: doc.id }
          )
        });
        setItems([...items, ...docs]);
      })
      .catch(err => {
        setLoading(false);
        console.log('could not get firestore items', err);
      });

    // setLoading(true);
    // console.log('loading more..')
    // loadItems(items, items.length).then(newArray => {
    //   setLoading(false);
    //   setItems(newArray);
    // });
  }

  const edit = (item) => {
    return e => {
      console.log('Open edit for', item);
      setModalOpen(true);
      setSelectedItem(item);
    }
  }

  const infiniteRef = useInfiniteScroll({
    loading,
    // This value is set to "true" for this demo only. You will need to
    // get this value from the API when you request your items.
    hasNextPage,
    onLoadMore: handleLoadMore,
    scrollContainer: scrollContainer,
  });

  return (
    <div className="ScrollerItems" ref={refContainer}>
      <table className="ScrollerItemsContainer" ref={infiniteRef}>
        <thead>
          <tr>
            {fields.map(field => (
              <th key={field.name}>{field.title}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.csvorder}>
              {fields.map(field => {

                const originalValue = item[timingDataset[`map_field_${field.name}`]];
                const editedValue = item[`_edited_${field.name}`];
                
                const invalid = !!item[`_invalid_field_${field.name}`];

                // if(originalValue==='Kenya'){
                //   console.log('field for kenya', field, invalid);
                // }

                if( typeof editedValue === 'string' ){
                  // WHEN THE VALUE WAS EDITED BY USER
                  return (
                    <td key={field.name} className="edited">{editedValue}</td>
                  )
                }
                
                // DEFAULT VALUE
                return(
                  <td key={field.name} className={classNames("original", { invalid })}>{originalValue}</td>
                )
              })}
              <td><button onClick={edit(item)}>edit</button></td>
            </tr>
          ))}
          {loading && <tr><td colSpan={99}>Loading...</td></tr>}
        </tbody>
      </table>
      <EditModal
        {...{ fields, timingDataset, currentTimingDatasetId, updateItemByIdLocally }}
        data={selectedItem}
        isOpen={isModalOpen}
        closeModal={e => setModalOpen(false)}
        openModal={e => setModalOpen(true)} />
    </div>
  );
}


export default InfiniteList