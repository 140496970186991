import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});

export const SET_STATUS = "INVITES/SET_STATUS";
export const SET_INVITES = "INVITES/SET_INVITES";


export default (state = initialState.invites, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.orgId
        // expected: action.requestStatus

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_INVITES: {
        // expected: action.orgId
        // expected: action.invites

        return update(state, {
            [action.orgId]: { $auto: {
                requestStatus: { $set: 'received' },
                invites: { $set: action.invites },
            }}
        });
    }

        
    default:
      return state;
  }
};
