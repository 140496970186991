import React, { Component } from "react";
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import './LoginPopup.scss'


import imgLogin from '../../images/login-1.svg';
import imgLoginHov from '../../images/login-2.svg';
import classNames from 'classnames';
import DropdownMenu from './DropdownMenu'
import LoginSignupContent from "./LoginSignupContent";
import {RemoveScroll} from 'react-remove-scroll';
// import steeltoe from 'steeltoe'

// import avatars, { getOwnAvatarImage} from './../../pages/AccountProfile/avatars'


class LoginPopup extends Component {
    constructor(props){
        super(props);
        this.iconEl = React.createRef();

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        
    }
    // state = {
    //     // isOpen: false,
    //     isDropdownOpen: false,
    //     // tab:'login'
    // }

    // componentDidMount(){
    //     this.coverEl = window.document.createElement('div');
    //     window.document.body.appendChild(this.coverEl);
    // }
    // componentWillUnmount(){
    //     window.document.body.removeChild(this.coverEl);
    // }

    componentDidUpdate(prevProps){
        if(!prevProps.fbuser && this.props.fbuser){
            this.close();
        }
    }

    // toggleDropdown = () => {
    //     this.props.toggleMenuDropdown();
    //     // this.setState( s => ({isDropdownOpen: !s.isDropdownOpen}))
    // }

    // closeDropdown = () => this.props.closeMenuDropdown(); // this.setState({ isDropdownOpen: false })

    renderModal = () => {
        const {setLoginPopupOpen, settings:{isLoginPopupOpen}} = this.props;
        // if(!this.coverEl) return null;
        return (
            ReactDOM.createPortal(
                <RemoveScroll>

                    <div className="LoginCover" onClick={this.close}>{ 
                        isLoginPopupOpen && <LoginSignupContent tab={isLoginPopupOpen} setTab={setLoginPopupOpen} close={this.close} /> 
                    }</div>

                </RemoveScroll>
            , this.coverEl)
        )
    }
    
    //setTab = index => this.setState({ tab: index })

    close = () => {
        // event.preventDefault();
        this.props.setLoginPopupOpen(false)

    }

    openLogin = () => {
        // if( this.props.disabled ) { return; }
        // ReactTooltip.hide();
        // this.props.openModal();
        //this.setTab(0);
        this.props.setLoginPopupOpen('login');
        // this.setState({tab:'login'})
    }

    openSignup = () => {
        //this.setTab(1);
        this.props.setLoginPopupOpen('signup')
        // this.setState({isOpen: true, tab:'signup'})
    }


    renderDefaultAvatar = () => {
        const {fbuser} = this.props;
        if(fbuser.photoURL) { // google avatar photo
            return (
                <img src={ fbuser.photoURL } alt="user photo" />
            )
        }

        const initials = fbuser && fbuser.email.substring(0, 2);
        return initials;
    }

        
    render() {
        const {fbuser, fbuserProfile, setLoginPopupOpen, settings, users, openMenuDropdown, closeMenuDropdown} = this.props;
        // const {
        //     // isOpen, 
        //     isDropdownOpen} = this.state;

        // let isLoginPopupOpen;
        // let isMenuDropdownOpen;
        // if (settings) {
        //     isLoginPopupOpen = settings.isLoginPopupOpen;
        //     isMenuDropdownOpen = settings.isMenuDropdownOpen;
        // }
        const {isLoginPopupOpen, isMenuDropdownOpen} = settings;

        

        
        
        // const avatarName = steeltoe(fbuserProfile).get('defaultAvatar'); // eg. "imgM7"
        // const isDefaultImageSelected = avatarName==='imgM7'||avatarName==='imgF7';
        
        // const EmailHash = fbuserProfile && Object.keys(users).find(ehash => users[ehash] && users[ehash].username === fbuserProfile.username );
        // const uploadedImage = steeltoe(this.props).get(`users.${EmailHash}.avatarImageUrl`);
        
        const avatarImage = null;
        const uploadedImage = null;
        const isDefaultImageSelected = false;
        
        return (
        <div className="LoginPopupContainer">

            { fbuser ? (
            <div
                ref={this.iconEl}
                className={classNames("LoggedInUserInitials",{ 
                    withAvatarImage: !!avatarImage, 
                    uploadedImage: uploadedImage&&isDefaultImageSelected })}
                onClick={openMenuDropdown}
                >
                { avatarImage ? 
                    <img src={avatarImage} alt="user" />
                    :
                    this.renderDefaultAvatar()
                    }
            </div>
            ) : (
            <div
                ref={this.iconEl}
                className={classNames("IconSwappable LoginIcon",{isDropdownOpen: isMenuDropdownOpen} )}
                // className="IconSwappable LoginIcon"
                onClick={openMenuDropdown}>
                <div className="icon" style={{ backgroundImage: `url(${imgLogin})`}}/>
                <div className="hover" style={{ backgroundImage: `url(${imgLoginHov})`}}/>
            </div>
            // <div className="IconSwappable LoginIcon" onClick={this.open}>
            //     <div className="icon" style={{ backgroundImage: `url(${imgLogin})`}}/>
            //     <div className="hover" style={{ backgroundImage: `url(${imgLoginHov})`}}/>
            // </div>
            )}

            <DropdownMenu fbuser={fbuser} fbuserProfile={fbuserProfile} show={isMenuDropdownOpen} 
                anchorEl={this.iconEl}
                openSignupRequest={this.openSignup}
                openLoginRequest={this.openLogin}
                  onCloseRequest={closeMenuDropdown}/>

            {/* { isLoginPopupOpen && this.renderModalContent() } */}
            
            { isLoginPopupOpen && this.renderModal() }


      </div>
    );
  }
}

const mapState = ({fbuser, settings, fbuserProfile, users})=>({fbuser, settings, fbuserProfile, users})

// const mapState = state => ({
//     // race: state.raceSelected,
//     // user: state.user,
//     fbuser: state.fbuser,
//     settings: state.settings,
//     fbuserProfile: state.fbuserProfile
//   })

const mapDispatch = dispatch => ({
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),

    openMenuDropdown: ()=>dispatch({type:'SET_SETTING', isMenuDropdownOpen: true, isPageInfoModalOpen: false }),
    closeMenuDropdown: ()=>dispatch({type:'SET_SETTING', isMenuDropdownOpen: false}),
    toggleMenuDropdown: ()=>dispatch({type:'TOGGLE_SETTING', isMenuDropdownOpen: true}),
    
})

export default connect(mapState, mapDispatch)(LoginPopup);
