import {db} from '../config/firebase'
import { SET_STATUS, SET_DATA } from '../reducers/usersByUidReducer'

export const requestUserByUid = (uid) => {
    return (dispatch, getState) => {
        // console.log('[requestUserByUid]', uid)

        if(!uid){
            return;
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});

        dispatch({ type: SET_STATUS, uid, requestStatus: 'pending' });

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error writing User document: ", e);
            dispatch({ type: SET_STATUS, uid, requestStatus: 'error' });
        };

        const handleSuccess = snap => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // console.log("Got User by id data!", uid, snap);

            const data = {...snap.data(), id: snap.id};
            // console.log("got data for ",snap.id,data)
            
            dispatch({ type: SET_DATA, uid, data  });

            // TODO: rewrite this to properly name accessRoles in redux
            // TODO: request user data for these accessRoles, before displaying them on the page

            // TODO: get data from invites too
        }

        // console.log("about to get user data for ",uid)
        
        db
            .collection("users")
            .doc(uid)
            .get()
            .then(handleSuccess)
            .catch(handleError);
        
    }
}