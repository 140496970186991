import React, { useState } from 'react';
// import firebase from 'firebase/app'
import {auth} from '../../config/firebase'
// import LoginSocial from './LoginSocial'
// import imgEmail from '../../images/mail-1.svg';
// import imgEmailHover from '../../images/mail-2.svg';
// import imgLock from '../../images/lock-1.svg';
// import imgLockHover from '../../images/lock-2.svg';
// import imgArrow from '../../images/next-3.svg';
// import classNames from 'classnames'
import EmailPassFields from './EmailPassFields'
import {connect} from 'react-redux'

const Login = ({close, setLoginPopupOpen, settings, setSettings}) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    const [formErrorMessage, setFormErrorMessage] = useState(null)
    const [formSubmitted, setFormSubmitted] = useState(false)


    const onSubmit = e => {
        e.preventDefault();
        setPasswordValid(!!password);
        setEmailValid(!!email);
        setFormSubmitted(true);

        if(!password || !email){return}

        auth.signInWithEmailAndPassword(email, password).then(()=>{
            //props.history.push('/')

            // SHOW THE SWITCHER - IGNORE THE userProfile.currentOrgId
            setSettings({ justLoggedIn: true });

        }).catch((error) => {
            console.warn('Error creating user:', error);
            setFormErrorMessage(error.message);
        });
    }

    return (
        <div>
            <h1>Log In</h1>
            <div className="Label"><i></i><span>Partner Account</span><i></i></div>

            <p className="tagline">Your definitive source for race<br/>performance analysis!</p>
            
            
            {/* <LoginSocial login /> */}
            <form onSubmit={onSubmit}>

                <EmailPassFields {...{email, setEmail, password, setPassword, formSubmitted,
                    passwordValid, setPasswordValid, emailValid, setEmailValid, formErrorMessage, setFormErrorMessage}} />

                {/* <div className="rememberpass">Don't remember your password?</div> */}
                
                { !!formErrorMessage && <p className="FormErrorMessage">{formErrorMessage}</p>}

                <div className="ForgotPasswordContainer"><span className="ForgotPasswordLink" onClick={()=>setLoginPopupOpen('forgotpassword')}>Forgot password?</span></div>

                <div className="buttons">
                    {/* <input type="submit" value="Sign In" style={{ backgroundImage: `url(${imgArrow})` }}/> */}
                    <button type="button" className="secondary" onClick={close}>Cancel</button>
                    <button type="submit">Log In</button>
                </div>

            </form>
        </div>

    )

}

const mapStateToProps = ({settings})=>({settings});
const mapDispatchToProps = (dispatch) => ({
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
    setSettings: settings => dispatch({ type: 'SET_SETTING', ...settings }),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);