import React, { Component, useState } from "react";
import steeltoe from 'steeltoe'
import * as Sentry from '@sentry/browser';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import Dropdown from '../../components/Dropdown/Dropdown'
import TooltipText from '../../components/Tooltip/TooltipText'
import moment from "moment";
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
// import landscape from "/images/city-generic/generic-02.svg";
import "./EventsPage.scss";
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'
import {formatNumber} from '../../utils/timeUtils'

// import * as allRacesActions from '../../actions/allRaces'
import { getCurrentOrgRaces, getSeriesRaces } from '../../re-selectors/getSelectedRace'


import openBlue from '../../images/open-01.svg';
import openGray from '../../images/open-02.svg';

import Headroom from 'react-headroom';


function EventCard(props) {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const dateOptions = props.events.map( event => {
    
    
    const startDate = moment(event.EventDateStart);
    const endDate = moment(event.EventDateEnd);

    const duration = endDate.diff(startDate, 'days');
    // console.log('DURATION:', duration )
    

    let eventDate = duration >= 1 ? 
      startDate.format("MMM D") + `–` + endDate.format("D • YYYY") : 
      endDate.format('MMM D • YYYY');

      return {
        id: event.EventCode,
        title: `${eventDate}`
      }

  });
  // console.log('dateOptions:', dateOptions);
  // const dateOptions = props.events.map( event => ({
    

  //   id: event.EventCode,
  //   title: `${event.RaceDateStart}`
  // }));

  

  const selectedEventCode =  selectedEvent || props.events[0].EventCode;

  
  
  // const raceEvent = props.events[0];
  const raceEvent = props.events.find(event => event.EventCode === selectedEventCode)

  // console.log("props.events",props.events);

  const startDate = moment(raceEvent.EventDateStart);
  const endDate = moment(raceEvent.EventDateEnd);

  const duration = endDate.diff(startDate, 'days');
  // const logoSrc= `/images/${raceEvent.EventSeriesCode}/partners/${raceEvent.sponsorImages[0].src}`;

  const raceImageSrc = raceEvent && raceEvent.sponsorImages && raceEvent.sponsorImages[0] && raceEvent.sponsorImages[0].src;
  // const raceImageSrc = steeltoe(raceEvent).get(`sponsorImages[0].src`);

  // console.log("raceEvent=",raceEvent);
  // console.log("RaceCode=",raceEvent.RaceCode);
  // console.log("RaceCode raceImageSrc=",raceImageSrc);

  // const logoSrc=  raceImageSrc.includes('https') ? raceImageSrc : `/images/${race.RaceDetails.EventSeriesCode}/partners/${race.RaceDetails.sponsorImages[0].src}`;
  const logoSrc=  raceImageSrc && raceImageSrc.includes('https') ? raceImageSrc : null;

  const countryFlag =  raceEvent.CountryTwoCharCode && (raceEvent.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)));
       

  let totalAthleteCountPerEvent = 0;
  for(var i = 0; i < raceEvent.races.length; i++) {
    // console.log("raceEvent.races[i]",raceEvent.races[i])
    totalAthleteCountPerEvent += raceEvent.races[i].Participants ? raceEvent.races[i].Participants : 0;
  } 

  totalAthleteCountPerEvent = formatNumber(totalAthleteCountPerEvent);
  

//   let eventDate = duration >= 1 ? 
//     startDate.format("MMM D") + `–` + endDate.format("D • YYYY") : 
//     endDate.format('MMM D • YYYY');

    const goToRace = (id) => {
      console.log('[goToRace]', id);      
      const event = props.events.find(event => event.EventCode === id);
      const uri = `/select-athlete/${event.EventCode}/${event.DefaultRaceCode}/All-Genders`
      console.log('EVENT', event, uri);
      props.history.push(uri);

    };

    return (
      <div className="EventCard" onClick={()=>{

        const event = props.events.find(event => event.EventCode === selectedEventCode)

        
        
        const uri = `/select-athlete/${event.EventCode}/${event.DefaultRaceCode}/All-Genders`

        
        
        // console.log('GO TO SELECTED DATE EVENT', selectedEventCode, '->', uri);

        props.history.push(uri);
        
      } }>

        <div className="OpenRaceIcon tooltip">
          <TooltipText><em>More details</em></TooltipText>
          <div className="IconSwappable">
            <div className="icon" style={{ backgroundImage: `url(${openGray})` }} />
            <div className="hover" style={{ backgroundImage: `url(${openBlue})` }} />
          </div>
        </div>

        
        {
          logoSrc && <div className="EventImage">
              <img alt="event logo" src={logoSrc} />
          </div>
        }
        <div className="EventTitle">{raceEvent.EventName}</div>
        <div className="EventInfo">
            <div><span style={{fontWeight:"400", fontSize:"11px"}}>Races</span><i className="dot" style={{fontWeight:"800", fontSize:"11px"}}>•</i> <span className="raceCount">{raceEvent.TotalRaces}</span></div>
            <div><span className="totalAthleteCount">{totalAthleteCountPerEvent}</span></div>
            <div><span className="flag">{countryFlag}</span> <span className="location">{raceEvent.Location}</span></div>
            <div>
              {
                dateOptions.length > 1 ?
                <Dropdown 
                  value={selectedEventCode}
                  onChange={setSelectedEvent}
                  goTo={goToRace}
                  options={dateOptions}/>
                :
                <span className="EventInfoDate">{dateOptions[0].title}</span>
              }
            </div>
        </div>
      </div>
    )
    
}

EventCard = withRouter(EventCard);


const eventTypes = [
  {
    id: 'all',
    title: 'All'
  }, {
    id: 'running',
    title: 'Running'
  }, {
    id: 'multi-sport',
    title: 'Multi-sport'
  }, {
    id: 'ultra',
    title: 'Ultra'
  },
]


class EventsPage extends Component {

  state = {
    selectedEventType: 'all'
  }
  
  componentDidMount() {
    

    // this.props.allRacesActions.getAllRaces();
    

  }


  setEventType = selectedEventType => e => this.setState({ selectedEventType })


  render() {


    const {
      requestsPending
    } = this.props;



    if (requestsPending) {
      return null;
    }

    const { selectedEventType } = this.state;
    const { raceEvents, raceEventsFilter } = this.props;



    const page ="Events Home";

    if(!raceEvents) return null;

    const eventCodes = Object.keys(raceEvents);

    let eventSeries = {
      // [EventSeriesCode]: [ ... raceEvents ]
    };
    let eventSeriesCodes = []

    eventCodes.map( (eventCode, i) => {
      const event = raceEvents[eventCode];
      const { EventSeriesCode } = event;

      if(typeof eventSeries[EventSeriesCode] === 'undefined'){
        eventSeries[EventSeriesCode] = [];
        eventSeriesCodes.push(EventSeriesCode);
      }

      eventSeries[EventSeriesCode].push(event);
    });

    

    return (
      <div className="HomePage">
        <Headroom>
          <Header page={page}/>
        </Headroom>

        {/* <Header page={page}/> */}

        <div className="MainContent">
            <div className="container">

              {/* <div className="EventTypeOptions">
              { eventTypes.map( (eventType, i) => {
                return (
                  <>
                  <span
                    className={classNames("EventType", {
                      selected: selectedEventType===eventType.id
                    })}
                    onClick={this.setEventType(eventType.id)}
                  >{eventType.title}</span>
                  { eventTypes.length !== i+1 && (
                    <span className="dot">•</span>
                  )}
                  </>
                )
              })}
              </div> */}

              

                <div className="EventCards">
                    {
                      // eventCards.map( eventCard => (
                      //     <EventCard key={eventCard.title} {...eventCard} />
                      // ))

                      eventSeriesCodes.map( (EventSeriesCode, i) => {

                        // console.log("eventname",event)
                        // return <EventRow key={eventCode} index={i} event={event} setSelectedRace={this.props.setSelectedRace} />
                        return <EventCard 
                          key={eventSeries[EventSeriesCode][0].RaceCode} 
                          events={eventSeries[EventSeriesCode]} 
                          />
                    })
                    }
                </div>
                
            </div>
          
        </div>

        <div className="HomeEventsFooterBackground" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   // race: state.raceSelected,
//   requestsPending: state.requestsPending,
// //   sponsors: state.sponsors,
//   // races: state.races,
// })


// const mapStateToProps = ({raceEvents, raceEventsFilter, requestsPending}) => ({raceEvents, raceEventsFilter, requestsPending})

const mapStateToProps = state => {
    return {
        // raceEvents: getCurrentOrgRaces(state), //cim-2019 (has multiple races), cim-2018, etc, see MyRace web raceEvents log
        raceEvents: getSeriesRaces(state), //cim-2019 (has multiple races), cim-2018, etc, see MyRace web raceEvents log
        raceEventsFilter: state.raceEventsFilter,
        requestsPending: state.requestsPending,
    }
  };

// const mapState = ({fbuser, fbuserProfile})=>({fbuser, fbuserProfile})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
//   setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
//   incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
//   decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
//   sponsorsActions: bindActionCreators(sponsorsActions, dispatch),

    // allRacesActions: bindActionCreators(allRacesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
// export default connect(mapState, mapDispatchToProps)(SelectEvent);




