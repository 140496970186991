import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';



const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  // console.log("payload",payload)
  const labelToUse = payload.LabelToUse ? payload.LabelToUse : `Missed ${parseFloat(payload.CheckpointDistanceMi.toFixed(2))} at mi`;

  return (
    
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} ${labelToUse}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


export default class CheckpointAnalyticsMissing extends PureComponent {


  state = {
    activeIndex: 0,
  };

//   const checkpointAnalytics = this.props.checkpointAnalytics;
  onPieEnter = (data, index) => {
    // console.log("data",data)
    this.setState({
      activeIndex: index,
    });
  };


  render() {
      const checkpointAnalytics = this.props.checkpointAnalytics;
      const chartTitle = this.props.chartTitle;
    return (

        
    checkpointAnalytics && checkpointAnalytics.Checkpoints && checkpointAnalytics.Checkpoints.length>0 ? 
    <div className="content"> 
        <h3>{chartTitle}</h3>
          <PieChart width={600} height={260}>
              <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={checkpointAnalytics.Checkpoints}
              cx='50%'
              cy='50%'
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="TotalAthletesMissed"
              onMouseEnter={this.onPieEnter}
              />
          </PieChart>
      </div> : null
    );
  }
}
