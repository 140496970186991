import { createSelector } from 'reselect'

const getCurrentTimingDatasetId = state => state.settings.currentTimingDatasetId
const getTimingDatasets = state => state.timingDatasets

export const getCurrentTimingDataset = createSelector(
  [getCurrentTimingDatasetId, getTimingDatasets],
  (currentTimingDatasetId, timingDatasets) => {
      return timingDatasets[currentTimingDatasetId]&& timingDatasets[currentTimingDatasetId].data;
  }
)