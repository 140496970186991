import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrgId } from "../../re-selectors/getCurrentOrgId";
import { requestOrgById } from "../../actions/orgsById";

const OrgsByIdGetter = () => {
    const dispatch = useDispatch();
    // currentOrgId: getCurrentOrgId(state)
    const currentOrgId = useSelector(getCurrentOrgId);
    useEffect(()=>{
        // console.log('OrgsByIdGetter requestOrgById');
        dispatch(requestOrgById(currentOrgId));
    },[currentOrgId]);
    return null;
};

export default OrgsByIdGetter;
