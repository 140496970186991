import React, { useState } from 'react';
import imgEmailBlue from '../../images/mail-01.svg';
import imgEmailRed from '../../images/mail-02.svg';
import imgEmailBlack from '../../images/mail-03.svg';
// import firebase from 'firebase/app'
import {auth} from '../../config/firebase'
import imgArrow from '../../images/next-3.svg';
// import classNames from 'classnames'
// import EmailPassFields from './EmailPassFields'
import {connect} from 'react-redux'
import { useForm } from "react-hook-form";
import classNames from 'classnames'
import axios from 'axios';

const ForgotPassword = ({close, setLoginPopupOpen, settings, setEmail}) => {
    const { register, errors, triggerValidation, handleSubmit } = useForm();

    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    // const [passwordValid, setPasswordValid] = useState(true);
    // const [emailValid, setEmailValid] = useState(true);

    const [formErrorMessage, setFormErrorMessage] = useState(null)
    const [formSubmitted, setFormSubmitted] = useState(false)


    const onSubmit = data => {
        console.log('FORM SUBMIT', data);
        const {email} = data;

        setEmail(email);
        
        // https://us-central1-my-race-com.cloudfunctions.net/resetPasswordRequest

        // axios.get('https://us-central1-my-race-com.cloudfunctions.net/resetPasswordRequest', { params: { email } })
        axios.get('https://us-central1-my-race-com-portal.cloudfunctions.net/resetPasswordRequest', { params: { email } })
            .then(resp => {
                console.log('fetch success:', resp.body);
                setLoginPopupOpen('emailsent');
            })
            .catch(err => {
                console.log('fetch error:', err)
            })
        
        // e.preventDefault();
        // setPasswordValid(!!password);
        // setEmailValid(!!email);
        // setFormSubmitted(true);

        // if(!password || !email){return}

        // auth.signInWithEmailAndPassword(email, password).then(()=>{
        //     //props.history.push('/')
        // }).catch((error) => {
        //     console.warn('Error creating user:', error);
        //     setFormErrorMessage(error.message);
        // });
    }

    return (
        <div>
            <h1>Forgot Password?</h1>
            <div className="Label"><i></i><span>Password Reset</span><i></i></div>

            <p className="tagline">An email will be sent to your email address<br/>to reset your password.</p>
            
            
            {/* <LoginSocial login /> */}
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className={classNames("control","email", {
                     valid: true,
                     invalid: false,
                    })}>
                    <div className="ControlIcon">
                        <div className="IconSwappableValid">
                            <div className="icon" style={{ backgroundImage: `url(${imgEmailBlue})`}}/>
                            <div className="invalid" style={{ backgroundImage: `url(${imgEmailRed})`}}/>
                            <div className="valid" style={{ backgroundImage: `url(${imgEmailBlack})`}}/>
                        </div>
                    </div>

                    <input name="email" type="email" 
                        ref={register({
                            validate: value => (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                        })}
                        autoComplete="off"
                        placeholder="Type in your email address..."
                        // onChange={e => {setEmail(e.target.value);
                        //     setFormErrorMessage('');
                        // }} 
                        // onFocus={e => setEmailValid(true)}
                        // onBlur={e => {
                        //     // setEmailValid(!!e.target.value && (/.+@.+\..+/).test(e.target.value));
                        //     setEmailValid();
                        // }}
                        />
                </div>


                {/* <div className="rememberpass">Don't remember your password?</div> */}
                
                {/* { !!formErrorMessage && <p className="FormErrorMessage">{formErrorMessage}</p>} */}


                <div className="buttons">
                    {/* <input type="submit" value="Sign In" style={{ backgroundImage: `url(${imgArrow})` }}/> */}
                    <button type="button" className="secondary" onClick={close}>Cancel</button>
                    <button type="submit">Reset</button>
                </div>

            </form>
        </div>

    )

}

const mapStateToProps = ({settings})=>({settings});
const mapDispatchToProps = (dispatch) => ({
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);