import firebase, {auth} from '../config/firebase'
import { SET_STATUS, SET_DATA } from '../reducers/racesAnalyticsByOrgIdReducer'


export const requestRacesAnalyticsByOrgId = (orgId, raceCodes) => {
    return (dispatch, getState) => {
        // console.log('[requestRacesAnalyticsByOrgId]', orgId)

        return new Promise((resolve,reject)=>{

            if(!orgId || (!auth.currentUser && !auth.currentUser.isAnonymous)){
                reject();
                return;
            }

            dispatch({type:'INCREMENT_PENDING_REQUESTS'});

            dispatch({ type: SET_STATUS, orgId, requestStatus: 'pending' });

            const funcRequestRacesAnalyticsByOrgId = firebase.functions().httpsCallable('requestRacesAnalyticsByOrgId');
            // console.log("orgId",orgId);
            // console.log("raceCodes",raceCodes);

            funcRequestRacesAnalyticsByOrgId({ orgId, raceCodes }).then(result=>{
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                
                // was getting an error, SET_DATA of undefined
                // console.log('funcRequestRacesAnalyticsByOrgId result:', orgId, result.data.data);
                const races = result.data.data ? result.data.data : [];
                // const races = result.data.data;
                
                dispatch({ type: SET_DATA, orgId, data: races  });

                // dispatch(getImagesForRaces({ races }));

                resolve(races);
                
            }).catch(e => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                console.warn('error making request in funcRequestRacesAnalyticsByOrgId',e);
                reject(e);
            });

        });

        
    }
}