import React, { useState, useContext } from 'react'
// import { connect } from 'react-redux'
import steeltoe from 'steeltoe'
import { useInterval } from '../Utils/useInterval'
import { BackendPingContext } from '../Utils/ListenerServerPingAlive'




function ServerPingTimer(){

    const backendPing = useContext(BackendPingContext);
    
    let [now, setNow] = useState(Date.now());
    useInterval(()=>{
        setNow(Date.now());
    },1000);

    // const numTasks = steeltoe(settings).get('BackendPing.tasksInQueue');
    const numTasks = steeltoe(backendPing).get('tasksInQueue');
    
    // const numTasksToShow = numTasks === 0 ? 'no' : (numTasks >= 10 ? '10 or more' : numTasks);
    const taskStatus = numTasks === 0 ? 'waiting for tasks' : 'working through ' + (numTasks >= 10 ? '10 or more' : numTasks) + ' task(s)';

    // const numSecondsSincePing = Math.round((now - steeltoe(settings).get('BackendPing.backendLastSeen')) / 1000);
    const numSecondsSincePing = Math.round((now - steeltoe(backendPing).get('backendLastSeen')) / 1000);
    let taskHandlerStatus = 'offline';
    if (numSecondsSincePing < 30) {
        taskHandlerStatus = 'online (' + taskStatus + ')';
    }

    return (
        // <span>Last Ping: {numSecondsSincePing} seconds ago ({numTasksToShow} tasks)</span>
        // <span>Task Handler: {taskHandlerStatus} ({numTasksToShow} tasks)</span>
        <span>Task Handler: {taskHandlerStatus}</span>
    )
}


// export default connect( ({settings}) => ({settings}) )(ServerPingTimer);
export default ServerPingTimer;