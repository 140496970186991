import React, { Component } from "react";
import "./RaceStatusFilters.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
// import TooltipText from '../Tooltip/TooltipText'

class RaceStatusFilters extends Component {


  componentDidMount(){

  }

  selectFilterOption = filterOption => e => {
    // if(this.props.requestsPending){ return; }
    const { raceListFilterStatusOption } = this.props.settings;

    const clone = raceListFilterStatusOption.slice(0);
    const index = clone.indexOf(filterOption);

    if (index > -1) {
        clone.splice(index, 1);
    } else {
        clone.push(filterOption);
    }
    this.props.setRaceFilterStatusOption(clone)
  }

  render() {
    const { raceListFilterStatusOption } = this.props.settings;

    const raceOptionFilters = ['Draft','Private','Public-Unofficial','Public'];
    
    return (
      <div className="RaceStatusFilters">
        
        <div className="line"/>

        { raceOptionFilters.map( filterOption => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={filterOption} onClick={this.selectFilterOption(filterOption)} 
            className={classnames("button", { selected: raceListFilterStatusOption.includes(filterOption)})}>

            {filterOption} 
          </div>
        ))}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = ({ settings, requestsPending }) => ({ settings,requestsPending })

const mapDispatchToProps = dispatch => ({
//   setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE_FILTER_STATUS', race}),
  setRaceFilterStatusOption: raceListFilterStatusOption => dispatch({type:'SET_SETTING', raceListFilterStatusOption}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceStatusFilters));

