import { SET_DATA, SET_PROPERTIES } from '../reducers/timingDatasetsReducer'
import {db} from '../config/firebase'

import { incrementPendingRequests, decrementPendingRequests } from './requestsPending'

const timingDatasetStub = {
    // steps: [
    //     // available - a step a user can navigate to
    //     // completed - a step user submitted
    //     { name: 'Step 1', available: true, completed: false, current: true  },
    //     { name: 'Step 2', available: false, completed: false, current: false  },
    //     { name: 'Step 3', available: false, completed: false, current: false  },
    //     { name: 'Step 4', available: false, completed: false, current: false  },
    //     { name: 'Step 5', available: false, completed: false, current: false  },
    //     { name: 'Step 6', available: false, completed: false, current: false  },
    //     { name: 'Step 7', available: false, completed: false, current: false  },
    //     { name: 'Step 8', available: false, completed: false, current: false  },
    // ],

    step_1_name: 'Step 1',
    step_1_available: true,
    step_1_completed: false,
    step_1_current: true,
    step_2_name: 'Step 2',
    step_2_available: false,
    step_2_completed: false,
    step_2_current: false,
    step_3_name: 'Step 3',
    step_3_available: false,
    step_3_completed: false,
    step_3_current: false,
    step_4_name: 'Step 4',
    step_4_available: false,
    step_4_completed: false,
    step_4_current: false,
    step_5_name: 'Step 5',
    step_5_available: false,
    step_5_completed: false,
    step_5_current: false,
    step_6_name: 'Step 6',
    step_6_available: false,
    step_6_completed: false,
    step_6_current: false,
    step_7_name: 'Step 7',
    step_7_available: false,
    step_7_completed: false,
    step_7_current: false,
    step_8_name: 'Step 8',
    step_8_available: false,
    step_8_completed: false,
    step_8_current: false,
    step_9_name: 'Step 9',
    step_9_available: false,
    step_9_completed: false,
    step_9_current: false,
    step_10_name: 'Step 10',
    step_10_available: false,
    step_10_completed: false,
    step_10_current: false,
    step_11_name: 'Step 11',
    step_11_available: false,
    step_11_completed: false,
    step_11_current: false,
    step_12_name: 'Step 12',
    step_12_available: false,
    step_12_completed: false,
    step_12_current: false,
    

};




class TimingDatasetUpdater {
    schedules = []
    savePending = false;
    saveTimeout = null;

    scheduleUpdate = (timingDatasetId, properties) => {
        const schedule = {
            id: Date.now()+'_'+Math.random(),
            timingDatasetId, 
            properties
        };
        this.schedules.push(schedule);
        console.log('[TimingDatasetUpdater] scheduleUpdate ', schedule, this.schedules.length);
        // this.save();
        this.postponeTimeout();
    }
    
    save = async () => {
        console.log('[TimingDatasetUpdater] SAVE ', this.schedules.length, this.schedules);
        let timingDatasetId;
        if(!this.schedules.length || this.savePending) { 
            console.log('[TimingDatasetUpdater] SAVE CANCELLED', this.schedules.length , this.savePending);
            
            return; 
        }
        
        this.savePending = true;
        
        timingDatasetId = this.schedules[0].timingDatasetId;
        
        const schedules = this.schedules.filter(schedule => schedule.timingDatasetId === timingDatasetId);
        
        let updates = {};
        let scheduleIds = []
        schedules.forEach(schedule => {
            updates = {...updates, ...schedule.properties};
            scheduleIds.push(schedule.id);
        });
        
        console.log('[TimingDatasetUpdater] SAVING ', timingDatasetId, updates);
        try {
            await db.collection('timingSetSteps').doc(timingDatasetId).update(updates);
            this.schedules = this.schedules.filter(s => !scheduleIds.includes(s.id) );
        } catch (err){
            console.log('error saving updates to firestore', err);
        }
        console.log('[TimingDatasetUpdater] DONE SAVING and scheduling another one')
        this.savePending = false;
        this.postponeTimeout();
    }
    
    postponeTimeout = () => {
        if(this.saveTimeout) {
            window.clearTimeout(this.saveTimeout);
        }
        this.saveTimeout = window.setTimeout(this.save, 300);
    }
}

const timingDatasetUpdater = new TimingDatasetUpdater();



export const updateTimingDatasetRedux = (properties, timingDatasetId)=>({
    type: SET_PROPERTIES,
    id: timingDatasetId,
    payload: properties
})


export const updateTimingDataset = (properties, timingDatasetId)=>{
    return (dispatch, getState) => {

        // also save it to firestore, and in a throttled way
        timingDatasetUpdater.scheduleUpdate(timingDatasetId, properties);
        
        dispatch(updateTimingDatasetRedux(properties, timingDatasetId))
    }
}

export const setSteps = (steps, timingDatasetId) => {
    return dispatch => {
        dispatch(updateTimingDataset({steps}, timingDatasetId))
    }
}

export const createStepsDoc = (RaceCode, dataSetVersion=1) => {
    return (dispatch) => {
        console.log('[createStepsDoc]', RaceCode, dataSetVersion);
        
        const timingDatasetId = `${RaceCode}-${dataSetVersion}`;
        
        const data = {
            ...timingDatasetStub,
            RaceCode,
            dataSetVersion
        };

        dispatch(incrementPendingRequests());        
        db.collection('timingSetSteps').doc(timingDatasetId).set(data)
            .then(snap => {
                dispatch(decrementPendingRequests());
                dispatch({ type:'SET_SETTING', currentTimingDatasetId: timingDatasetId });
                dispatch({ type: SET_DATA, id:timingDatasetId , data });
            })
            .catch(err=>{
                dispatch(decrementPendingRequests());
                console.log('ERROR creating timingSetSteps doc', err)
            })
    }
}

export const getLatestTimingDataset = (RaceCode) => {
    return (dispatch) => {
        dispatch(incrementPendingRequests());      
        db.collection('timingSetSteps').where('RaceCode','==', RaceCode).orderBy('dataSetVersion', 'desc').limit(1).get()
            .then(snap => {
                dispatch(decrementPendingRequests());
                console.log('timing sets size', snap.size);
                if(!snap.size){
                    // we don't have the doc in firestore yet - create it
                    dispatch(createStepsDoc(RaceCode, 1));
                    return;
                }
                // WE GOT IT!
                snap.forEach(doc => {
                    // console.log('timingSetSteps:', doc.id, doc.data());
                    const currentTimingDatasetId = doc.id;
                    const data = doc.data();
                    dispatch({ type:'SET_SETTING', currentTimingDatasetId });
                    dispatch({ type: SET_DATA, id:currentTimingDatasetId , data });
                })
            })
            .catch(err=>{
                dispatch(decrementPendingRequests());
                console.log('ERROR getting timingSetSteps:', err)
            });
    }
}