import React, { useEffect, useReducer } from "react";
import steeltoe from 'steeltoe'
import { useForm } from "react-hook-form";
import {decrementPendingRequests, incrementPendingRequests} from '../../../../actions/requestsPending'
import { requestRacesAnalyticsByOrgId } from '../../../../actions/racesAnalyticsByOrgId';
import firebase, { db } from '../../../../config/firebase'
import { connect } from 'react-redux'

const stepInitialState = {
    callStatus: '', // pending, success, error
};

const stepStateReducer = (state, action) => {
    // action.type
    return {...state, ...action};
}



const Step9 = ({goToPrevStep, timingDataset, currentTimingDatasetId, update, settings, decrementPendingRequests, incrementPendingRequests, requestRacesAnalyticsByOrgId}) => {
    const { handleSubmit } = useForm();
    const [state, setState] = useReducer(stepStateReducer, stepInitialState);
    useEffect(()=>{
        // componentDidMount
        console.log('timingDataset.runRaceAnalytics_taskId', timingDataset.runRaceAnalytics_taskId);
        if(timingDataset.runRaceAnalytics_taskId){
            console.log('looking for the task doc');
            setState({ callStatus: 'pending' });
            let upd = {};
            db.collection('tasks').doc(timingDataset.runRaceAnalytics_taskId).get()
            .then(doc => {
                console.log('success getting task doc');
                if(!doc.exists){
                    console.log('error getting task doc - not found');
                    setState({ callStatus: '' });
                    return;
                }
                const task = doc.data();
                console.log('success getting task doc', task);
                
                if(steeltoe(task).get('result.error')){
                    upd.callStatus = 'error';
                    upd.errorMessage = steeltoe(state).get('task.result.error') ;
                }
                if(steeltoe(task).get('result.success')){
                    upd.callStatus = 'success';

                    
                }

                setState(upd);
            })
            .catch(err=>{
                console.log('error getting task doc', err);
                setState({ callStatus: 'error', errorMessage: 'Firestore connection error:' + err.message });
            });
        }
    },[timingDataset.runRaceAnalytics_taskId]);

    useEffect(()=>{
        console.log('[useEffect] runRaceAnalytics_taskId:', state.runRaceAnalytics_taskId);
        if(!state.runRaceAnalytics_taskId){
            return ()=>{};
        }

        update({ runRaceAnalytics_taskId: state.runRaceAnalytics_taskId });

        // start listening to the task document
        return db.collection('tasks').doc(state.runRaceAnalytics_taskId).onSnapshot(doc=>{
            setState({ task: doc.data() });
        });


    }, [state.runRaceAnalytics_taskId]);

    useEffect(()=>{
        // task document in Firestore was updated
        console.log('[useEffect] task updated', state.task);

        if(steeltoe(state).get('task.result.success')){
            setState({ callStatus: 'success' });

            // do something when the task is successfully done
            // initiate request to get analytics from mongo for this RaceCode
            const raceCodes = [timingDataset.RaceCode];
            console.log('Step9: [requestRacesAnalyticsByOrgId]')
            requestRacesAnalyticsByOrgId(settings.currentOrgId, raceCodes);

        }
        if(steeltoe(state).get('task.result.error')){
            setState({ 
                callStatus: 'error', 
                errorMessage: steeltoe(state).get('task.result.error') 
            })

        }

    },[state.task]);
   

    const onSubmit = data => {
        console.log('STEP 9 - submitted data:', data);

        // const { TimingDataOption1 } = data;

        // update(data);

        // goToNextStep();
    }

    const runRaceAnalytics = () => {
        // const confirmed = window.confirm('Are you sure?');
        // if(!confirmed) return;



        incrementPendingRequests();
        
        setState({ callStatus: 'pending' });
        const funcaddTask = firebase.functions().httpsCallable('addTask');
        funcaddTask({ 
            payload: {
                timingDatasetId: currentTimingDatasetId,
            },
            type: 'runRaceAnalytics'
            // orgId
        }).then(result=>{
            console.log('funcaddTask result:', result.data);
            setState({ runRaceAnalytics_taskId: result.data.id });
            
            
            decrementPendingRequests()
            // window.location.reload();
        }).catch(e => {
            setState({ callStatus: 'error' });
            decrementPendingRequests();

            console.warn('error calling backend function',e)
        });
        
    }

    
    return (
        <>

            { !!state.errorMessage && <p style={{color:'red'}}>ERROR: {state.errorMessage}</p>}
            
            <div className="buttons">
                <button onClick={runRaceAnalytics} disabled={state.callStatus==='pending' || state.callStatus==='success'}>RUN RACE ANALYTICS</button>
                { state.callStatus==='success' || state.callStatus==='pending'  ? (
                    <button disabled={state.callStatus==='pending'} onClick={runRaceAnalytics}>Re-run</button>
                ): null}
            </div>


            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="buttons">
                    <button onClick={goToPrevStep}>Previous</button>
                    {/* <button type="submit" disabled={state.callStatus!=='success'}>Next</button> */}
                    <button type="submit" disabled={state.callStatus!=='success'}>COMPLETED</button>
                </div>
            </form>
        </>
    )
}
export default connect(({settings})=>({settings}),{decrementPendingRequests, incrementPendingRequests, requestRacesAnalyticsByOrgId})(Step9)