import React, { Component } from "react";
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
// import logoimg from "../../images/logo-05.svg";
import logoimg from '../../images/slack-01.svg'


import './Sidebar.scss'

class Sidebar extends Component {


    

    goToHome = () => this.props.history.push('/')
    
    render(){
        // const {page,fbuser, fbuserProfile, openSidebar, race, athlete, closeSidebar, settings: {isSidebarOpen, windowW}} = this.props;

        const onClick = this.goToHome;


        // http://localhost:3000/welcome/mvm-ma-2018/athletes/569

        return (
         <div className="Sidebar">
            <div className="SidebarButton" onClick={onClick}>
                <div className="background" />
                <img src={logoimg} alt="Menu" />
            </div>
           
         </div>
        )
    }
}


// const mapState = ({settings}) => ({settings});
// const mapState = ({fbuser, settings, fbuserProfile})=>({fbuser, settings, fbuserProfile})

const mapDispatch = dispatch => ({

})

// export default withRouter(connect(mapState, mapDispatch)(Sidebar));
export default withRouter(connect(null, mapDispatch)(Sidebar));

