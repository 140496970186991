import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom'
import steeltoe from 'steeltoe';
import logo from "../../images/logo.svg"
import icoRunner from "../../images/user-04.svg"
import iconEmail from "../../images/mail-01.svg"

import imgUnchecked from '../../images/check-1.svg';
import imgCheckHover from '../../images/check-2.svg';
import imgChecked from '../../images/check-3.svg';

import './InviteMemberModalForm.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import classNames from 'classnames'
import mongoapi from '../../apis/mongoapi'
import keepInTouchIcon from "../../images/happy120+.gif";
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

import imgRunner from '../../images/running-small.gif'
import cityImgMobile from '../../images/generic-popup-1.svg'
import TooltipText from '../../components/Tooltip/TooltipText'
import {RemoveScroll} from 'react-remove-scroll';

import * as orgsByIdActions from '../../actions/orgsById';
import { db } from "../../config/firebase";


class InviteMemberModalForm extends Component {

    constructor(props){
        super(props);

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        this.contentEl = window.document.createElement('div');
        window.document.body.appendChild(this.contentEl);
    }

    state = {
        email: '',
        firstname: '',
        lastname: '',
        notes: '',
        roleAdmin: true,
        roleTimer: true,
        roleMarketing: true,
        errors: {},
        formSubmitted: false,
    }
    componentDidMount(){
        this.setUp();
    }
    

    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        window.document.body.removeChild(this.contentEl);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.orgId !== this.props.match.params.orgId) {
            this.setUp();
        }
    }

    setUp = () => {
        const { orgsById, match: { params: { orgId }}} = this.props; //this.props.match.params.orgId
        if(!orgId){
            return;
        }

        // if(!steeltoe(orgsById).get(`${orgId}.requestStatus`)){
        //     this.props.orgsByIdActions.requestOrgById(orgId);
        //     return;
        // }

    }

    open = () => {
        if( this.props.disabled ) { return; }

        this.props.openModal();
    }
    close = () => {
        this.props.closeModal();
        this.setState({formSubmitted: false})
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="InviteMemberModalCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }



    validate = async () => {
        let nextErrors = {...this.state.errors};
        // nextErrors.name = this.state.name ? null : 'Please enter Name';
        nextErrors.email = this.state.email ? null : 'Please enter Email';
        // nextErrors.race = this.state.race ? null : 'Please enter Race name';

        this.setState({errors: nextErrors});

        const formInvalid = Object.keys(nextErrors).filter( key => !!nextErrors[key] ).length > 0;

    
        if(formInvalid){
            throw new Error('Form not valid');
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        

        this.validate()
            .then(()=>{
                // form valid
                // console.log('form validation: VALID');
                this.addInvite();
            })
            .catch(err =>{
                // form not valid
                // console.log('form validation: INVALID', err)
            });
    }

    addInvite = () => {
        const { orgsById, fbuser } = this.props;
        const orgId = this.props.match.params.orgId;
        const orgName = steeltoe(orgsById).get(`${orgId}.data.name`) || '...';



        const invitedByUid = fbuser ? fbuser.uid : null;
        // console.log("invitedByUid",invitedByUid)

        // return;
        const { firstname, lastname, notes, email } = this.state;
        
        let roles = [];
        if(this.state.roleAdmin) roles.push('admin');
        if(this.state.roleTimer) roles.push('timer');
        if(this.state.roleMarketing) roles.push('marketing');
        
        db.collection('invites').add({
            orgId,
            firstname,
            lastname,
            notes,
            email,
            orgName,
            invitedByUid,
            roles
        }).then(() => {
            this.thankYou();
        }).catch(err=>{
            console.warn('ERROR adding invite to firestore', err)
        })



    }

    thankYou = () => this.setState({formSubmitted: true})

    updateField = field => e => {
      

        let errors = {
            [field]: this.getValidationErrors(field, e.target.value),
            form: null
        };

        this.setFormErrorMessage(errors);
        
        this.setState({ [field]: e.target && typeof e.target.value!=='undefined' ? e.target.value : e })
    }

    handleInputChange = e => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({ [name]: value });
    }

    getValidationErrors = (field, value) => {
        if(['firstname','lastname'].includes(field)){
            return !value ? `${field} is required` : null;
        }

        // console.log("CHECKING IF EMAIL VALID")
        if( field==='email'  &&  !(/.+@.+\..+/g).test(value) ) {
            return 'Email is invalid';
        }
        
        return null;
    }

    setFormErrorMessage = (err) => { // err:  { field: errorMessage }
        const errors = this.state.errors || {};
        let nextErrors = {...errors, ...err};
        let errs = {};
        Object.keys(nextErrors).forEach(key=>{
            if(nextErrors[key]){
                errs[key] = nextErrors[key]
            }
        });
        this.setState({errors: errs});
    }


    renderForm = () => {
        // const { email, name, phone, comment, errors } = this.state;
        const { email, firstname, lastname, roles, notes, errors } = this.state;

        const { orgsById } = this.props;

        const orgId = this.props.match.params.orgId;


        const orgName = steeltoe(orgsById).get(`${orgId}.data.name`) || '...';

        // need to get the org details
        // const orgName = steeltoe(accessRolesByUser).get(`${uid}.requestStatus`)

        return (
            <form onSubmit={this.handleSubmit}>

                <div className="title">Add a Member</div>

                <div className="Label">
                    <i></i>
                    <span>{ orgName }</span>
                    <i></i>
                </div>
                
                {/* <p className="light">The MyRace Portal allows your team to manage your event's online race results.</p> */}
                <p className="light">Invite members of your team to support your event using the MyRace Portal</p>

                <div className={classNames("control", { invalid: !!this.state.errors.org })}>
                    <label> 
                        <span className="label">Member's Email Address</span>
                        <input type="email" 
                                name="email" 
                                value={email} 
                                placeholder="email to invite..." 
                                onChange={this.handleInputChange} 
                                style={{backgroundImage: `url(${iconEmail})`}} />
                    </label>

                </div>

                <div className={classNames("control", { invalid: !!this.state.errors.org })}>
                    <label> 
                        <span className="label">Member's First Name</span>
                        <input name="firstname" type="text" 
                            placeholder={ 'First Name...'}
                            onChange={this.handleInputChange} 
                            value={firstname} 
                            // style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>
                <div className={classNames("control", { invalid: !!this.state.errors.org })}>
                    <label> 
                        <span className="label">Member's Last Name</span>
                        <input name="lastname" type="text" 
                            placeholder={ 'Last Name...'}
                            onChange={this.handleInputChange} 
                            value={lastname} 
                            // style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>
                <div className={classNames("control", { invalid: !!this.state.errors.org })}>
                    <label> 
                        <span className="label">Special Notes to include to member:</span>
                        <input name="notes" type="text" 
                            placeholder={ 'Notes...'}
                            onChange={this.handleInputChange} 
                            value={notes} 
                            // style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>

                {/* <div>
                    <label><input type="checkbox" checked={this.state.roleAdmin} name="roleAdmin" onChange={this.handleInputChange} /> Admin </label>
                    <label><input type="checkbox" checked={this.state.roleTimer} name="roleTimer" onChange={this.handleInputChange} /> Timer </label>
                    <label><input type="checkbox" checked={this.state.roleMarketing} name="roleMarketing" onChange={this.handleInputChange} /> Marketing </label>
                </div> */}

                <div className="CheckboxControl" >
           
                    <input onChange={this.handleInputChange} type="checkbox" name="roleAdmin" id="checkbox_admin" checked={this.state.roleAdmin} />
                    <label htmlFor="checkbox_admin">
                        <img src={imgUnchecked} className="checkbtn unselected" alt="age grade off" />
                        <img src={imgCheckHover} className="checkbtn unselected_hover" alt="admin hover"/>
                        <img src={imgChecked} className="checkbtn selected" alt="admin on"/>
                        <span className="label_name">Admin</span>
                    </label>
                </div>
                <div className="CheckboxControl" >
           
                    <input onChange={this.handleInputChange} type="checkbox" name="roleTimer" id="checkbox_timer" checked={this.state.roleTimer} />
                    <label htmlFor="checkbox_timer">
                        <img src={imgUnchecked} className="checkbtn unselected" alt="age grade off" />
                        <img src={imgCheckHover} className="checkbtn unselected_hover" alt="timer hover"/>
                        <img src={imgChecked} className="checkbtn selected" alt="admin on"/>
                        <span className="label_name">Timer</span>
                    </label>
                </div>
                <div className="CheckboxControl" >
           
                    <input onChange={this.handleInputChange} type="checkbox" name="roleMarketing" id="checkbox_marketing" checked={this.state.roleMarketing} />
                    <label htmlFor="checkbox_marketing">
                        <img src={imgUnchecked} className="checkbtn unselected" alt="age grade off" />
                        <img src={imgCheckHover} className="checkbtn unselected_hover" alt="marketing hover"/>
                        <img src={imgChecked} className="checkbtn selected" alt="marketing on"/>
                        <span className="label_name">Marketing</span>
                    </label>
                </div>
                

                <div className="buttons">
                    <button className="secondary" onClick={this.close}>Cancel</button>
                    <button type="submit">Send the Invite</button>
                </div>


                
            </form>
        )
    }

    renderThankYou = () => {
        return (
            <div className="ThankYou">
                <div className="title">Invite Sent!</div>
                <p>Thank you for growing your team on the MyRace Portal!</p>
                <img src={icoRunner} className="ThankYouFinishIcon" />
            </div>
        )
    }


    
    renderModalContent = () => {
        const { formSubmitted } = this.state;
        const { settings } = this.props;

        return (
            ReactDOM.createPortal(
                <RemoveScroll>

                    <div className={classNames("InviteMemberModalForm", {
                        InviteMemberModalFormThankYou: formSubmitted
                    })} style={{
                        // backgroundImage: `url(${imgRunner}), url(${cityImgMobile})`,
                    }}>
                        {/* <div className="IconSwappable ClearIcon" onClick={this.close}>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div> */}

                        <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                            <div className="background"/>
                            <TooltipText><em>Close</em></TooltipText>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div>
{/* 
                        <div className="HeroImage">
                            <img src={cityImgMobile} className="CityBackground" />
                            <img src={imgRunner} className="Runner" />
                        </div> */}

        
        
                        <div className="ModalContent">

                            { !formSubmitted? this.renderForm() : this.renderThankYou() }
            
                        </div>
        
                        
        
                        {/* <img src={ imgRunner } className="runnergif"  /> */}
                    </div>
                </RemoveScroll>
            , this.coverEl)
        )
    }

    render(){
        const { settings: {isInviteMemberModalOpen = false} } = this.props;

        return (
            <>

                { isInviteMemberModalOpen && this.renderModalContent() }
                { isInviteMemberModalOpen && this.renderModal() }
            </>
        )
    }
}

const mapState = ({settings, orgsById, fbuser}) => ({settings, orgsById, fbuser});
const mapDispatch = dispatch => ({
    openModal: ()=>dispatch({type:'SET_SETTING', isInviteMemberModalOpen: true}),
    closeModal: ()=>dispatch({type:'SET_SETTING', isInviteMemberModalOpen: false}),
    orgsByIdActions: bindActionCreators(orgsByIdActions, dispatch),
})


export default withRouter(connect(mapState, mapDispatch)(InviteMemberModalForm));
