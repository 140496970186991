import React, { Component } from "react";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'

import DropdownDivision from "../DropdownDivision/DropdownDivision";
// import DropdownHometown from "../DropdownHometown/DropdownHometown";
import Dropdown from "../Dropdown/Dropdown";
import imgClearFilter from "../../images/filter-2.svg"
import "./AthleteListFilters.scss"
import TooltipText from '../Tooltip/TooltipText'

// import Beacon from '../Beacon/Beacon'
// import { setBeaconDone } from '../../actions/settings'
import { bindActionCreators } from "redux";

class AthleteListFilters extends Component {


  athleteGroupDropdownOptions = [
    {
      id: 'All-Genders',
      title: 'All Genders'
    },{
      id: 'M',
      title: 'Males'
    }, {
      id: 'F',
      title: 'Females'
    },
  ]

  athleteDivisionDropdownOptions = [
    {
      id: 'All-DivisionType1s',
      title: 'All Ages'
    },{
      id: 'M01-15',
      title: 'M0–15'
    }, {
      id: 'M16-19',
      title: 'M16–19'
    }, {
      id: 'M20-24',
      title: 'M20–24'
    }, {
      id: 'M25-29',
      title: 'M25–29'
    }, {
      id: 'M30-34',
      title: 'M30–34'
    }, {
      id: 'M35-39',
      title: 'M35–39'
    }, {
      id: 'M40-44',
      title: 'M40–44'
    }, {
      id: 'M45-49',
      title: 'M45–49'
    }, {
      id: 'M50-54',
      title: 'M50–54'
    }, {
      id: 'M55-59',
      title: 'M55–59'
    }, {
      id: 'M60-64',
      title: 'M60–64'
    }, {
      id: 'M65-69',
      title: 'M65–69'
    }, {
      id: 'M70-74',
      title: 'M70–74'
    }, {
      id: 'M75-79',
      title: 'M75–79'
    }, {
      id: 'M80-84',
      title: 'M80–84'
    }, {
      id: 'M85-89',
      title: 'M85–89'
    }, {
      id: 'M90-94',
      title: 'M90–94'
    }, {
      id: 'M95-99',
      title: 'M95–99'
    }, {
      id: 'M-NoAge',
      title: 'M NoAge'
    },{
      id: 'F01-15',
      title: 'F0–15'
    }, {
      id: 'F16-19',
      title: 'F16–19'
    }, {
      id: 'F20-24',
      title: 'F20–24'
    }, {
      id: 'F25-29',
      title: 'F25–29'
    }, {
      id: 'F30-34',
      title: 'F30–34'
    }, {
      id: 'F35-39',
      title: 'F35–39'
    }, {
      id: 'F40-44',
      title: 'F40–44'
    }, {
      id: 'F45-49',
      title: 'F45–49'
    }, {
      id: 'F50-54',
      title: 'F50–54'
    }, {
      id: 'F55-59',
      title: 'F55–59'
    }, {
      id: 'F60-64',
      title: 'F60–64'
    }, {
      id: 'F65-69',
      title: 'F65–69'
    }, {
      id: 'F70-74',
      title: 'F70–74'
    }, {
      id: 'F75-79',
      title: 'F75–79'
    }, {
      id: 'F80-84',
      title: 'F80–84'
    }, {
      id: 'F85-89',
      title: 'F85–89'
    }, {
      id: 'F90-94',
      title: 'F90–94'
    }, {
      id: 'F95-99',
      title: 'F95–99'
    }, {
      id: 'F-NoAge',
      title: 'FNoAge'
    }
    // , {
    //   id: '10',
    //   title: 'Top 10 athletes'
    // }, {
    //   id: 'winners',
    //   title: 'Winners'
    // }, 
  ]

  SplitTypeDropdownOptions = [
    { id: 'All-Splits', title: 'All splits' },
    { id: 'Negative', title: 'Negative' },
    { id: 'Positive', title: 'Positive' },
  ]



  // yearDropdownOptions = [
  //   { id: 2018, title: '2018' },
  //   { id: 2017, title: '2017' },
  //   { id: 2016, title: '2016' },
  //   { id: 2015, title: '2015' },
  //   { id: 2014, title: '2014' },
  //   { id: 2013, title: '2013' },
  //   { id: 2012, title: '2012' },
  // ]

  setAthleteGroupDropdownValue = id => {
    // let newState = { athleteGroupDropdownValue: id };
    // this.setState(newState);

    // this.props.history.push(`/select-athlete/${this.props.raceSelected.eventCode}/${this.props.raceSelected.raceCode}/${id}`)
    // console.log("setting the athleteFilter in redux to",filter);
    if(this.props.raceAthleteFilter.overallFilter !== id) {
      // console.log('[setAthleteGroupDropdownValue] selectRaceAthleteFilter', id)
      this.props.selectRaceAthleteFilter(id);
    }
  }
  setAthleteDivisionDropdownValue = id => {

    // this.props.history.push(`/select-athlete/${this.props.raceSelected.eventCode}/${this.props.raceSelected.raceCode}/${id}`)
    // console.log("setting the athleteFilter in redux to",filter);
    console.log("new id",id);
    if(this.props.raceAthleteFilter.divisionFilter !== id) {
      // this.props.selectRaceAthleteFilter(id);
      this.props.setDivisionFilter(id);
    }
  }

  setSplitTypeDropdownValue = id => {
    if(this.props.raceAthleteFilter.splitTypeFilter !== id) {
      this.props.setSplitTypeFilter(id);
    }

  }
  setLocationDropdownValue = (id, obj) => {
    console.log('[AthleteListFilters] LOCATION FILTER CHANGED', id, obj)
    // console.log("CALLING LOCATION DROPDOWN",id);
    const city = (obj === undefined || obj.City === undefined) ? '' : obj.City;
    const stateCode = (obj === undefined || obj.StateCode === undefined) ? '' : obj.StateCode;
    const countryCode = (obj === undefined || obj.CountryThreeCharCode === undefined) ? '' : obj.CountryThreeCharCode;

    const newFilter = city + '*' + stateCode + '*' + countryCode;
    // console.log("locationFilter = ",newFilter);
    if(this.props.raceAthleteFilter.locationFilter !== id) {
      this.props.setLocationFilter(newFilter);
    }

  }

  clearFilters = e => {
    this.props.clearRaceAthleteFilter();
    // this.beaconDone("clearFilterButton")();
  }

  

  // beaconDone = beaconId => e => {
  //     this.props.settingsActions.setBeaconDone(beaconId)
  // }


  render() {

    // const { athleteGroupDropdownValue, yearDropdownValue, athleteDivisionDropdownValue } = this.state;

    const { raceAthleteFilter, match } = this.props;
    const { overallFilter, divisionFilter, splitTypeFilter, locationFilter, isDirty, onlyFavorite } = raceAthleteFilter;
    const isDisabled = divisionFilter !== 'All-DivisionType1s' || !!onlyFavorite;
    

    // console.log("this.splitTypeDropdownOptions",this.splitTypeDropdownOptions);
    // console.log("divisionFilter",divisionFilter);
    // console.log("locationFilter (render)",locationFilter);



    return (
      <div className="AthleteListFilters">
        <Dropdown 
          isForGenderFilter = {true}
          disabled={ divisionFilter !== 'All-DivisionType1s' || !!onlyFavorite }
          options={this.athleteGroupDropdownOptions} value={overallFilter} onChange={this.setAthleteGroupDropdownValue} />
        <DropdownDivision
          disabled={ !!onlyFavorite }
          race={this.props.raceSelected} options={this.athleteDivisionDropdownOptions} value={divisionFilter} onChange={this.setAthleteDivisionDropdownValue} />
          
        {/* <DropdownHometown
          key={match.params.raceCode}
          disabled={ !!onlyFavorite }
          race={this.props.raceSelected} value={locationFilter} onChange={this.setLocationDropdownValue}/> */}
          
        {/* <DropdownHometown race={this.props.raceSelected} onChange={this.setLocationDropdownValue}/> */}
        {/* <Dropdown options={this.SplitTypeDropdownOptions} value={splitTypeFilter} onChange={this.setSplitTypeDropdownValue} /> */}

        { isDirty && <div
          role="button"
          className="ClearFiltersButton tooltip"
          onClick={this.clearFilters}
          style={{ backgroundImage: `url(${imgClearFilter})`}}>
            {/* <Beacon id="clearFilterButton" text='' direction="left" style={{ top:'8px', left:'30px'}} /> */}
            <TooltipText>Clear Filters &amp; Sort</TooltipText>
          </div>
        }
       
      </div>
    );
  }
}

const mapStateToProps = state => ({
  raceSelected: state.raceSelected,
  raceAthleteFilter: state.raceAthleteFilter
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    selectRaceAthleteFilter: filter => dispatch({type:'SET_RACE_ATHLETE_FILTER', filter}),
    setDivisionFilter: filter => dispatch({type:'SET_DIVISION_FILTER', filter}),
    setSplitTypeFilter: filter => dispatch({type:'SET_SPLIT_TYPE_FILTER', filter}),
    setLocationFilter: filter => dispatch({type:'SET_LOCATION_FILTER', filter}),
    clearRaceAthleteFilter: () => dispatch({type:'CLEAR_RACE_ATHLETE_FILTER'}),
    // settingsActions: bindActionCreators({setBeaconDone}, dispatch), 
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AthleteListFilters));;
