import React, { useState } from "react";
import styled from "styled-components";
import classnames from 'classnames'
import { useSelector, useDispatch } from "react-redux";
import { setValues } from "../../actions/adBuilder";






const BannerOption_ = ({className, onClick}) => {
    return (
        <div {...{className, onClick}}>
            <div className="Cut">
            </div>
        </div>
    )
};

const BannerOptionStyled = styled(BannerOption_)`
    box-sizing: border-box;
    width: 18px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: 15px;
    filter: drop-shadow(0px 0px 1px rgba(50,50,0,0.4));
    cursor: pointer;
    
    .Cut{
        background-color: #ffffff;
        padding: 0px 0px 18px 0px;
    }

    &.type02 {
        .Cut {
            clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 100%, 0 0);
        }
    }
    &.type03 {
        .Cut {
            clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
        }
    }
    &.type04 {
        .Cut {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    &.selected {
        cursor: default;
        .Cut {
            background: #cce8fe;
        }
    }
`;
const BannerOption = ({type}) => {
    const selectedBannerTypeId = useSelector(s => s.adBuilder.bannerTypeId);
    const dispatch  = useDispatch();
    const onClick = e => dispatch(setValues({ bannerTypeId: type }))
    return (
        <BannerOptionStyled
            onClick={onClick}
            className={classnames(`type${type}`, {
                selected: type===selectedBannerTypeId
            })}
         selected={type===selectedBannerTypeId} {...{type}} />
    )
}






const BannerOptions_ = ({className}) => {

    return (
        <div className={className}>
            <BannerOption type="01" />
            <BannerOption type="02" />
            <BannerOption type="03" />
            <BannerOption type="04" />
        </div>
    )
};

const BannerOptions = styled(BannerOptions_)`
    display: flex;
`;


export default BannerOptions;