import React, { Component } from "react";
import "./RaceEditFilters.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
// import TooltipText from '../Tooltip/TooltipText'

class RaceEditFilters extends Component {


  componentDidMount(){

  }

  selectFilterOption = filterOption => e => {
    // if(this.props.requestsPending){ return; }
    // const { setRaceEditOption } = this.props.settings;

    // const clone = setRaceEditOption.slice(0);
    // const index = clone.indexOf(filterOption);

    // if (index > -1) {
    //     clone.splice(index, 1);
    // } else {
    //     clone.push(filterOption);
    // }
    this.props.setRaceEditOption(filterOption)
  }

  render() {
    const { raceEditOption } = this.props.settings;

    const filters = ['Race','Race Images','Elevations','Timing Data'];
    
    return (
      <div className="RaceEditFilters">
        
        <div className="line"/>

        { filters.map( filterOption => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={filterOption} onClick={this.selectFilterOption(filterOption)} 
            className={classnames("button", { selected: raceEditOption===filterOption})}>

            {filterOption} 
          </div>
        ))}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = ({ settings, requestsPending }) => ({ settings,requestsPending })

const mapDispatchToProps = dispatch => ({
//   setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE_FILTER_STATUS', race}),
  setRaceEditOption: raceEditOption => dispatch({type:'SET_SETTING', raceEditOption}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceEditFilters));

