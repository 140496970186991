import React, { useState } from "react";
import classNames from 'classnames'
import SorterButton from "./SorterButton";

const AthleteDivisionCells = props => {
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    // const { row, race } = props;
    const { raceAthleteCategories, row, isHeader } = props;

    let divList = [];
    let divListNames = [];
    let defaultSelectedOption = null;
    let selectedCategory = null;
    let selectedOptionPlace = null;

    if (!isHeader && row) {

        if (raceAthleteCategories.includes("EL") && row.DivisionEliteGroup) {
            divList.push("EL");
            divListNames.push("Elite");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "EL";
        }
        if (raceAthleteCategories.includes("MA") && row.DivisionMastersGroup) {
            divList.push("MA");
            divListNames.push("Masters");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "MA";
        }
        if (raceAthleteCategories.includes("WE") && row.DivisionAthenaClydesdaleGroup) {
            divList.push("WE");
            divListNames.push("Weight");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "WE";
        }
        if (raceAthleteCategories.includes("VI") && row.DivisionVisuallyImpairedGroup) {
            divList.push("VI");
            divListNames.push("Visually Impaired");
            
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "VI";
        }
        if (raceAthleteCategories.includes("AG") && row.DivisionAgeGroup) {
            divList.push("AG");
            divListNames.push("Age Group");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "AG";
        }

        defaultSelectedOption = divList[selectedCategoryIndex];

        // console.log("row",row);
        // console.log("raceAthleteCategories",raceAthleteCategories);
        // console.log("defaultSelectedOption",defaultSelectedOption);

        // Set the place based on which Category
        if (defaultSelectedOption == "EL") {
            selectedCategory = "ELITE • " + row.Gender;
            selectedOptionPlace = row.PlaceDivisionEliteGroup;
        } else if (defaultSelectedOption == "AG") {
            selectedCategory = row.DivisionAgeGroup.replace('-','–');
            selectedOptionPlace = row.PlaceDivisionAgeGroup;
        } else if (defaultSelectedOption == "MA") {
            selectedCategory = "MAS • " + row.Gender;
            selectedOptionPlace = row.PlaceDivisionMastersGroup;
        } else if (defaultSelectedOption == "WE") {
            selectedCategory = row.Gender === "F" ? "ATH" : "CL";
            selectedOptionPlace = row.PlaceDivisionAthenaClydesdaleGroup;
        } else if (defaultSelectedOption == "VI") {
            selectedCategory = "VI • " + row.Gender;
            selectedOptionPlace = row.PlaceDivisionVisuallyImpairedGroup;
        } 
    }

    
    let showCategoriesHeader = null;
    let showCategoriesCell = null;


    if (raceAthleteCategories && raceAthleteCategories.length > 1) {
        showCategoriesHeader = <th style={{ width: '5%'}}>Categories</th>;
        showCategoriesCell = <td style={{ width: '5%'}}>{divList.map((div,i) => {
            return (
                <span key={i}
                    // data-tip={div} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                    className={classNames(selectedCategoryIndex===i ? '': 'tooltip',"athleteCategory",{ selected: selectedCategoryIndex===i })} 
                    onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCategoryIndex(i) }}>
                    {selectedCategoryIndex===i ? '': <span className={classNames("tooltiptext", divList[i]) }>{divListNames[i]}</span>}
                    {div}
                </span>
            )
        })}</td>;
    } 
   
    // console.log("raceAthleteCategories.length",raceAthleteCategories && raceAthleteCategories.length);

    const content = isHeader ?  
        <>
            <th 
                data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                style={{ width: '3%'}}>Gender<SorterButton col="Gender"/></th>
            <th 
                data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                style={{ width: '3%'}}>Age<SorterButton col="Age"/></th>
            <th 
                data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                style={{ width: '3%'}}>Gender<br/>Place<SorterButton col="PlaceGender"/></th>
            {showCategoriesHeader}
            <th style={{ width: '6%'}}>Category</th>
            <th 
                data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                style={{ width: '4%'}}>Category<br/>Place<SorterButton col="PlaceDivisionAgeGroup"/></th>
        </> :
        <>
            <td style={{ width: '3%'}}>{row.Gender}</td>
            <td style={{ width: '3%'}}>{row.Age}</td>
            <td style={{ width: '3%'}}>{row.PlaceGender}</td>
            {showCategoriesCell}
            <td style={{ width: '6%'}}>{selectedCategory}</td>
            <td style={{ width: '4%'}}>{selectedOptionPlace}</td>
        </>;


    return (
        content
    )
}

export default AthleteDivisionCells