import React, { Component, useState } from "react";
import steeltoe from 'steeltoe'
import classnames from 'classnames'
import * as Sentry from '@sentry/browser';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import {connect, useDispatch, useSelector} from 'react-redux'
import { bindActionCreators } from "redux";
import landscape from "../../images/generic-mobile.svg";

import imgClose from "../../images/close-04.svg";
import imgCloseHover from "../../images/close-03.svg";

import "./AdBuilder.scss";
import Headroom from 'react-headroom';
import styled from "styled-components";

import MainAthleteBlock from './MainAthleteBlock/MainAthleteBlock'
import { setValues, setSelectedCraft, setSelectedLogo } from "../../actions/adBuilder";

import harnessSVG from '../../images/harness.svg'
import imgRunBetter from '../../images/adsImages/RunBetter.png'
import imgGarmin from '../../images/adsImages/garmin.png'
import imgGu from '../../images/adsImages/gu.png'
import imgRevuLogo from '../../images/adsImages/revolugo.png'
import imgCapitalOne from '../../images/adsImages/2_0001sCapital-One.png'
import { crafts, craftsArr, logos, logosArr } from "./assets";
// import AirshipPreview from "./AirshipPreview/AirshipPreview";
import AirshipPreview from "./AirshipPreview/AirshipFramer";
import BannerOptions from "./BannerOptions";





const AdBuilderContainer = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 330px 630px 330px;
    
`;


const BuilderCol = styled.div`

`;



const AdBuilderSection = styled.div`
    &.Main {
        display: grid;
        grid-template-columns: repeat(4, 120px);
        grid-gap: 50px;
    }
`;


const CloseButton_ = ({className, onClick}) => {
    return (
        <div {...{onClick, className}} >
            <img src={imgClose} className="default" />
            <img src={imgCloseHover} className="hover" />
        </div>
    )
}
const CloseButton = styled(CloseButton_)`
    width: 18px;
    height: 18px;
    display: block;
    
    position: absolute;
    top: 12px;
    right: 12px;

    font-size: 10px;
    text-align: center;
    cursor: pointer;
    
    &:hover {
    }
    img {
        max-width:100%;
        width: 18px;
    }
    .default { display: block; }
    .hover { display: none; }
    &:hover {
        .default { display: none; }
        .hover { display: block; }
    }
`;



const ClosableCard_ = ({className, children, onCloseRequest, closed=false}) => {
    if(closed){
        return <div/>
    }
    return (
        <div className={className}>
            <CloseButton onClick={onCloseRequest} />
            {children}
        </div>
    )
}
const ClosableCard = styled(ClosableCard_)`
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 20px #ccc;
    padding: 40px 10px;
    border-radius: 8px;
    position: relative;
    `;





const PreviewCard = styled.div`
    padding: 15px;
    box-shadow: 2px 2px 20px #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    &.pointer {
        cursor: pointer;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;


const RangeInput_ = ({ name, title, displayUnit, className, step=1, min=0, max=100}) => {
    const dispatch  = useDispatch();
    const value = useSelector(s => s.adBuilder[name]);
    const onChange = e => dispatch(setValues({ [name]: +e.target.value }))
    return (
        <div className={className}>
            <input type="range" onChange={onChange}
                {...{step, min, max, value}}
            />
            <div className="info">
                <div>{title}</div>
                <div><strong>{value}</strong> {displayUnit}</div>
            </div>
        </div>
    )
};
const RangeInput = styled(RangeInput_)`
    margin-bottom: 20px;
    input {
        width: 100%;
    }
    .info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &>div:last-child {
            text-align: right;
            white-space: nowrap;
            strong {
                display: inline-block;
                font-weight: 800;
                margin-right: 1px;
            }
        }
    }
`;

const ResetButton = styled.div`
    background-color: #fff;
    border: 2px solid #cce8fe;
    border-radius: 15px;
    padding: 4px 9px;
    color: #008dfb;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
`;




const CardCellOption = styled.div`
    .Option {
        cursor: pointer;
        border: 4px solid transparent;
        border-radius: 8px;
        padding: 4px;
        margin: 4px;

        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        min-height: 100%;
        box-sizing: border-box;
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &.Selected {
        .Option {
            border-color: #cce8fe;
        }
    }
`;


const CardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
`;


const LeftCardContent = ({className}) => {
    const dispatch  = useDispatch();
    const selectedId = useSelector(s => s.adBuilder.selectedCraftId);

    return (
        <CardGrid>
            {craftsArr.map(([id, {img}])=>{
                return (
                    <CardCellOption className={classnames("Cell",{
                        Selected: selectedId === id
                    })} key={id}>
                        <div className="Option" 
                            onMouseEnter={()=>dispatch( setValues({hoveredCraftId: id}) )}
                            onMouseLeave={()=>dispatch( setValues({hoveredCraftId: selectedId}) )}
                            onClick={()=>dispatch( setSelectedCraft(id) )}>
                            <img src={img} />
                        </div>
                    </CardCellOption>
                )
            })}
        </CardGrid>
    )
}


const RightCardContent = ({className}) => {
    const dispatch  = useDispatch();
    const selectedId = useSelector(s => s.adBuilder.selectedLogoId);
    return (
        <CardGrid>
            {logosArr.map(([id, {img}])=>{
                return (
                    <CardCellOption className={classnames("Cell",{
                        Selected: selectedId === id
                    })} key={id}>
                        <div className="Option" 
                            onMouseEnter={()=>dispatch( setValues({hoveredLogoId: id}) )}
                            onMouseLeave={()=>dispatch( setValues({hoveredLogoId: selectedId}) )}
                            onClick={()=>dispatch( setSelectedLogo(id) )}>
                            <img src={img} />
                        </div>
                    </CardCellOption>
                )
            })}
        </CardGrid>
    )
}














const SelectedCraftBodyImg = () => {
    const id = useSelector(s => s.adBuilder.hoveredCraftId);
    if(!id) return null;
    return (
        <img src={crafts[id].img} />
    )
}



const RadioButton_ = ({className, name, title, checked, onChange}) => {
    return (
        <label className={className}><input type="radio" {...{name, checked, onChange}} />{` ${title}`}</label>
    )
}
const RadioButton = styled(RadioButton_)`
    display: block;
    font-size: 12px;
    margin: 5px 0;
    cursor: pointer;
    input {
        margin-right: 6px;
    }
`;



const ColorSelect_ = ({className, name}) => {
    const dispatch  = useDispatch();
    const value = useSelector(s => s.adBuilder[name]);
    const onChange = e => dispatch(setValues({ [name]: e.target.value }))
    return (
        <input className={className} {...{name, onChange, value}} type="color" />
    )
}
const ColorSelect = styled(ColorSelect_)`

`;


const HarnessOrientationSelect_ = ({className}) => {
    const dispatch = useDispatch();
    const orientation = useSelector(s => s.adBuilder.orientation);
    return (
        <div className={className}>
            <RadioButton
                name="orientation"
                checked={orientation==='v'}
                onChange={()=>dispatch(setValues({ orientation: 'v' }))}
                title="VERTICAL"
            />
            <RadioButton
                name="orientation"
                checked={orientation==='h'}
                onChange={()=>dispatch(setValues({ orientation: 'h' }))}
                title="HORIZONTAL"
            />
        </div>
    )
}
const HarnessOrientationSelect = styled(HarnessOrientationSelect_)`
    margin-bottom: 20px;
`;



const HarnessPreview_ = ({className, harnessWidth, harnessColor, orientation}) => {
    return (
        <div className={classnames(className, 'AirshipHarness', {
            horizontal: orientation==='h',
            vertical: orientation==='v',
        })}>
            <img src={harnessSVG} />
            <div className="BannerStickPreview"></div>
        </div>
    )
}
const HarnessPreviewStyled = styled(HarnessPreview_)`
    position: relative;
    display:flex;
    align-items: center;

    .BannerStickPreview {
        width: 2px;
            height: 45px;
        background-color: ${p=>p.harnessColor};
        position: relative;
        left: -2px;
        border-radius: 3px;
    }

    &.horizontal {
        .BannerStickPreview {
        }
    }
    
    &.vertical {
        transform: rotate(90deg);
    }
`;
const HarnessPreview = () => {
    const harnessWidth = useSelector(s => s.adBuilder.harnessWidth);
    const harnessColor = useSelector(s => s.adBuilder.harnessColor);
    const orientation = useSelector(s => s.adBuilder.orientation);
    return (
        <HarnessPreviewStyled {...{harnessWidth, harnessColor, orientation}} />
    )
}



const BannerPreview_ = ({className, orientation, type}) => {
    return (
        <div className={classnames(className, `type${type}`, 'BannerPreview', {
            horizontal: orientation==='h',
            vertical: orientation==='v',
        })}>
            <div className="Cut"></div>
        </div>
    )
}
const BannerPreviewStyled = styled(BannerPreview_)`
    position: relative;
    display:flex;
    align-items: center;
    filter: drop-shadow(0px 0px 1px rgba(50,50,0,0.4));

    .Cut{
        background-color: ${p => p.color ? p.color : '#ffffff'};
        padding: 20px;
    }

    &.horizontal {
        transform: rotate(-90deg);
    }
    
    &.vertical {
    }

    &.type02 {
        .Cut {
            clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 100%, 0 0);
        }
    }
    &.type03 {
        .Cut {
            clip-path: polygon(100% 0, 100% 50%, 50% 100%, 0 50%, 0 0);
        }
    }
    &.type04 {
        .Cut {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }

`;
const BannerPreview = () => {
    const orientation = useSelector(s => s.adBuilder.orientation);
    const type = useSelector(s => s.adBuilder.bannerTypeId);
    const color = useSelector(s => s.adBuilder.bannerColor);
    return (
        <BannerPreviewStyled 
            {...{orientation, type, color}} 
        
        />
    )
}




const LogoPreview_ = ({className}) => {
    const id = useSelector(s => s.adBuilder.hoveredLogoId);
    if(!id) return null;
    return (
        <div className={className}>
            <img src={logos[id].img} />
        </div>
    )
}
const LogoPreviewStyled = styled(LogoPreview_)`

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
`;
const LogoPreview = () => {
    
    return (
        <LogoPreviewStyled />
    )
}



const AddTargetUrlButton_ = ({className}) => {
    const dispatch = useDispatch();
    const targetUrl = useSelector(s=>s.adBuilder.targetUrl);
    const title = targetUrl;
    const onClick = e => {
        const targetUrl = window.prompt(`Enter Target URL:`);
        const isUrl = (/^(http|https):\/\/[^ "]+$/).test(targetUrl);
        if(isUrl){
            dispatch(setValues({ targetUrl }));
        }
    }
    return (
        <div {...{className, onClick, title}}>Add Target URL</div>
    )
};
const AddTargetUrlButton = styled(AddTargetUrlButton_)`
    cursor: pointer;
    color: #008dfb;
    font-weight: 600;
    font-size: 12px;
`;




const AdBuilder_ = ({className}) => {
    const dispatch = useDispatch();
    const leftPanelOpen = useSelector(s => s.adBuilder.leftPanelOpen);
    const rightPanelOpen = useSelector(s => s.adBuilder.rightPanelOpen);

    const orientation = useSelector(s => s.adBuilder.orientation);

    const page ="Ad Builder";


    return (
        <div className={classnames("AdBuilder", className, {
            horizontal: orientation==='h',
            vertical: orientation==='v',
        })}>

            <AirshipPreview />
            
                
            <Headroom>
                <Header title={`Ad Builder`} page={page}/>
            </Headroom>

            <MainAthleteBlock />
            


            <div className="AdBuilderContent">
                <div className="container">

                    <AdBuilderContainer>
                        <AdBuilderSection>
                            <ClosableCard onCloseRequest={e=>dispatch(setValues({leftPanelOpen:false}))} closed={!leftPanelOpen}>
                                <LeftCardContent />
                            </ClosableCard>
                        </AdBuilderSection>
                        <AdBuilderSection className="Main">
                            
                            <BuilderCol>

                                <PreviewCard onClick={e=>dispatch(setValues({leftPanelOpen:true}))} className={classnames({
                                    pointer: !leftPanelOpen
                                })}>
                                    <SelectedCraftBodyImg />
                                </PreviewCard>

                                <RangeInput name="craftScale" title="Scale" min={-100} max={100} displayUnit="%" />
                                
                            </BuilderCol>


                            <BuilderCol>
                                <PreviewCard>
                                    <HarnessPreview />
                                </PreviewCard>
                                <RangeInput name="harnessWidth" min={20} max={150} title="Width" displayUnit="px" />

                                <HarnessOrientationSelect />


                                <ColorSelect name="harnessColor" />

                            </BuilderCol>


                            <BuilderCol>
                                <PreviewCard>
                                    <BannerPreview />
                                </PreviewCard>
                                <RangeInput name="bannerHeight" min={120} max={320} title="Height" displayUnit="px" />
                                <RangeInput name="bannerWidth" min={120} max={320} title="Width" displayUnit="px" />


                                <BannerOptions />

                                <ColorSelect name="bannerColor" />
                                
                            </BuilderCol>


                            <BuilderCol>
                                <PreviewCard onClick={e=>dispatch(setValues({rightPanelOpen:true}))} className={classnames({
                                    pointer: !rightPanelOpen
                                })}>
                                    <LogoPreview />
                                </PreviewCard>
                                <RangeInput name="logoScale" min={-50} max={150} step={1} title="Scale" displayUnit="%" />
                                <RangeInput name="logoOffsetY" min={-40} max={40} title="Vertical" displayUnit="px" />
                                <RangeInput name="logoOffsetX" min={-40} max={40} title="Horizontal" displayUnit="px" />

                                
                            </BuilderCol>
                            <BuilderCol>
                                    <ResetButton>
                                        Reset All
                                    </ResetButton>
                            </BuilderCol>
                            <BuilderCol>
                            </BuilderCol>
                            <BuilderCol>
                            </BuilderCol>
                            <BuilderCol>
                                <AddTargetUrlButton />
                            </BuilderCol>
                        </AdBuilderSection>
                        <AdBuilderSection>
                            <ClosableCard onCloseRequest={e=>dispatch(setValues({rightPanelOpen:false}))} closed={!rightPanelOpen}>
                                <RightCardContent/>
                            </ClosableCard>
                        </AdBuilderSection>
                        
                        

                    </AdBuilderContainer>
                
                </div>
            </div>

        {/* <div className="HomePagePublicFooterBackground flexgrow" style={{backgroundImage: `url(/images/city-generic/generic.svg)` }}> </div> */}

        <Footer />

    </div>
    );
};

const AdBuilder = styled(AdBuilder_)`
    overflow: hidden;
    position: relative;

    &.vertical {
      .Airship {
          flex-direction: column;
          .AirshipHarness {
            justify-content: center;
            img {
                transform: rotate(90deg);
            }
          }
          .AirshipBanner {
            flex-direction: column;
          }

      }
    }
`;

export default AdBuilder;




