import React from "react";
import imgFavHover from '../../images/star-1.svg'
import imgFav from '../../images/star-2.svg'
import imgFavDisabled from '../../images/star-4.svg'
// import imgFavFavorited from '../../images/star-3.svg'
import imgFavFavorited from '../../images/star-1.svg'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as favoriteAthletesActions from '../../actions/favoriteAthletes'
import classNames from 'classnames'
import TooltipText from '../Tooltip/TooltipText'

function prevent(e){
    e.preventDefault();
    e.stopPropagation();
}

const FavIconStatic = (props) => {
    return (
        <div className="FavIconStatic"
            onClick={prevent}
            style={{ backgroundImage: `url(${imgFavDisabled})`}} />
    )
}

const FavIconLink = props => {
    const { fbuser, requestsPending, row, raceSelected, favoriteAthletes } = props;
    if(!raceSelected){ return null; }
    // console.log("favoriteAthletes",favoriteAthletes);
    if(!fbuser) {
        return <FavIconStatic />;
    }

    let isFavorite = false;

    if(row && favoriteAthletes[row.RaceCode]){
        //isFavorite = !!Object.keys(fbuserProfile.favoriteAthletes).find( k => row.RaceCode+'|'+row.Bib === k);

        const bibs =  Object.keys(favoriteAthletes[row.RaceCode]);

        // console.log('FAVICIONLINK',  favoriteAthletes[row.RaceCode], row)
        
        isFavorite = bibs.includes(row.Bib+'');
    }
    // favs === [ ['mvm-ma-2018', '33'] ]


    
    return (
        <div className={classNames("FavIconLink", { 
            loading: requestsPending > 0,
            isFavorite
        })} onClick={e=>{
            e.stopPropagation();
            if(requestsPending > 0) { return; }
            console.log("row",row);
            props.favoriteAthletesActions.setFav({ fbuser, EmailHash: row.EmailHash, RaceCode: row.RaceCode, bib: row.Bib, isFavorite: !isFavorite })
        }}>
            
            { isFavorite ? 
                <div
                className="IconSwappable tooltip">
                    <TooltipText>Unfavorite</TooltipText>
                    <div className="icon" style={{ backgroundImage: `url(${imgFavFavorited})`}}/>
                    <div className="hover" style={{ backgroundImage: `url(${imgFav})`}}/>
                        
                </div>
            :
                <div
                //  data-tip="Clear" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDark"
                className="IconSwappable tooltip"><TooltipText>Favorite</TooltipText>
                    <div className="icon" style={{ backgroundImage: `url(${imgFav})`}}/>
                    <div className="hover" style={{ backgroundImage: `url(${imgFavHover})`}}/>
                </div>
            }

            
        </div>
    )
}

const mapState = ({fbuser,fbuserProfile,requestsPending,raceSelected, favoriteAthletes})=>({fbuser,fbuserProfile,requestsPending,raceSelected, favoriteAthletes});
const mapDispatch = dispatch => ({
    favoriteAthletesActions: bindActionCreators(favoriteAthletesActions, dispatch),
})
export default connect(mapState, mapDispatch)(FavIconLink);