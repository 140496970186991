import React from "react";
import firebase from '../../../config/firebase'
import { Link} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { useForm, ErrorMessage } from "react-hook-form";
import steeltoe from 'steeltoe'
import { connect } from 'react-redux'
import Header from "../../../components/Header/Header";
import Footer from '../../../components/Footer/Footer'
import Headroom from 'react-headroom';
import classNames from 'classnames'
import moment from "moment";
import { toast } from 'react-toastify';

import "./RaceNew.scss";

const RaceNew = ({ match, history, settings, racesByOrgId }) => {
    const { register, errors, triggerValidation, handleSubmit, watch } = useForm();

    // const vals = watch(['RaceDateStart', 'EventSeriesCode']);
    // console.log('vals', vals);
    const {RaceDateStart, EventSeriesCode, RaceDistance,RaceSpecialType,RaceTerrainOption, RaceDiscipline} = watch(['RaceDateStart', 'EventSeriesCode', 'RaceDistance','RaceSpecialType','RaceTerrainOption','RaceDiscipline']);
    // const {RaceDateStart, EventSeriesCode} = ['RaceDateStart', 'EventSeriesCode'];

    const RaceDateStartMoment = moment(RaceDateStart);
    const RaceDateStartFormatted = RaceDateStartMoment.format('MMM DD, YYYY HH:mm');
    const RaceDateStartValid = RaceDateStartFormatted.length > 0 && RaceDateStartFormatted !== 'Invalid date';
    const RaceDateStartPreview =  RaceDateStartValid ? RaceDateStartFormatted : 'Type any date format';

    
    
    let RaceCodeDistancePart = '';
    let RaceSpecialTypeCode = '';
    let RaceTerrainOptionCode = '';
    let RaceCodeEventSeriesCodePart = EventSeriesCode ? EventSeriesCode.toLowerCase() : '';
    const RaceYearStart = RaceDateStartValid ? RaceDateStartMoment.format('YYYY'): '';
    
    const RaceCodeEventCodePart = `${RaceCodeEventSeriesCodePart}-${RaceYearStart}`;

    let RaceDistanceOptions = null;
    
    if (RaceDiscipline === 'Run') {
        RaceDistanceOptions = 
        <>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Marathon" ref={register({ required: true })} defaultChecked/>
                Marathon
            </label>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Half Marathon" ref={register({ required: true })}/>
                Half Marathon
            </label>
            {/* <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Half Marathon (Second)" ref={register({ required: true })}/>
                Half Marathon (Second)
            </label> */}
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="10 KM" ref={register({ required: true })}/>
                10 KM
            </label>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="5 KM" ref={register({ required: true })}/>
                5 KM
            </label> 
        </>
    } else if (RaceDiscipline === 'Triathlon') {
        RaceDistanceOptions = 
        <>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Sprint" ref={register({ required: true })} defaultChecked/>
                Sprint
            </label>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Olympic" ref={register({ required: true })}/>
                Olympic
            </label>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Half-iron" ref={register({ required: true })}/>
                Half-iron
            </label>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="Iron" ref={register({ required: true })}/>
                Iron
            </label>
        </>
    } else if (RaceDiscipline === 'Swim') {
        RaceDistanceOptions = 
        <>
            <label><input style={{display:'inline', width: 20}} name="RaceDistance" type="radio" value="1.2 mile" ref={register({ required: true })} defaultChecked/>
                1.2 mile
            </label>
        </>
    } else {
        RaceDistanceOptions =
        <>
            <p>Contact support@myrace.com</p>
        </>
    }
        

    let FemaleWRValue = 999999;
    let MaleWRValue = 999999;

    switch (RaceSpecialType) {
        case 'ADA': 
            RaceSpecialTypeCode='ada-';
            break;
        case 'Push-Rim':
            RaceSpecialTypeCode='pushrim-';
            break;
        default:
            RaceSpecialTypeCode='';
            break;
    }

    switch (RaceTerrainOption) {
        case '': 
            RaceTerrainOptionCode='';
            break;
        case 'Off Road':
            RaceTerrainOptionCode='offroad-';
            break;
        case 'On and Off Road':
            RaceTerrainOptionCode='onandoffroad-';
            break;
        default:
            RaceTerrainOptionCode='';
            break;
    }

    switch (RaceDistance) {
        case 'Marathon':
            RaceCodeDistancePart='ma';
            FemaleWRValue = 2*60*60+14*60+4; //2:14:04
            MaleWRValue = 2*60*60+2*60+57; //2:02:57
            break;
        case 'Half Marathon':
            RaceCodeDistancePart='half';
            FemaleWRValue = 1*60*60+4*60+31; //1:04:31
            MaleWRValue = 0*60*60+58*60+1; //0:58:01
            break;
        case 'Half Marathon (Second)':
            RaceCodeDistancePart='secondhalf';
            FemaleWRValue = 1*60*60+4*60+31; //1:04:31
            MaleWRValue = 0*60*60+58*60+1; //0:58:01
            break;
        case '5 KM':
            RaceCodeDistancePart='fivekm';
            FemaleWRValue = 0*60*60+14*60+11; //0:14:11
            MaleWRValue = 0*60*60+12*60+37; //0:12:37
            break;
        case '10 KM':
            RaceCodeDistancePart='tenkm';
            FemaleWRValue = 0*60*60+29*60+17; //0:29:17
            MaleWRValue = 0*60*60+26*60+17; //0:26:17
            break;
        case 'Sprint':
            RaceCodeDistancePart='spr';
            FemaleWRValue = 0*60*60+55*60+30; //0:55:30
            MaleWRValue = 0*60*60+51*60+15; //0:51:15
            break;
        case 'Olympic':
            RaceCodeDistancePart='oly';
            FemaleWRValue = 1*60*60+52*60+12; //1:52:12
            MaleWRValue = 1*60*60+39*60+50; //1:39:50
            break;
        case 'Half-iron':
            RaceCodeDistancePart='halfiron';
            FemaleWRValue = 3*60*60+55*60+50; //3:55:50
            MaleWRValue = 3*60*60+29*60+4; //1:29:04
            break;
        case 'iron':
            RaceCodeDistancePart='fulliron';
            FemaleWRValue = 8*60*60+18*60+13; //8:18:13
            MaleWRValue = 7*60*60+35*60+39; //7:35:39
            break;
        default:
            RaceCodeDistancePart='unk';
            break;

    }

    let RaceCode = '';
    if (RaceCodeEventSeriesCodePart && RaceCodeDistancePart && RaceYearStart) {
        
        RaceCode = `${RaceCodeEventSeriesCodePart}-${RaceCodeDistancePart}-${RaceSpecialTypeCode}${RaceYearStart}`;
    }
    if (RaceCodeEventSeriesCodePart && RaceCodeDistancePart && RaceYearStart) {
        
        RaceCode = `${RaceCodeEventSeriesCodePart}-${RaceCodeDistancePart}-${RaceSpecialTypeCode}${RaceTerrainOptionCode}${RaceYearStart}`;
    }
    

    const orgId = settings.currentOrgId;

    const onSubmit = data => {
        console.log('submitted data:', data);
        // return;

        const updateData = {
            EventCode: RaceCodeEventCodePart,
            EventSeriesCode: data.EventSeriesCode.toLowerCase(),
            RaceDateStart: data.RaceDateStart,
            RaceCode,
            RaceDistance: data.RaceDistance,
            RaceSpecialType: data.RaceSpecialType,
            RaceTerrainOption: data.RaceTerrainOption,
            RaceDiscipline: data.RaceDiscipline,
            EventName: data.EventName,
            RaceName: data.RaceName,
            RaceWebsiteURL: data.RaceWebsiteURL,
            AdminStatus: data.AdminStatus,
            Location: data.Location,
            CountryTwoCharCode: data.CountryTwoCharCode,
            DefaultRaceCodeForEvent: (data.DefaultRaceCodeForEvent.toLowerCase() === 'true'),
            ShowHalfSplits: (data.ShowHalfSplits.toLowerCase() === 'true'),
            EventListOrderIndex: parseInt(data.EventListOrderIndex),
            FemaleWRFinishTime: parseInt(data.FemaleWRFinishTime),
            MaleWRFinishTime: parseInt(data.MaleWRFinishTime),
            PhotoPartnerURL: data.PhotoPartnerURL,
            RaceAthleteCategories: data.RaceAthleteCategories,
            RaceDivisionType: parseInt(data.RaceDivisionType),
            RaceLabelForFilter: data.RaceLabelForFilter,
            RaceLandscapeImagePath: data.RaceLandscapeImagePath
        }
        
        const funcUpdateRace = firebase.functions().httpsCallable('updateRace');
        funcUpdateRace({ orgId, updateData }).then(result=>{
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // console.log('updateData:', updateData);
            // console.log('funcUpdateRace result:', result.data.data);
            
            history ? history.push(`/races-admin/edit/${RaceCode}`) : console.log('history missing...');            
            toast("New Race Saved!");

            // toast.info('Race Data Saved!',{
            //     className: 'black-background',
            //     autoClose: 8000
            // });
            // dispatch({ type: SET_DATA, racecode, data: result.data.data  });
        }).catch(e => {
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.warn('error making request in funcUpdateRace',e)
        });
        
    }
    

    // const races = steeltoe(racesByOrgId).get(`${orgId}.data`);
    
    const page = "Race New";



    
    return (

        <div className="SelectRace">
            
            
            <Headroom>
                <Header title={`Select Race`} page={page}/>
            </Headroom>


            <div className="RaceNewContent">
                <div className="container">

                    {/* <h1>Race Edit: {match.params.racecode}</h1> */}
                    <Link to={`/races-admin`}>
                        Back to all Races</Link>
                    <h3>New Race</h3>

                    
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceDateStart.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceDateStart.message')
                                    })}>
                            <label> 
                                <span className="label">Race Start Date/Time (local)</span>
                                <input name="RaceDateStart" type="text" 
                                    placeholder={ 'Race Start Date.Time...'}
                                    onChange={(e)=>{
                                        triggerValidation('RaceDateStart');
                                    }}
                                    // defaultValue={race.RaceName}
                                    ref={register({
                                        validate: value => value ? true : 'no Race Start Date'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                
                            </label>
                        </div>
                        <p className="RaceDateStartPreview">{RaceDateStartPreview}</p>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('EventSeriesCode.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('EventSeriesCode.message')
                                    })}>
                            <label> 
                                <span className="label">Event Series Code (i.e. CIM, TSFM)</span>
                                <input name="EventSeriesCode" type="text" 
                                    placeholder={ 'Event Series Code...'}
                                //    value={race.EventSeriesCode}
                                   ref={register({
                                        validate: value => value ? (!value.includes('-') ? true : 'No hyphens are allowed') : 'Event Series Code is a mandatory field',
                                        // validate: value => !value.includes('-') ? true : 'no hyphens allowed'
                                    })}
                                />
                                

                                <ErrorMessage errors={errors} name="EventSeriesCode">
                                    {({ message }) => <p>{message}</p>}
                                </ErrorMessage>                                 
                            </label>

                        </div>

                        <div className={classNames("control", {
                                    disabled: true
                                    })}>
                            <label> 
                                <span className="label">Event Code (CIM-2019</span>
                                <input name="EventCode" type="text" 
                                    disabled
                                    // value={race.EventCode}
                                    value={RaceCodeEventCodePart}
                                    ref={register({
                                        validate: value => value ? true : 'no Event Code'
                                    })}
                                    
                                />

                                
                            </label>
                        </div>


                        <div className={classNames("control")}>

                                <span className="label">Race Discipline Type</span>
                                
                                <label><input style={{display:'inline', width: 20}} name="RaceDiscipline" type="radio" value="Run" ref={register({ required: true })} defaultChecked/>
                                    Run
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDiscipline" type="radio" value="Triathlon" ref={register({ required: true })}/>
                                    Triathlon
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDiscipline" type="radio" value="Aqua-bike" ref={register({ required: true })}/>
                                    Aqua-bike
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDiscipline" type="radio" value="Swim" ref={register({ required: true })}/>
                                    Swim
                                </label>

                                <ErrorMessage errors={errors} name="RaceDiscipline">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>

                                

                        </div>

       

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceDistance.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceDistance.message')
                                    })}>

                                <span className="label">Race Distance</span>
                                {/* <input name="RaceDistance" type="text" 
                                //    value={race.EventSeriesCode}
                                //    disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Distance'
                                    })}
                                /> */}
                                
                                {RaceDistanceOptions}

                                <ErrorMessage errors={errors} name="RaceDistance">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>

                                

                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceSpecialType.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceSpecialType.message')
                                    })}>

                                <span className="label">Special Race Type (Push-Rim, ADA)</span>
                                {/* <input name="RaceDistance" type="text" 
                                //    value={race.EventSeriesCode}
                                //    disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Distance'
                                    })}
                                /> */}
                                
                                <label><input style={{display:'inline', width: 20}} name="RaceSpecialType" type="radio" value="" ref={register({ required: true })} defaultChecked/>
                                    Neither
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceSpecialType" type="radio" value="Push-Rim" ref={register({ required: true })}/>
                                    Push-Rim
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceSpecialType" type="radio" value="ADA" ref={register({ required: true })}/>
                                    ADA
                                </label>

                                <ErrorMessage errors={errors} name="RaceSpecialType">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
      

                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceTerrainOption.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceTerrainOption.message')
                                    })}>

                                <span className="label">Race Terrain</span>
                                {/* <input name="RaceDistance" type="text" 
                                //    value={race.EventSeriesCode}
                                //    disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Distance'
                                    })}
                                /> */}
                                
                                <label><input style={{display:'inline', width: 20}} name="RaceTerrainOption" type="radio" value="On Road" ref={register({ required: true })} defaultChecked/>
                                    On Road
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceTerrainOption" type="radio" value="Off Road" ref={register({ required: true })}/>
                                    Off Road
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceTerrainOption" type="radio" value="On and Off Road" ref={register({ required: true })}/>
                                    On and Off Road
                                </label>

                                <ErrorMessage errors={errors} name="RaceTerrainOption">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
      

                        </div>

                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">Race Code (cim-ma-2019)</span>
                                <input name="RaceCode" type="text" 
                                //    value={race.RaceCode}
                                    value={RaceCode}
                                   disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Code'
                                    })}
                                />
                                
                                
                            </label>
                        </div>

                        
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('EventName.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('EventName.message')
                                    })}>
                            <label> 
                                <span className="label">Event Name</span>
                                <input name="EventName" type="text" 
                                    placeholder={ 'EventName...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    // defaultValue={race.EventName}
                                    ref={register({
                                        validate: value => value ? true : 'no Event Name'
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="EventName">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceWebsiteURL.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceWebsiteURL.message')
                                    })}>
                            <label> 
                                <span className="label">Website URL</span>
                                <input name="RaceWebsiteURL" type="text" 
                                    placeholder={ 'Website URL...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    // defaultValue={race.RaceWebsiteURL}
                                    ref={register({
                                        validate: value => value ? true : 'no Website URL'
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceWebsiteURL">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceName.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceName.message')
                                    })}>
                            <label> 
                                <span className="label">Race Name</span>
                                <input name="RaceName" type="text" 
                                    placeholder={ 'RaceName...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    // defaultValue={race.RaceName}
                                    ref={register({
                                        validate: value => value ? true : 'no Race Name'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceName">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('AdminStatus.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('AdminStatus.message')
                                    })}>

                                <span className="label">Publish Status</span>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Draft" ref={register({ required: true })} defaultChecked disabled/>
                                Draft
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Private" ref={register({ required: true })}
                                disabled />
                                Private
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Public" ref={register({ required: true })}
                                disabled />
                                Public
                                </label>
                                {/* <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Archived" ref={register({ required: true })}
                                disabled />
                                Archived
                                </label> */}
                                
                                <ErrorMessage errors={errors} name="AdminStatus">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>



                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('Location.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('Location.message')
                                    })}>
                            <label> 
                                <span className="label">Location</span>
                                <input name="Location" type="text" 
                                    placeholder={ 'Location...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    // defaultValue={race.Location}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="Location">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('CountryTwoCharCode.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('CountryTwoCharCode.message')
                                    })}>
                            <label> 
                                <span className="label">CountryTwoCharCode</span>
                                <input name="CountryTwoCharCode" type="text" 
                                    placeholder={ 'CountryTwoCharCode...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="CountryTwoCharCode">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>


                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('DefaultRaceCodeForEvent.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('DefaultRaceCodeForEvent.message')
                                    })}>

                                <span className="label">Default Race for Event</span>
                                <label><input style={{display:'inline', width: 20}} name="DefaultRaceCodeForEvent" type="radio" value="true" ref={register({ required: true })} defaultChecked/>
                                True
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="DefaultRaceCodeForEvent" type="radio" value="false" ref={register({ required: true })}
                                 />
                                False
                                </label>
                                
                                
                                <ErrorMessage errors={errors} name="DefaultRaceCodeForEvent">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('ShowHalfSplits.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('ShowHalfSplits.message')
                                    })}>

                                <span className="label">Show Half Splits</span>
                                <label><input style={{display:'inline', width: 20}} name="ShowHalfSplits" type="radio" value="true" ref={register({ required: true })} defaultChecked/>
                                True
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="ShowHalfSplits" type="radio" value="false" ref={register({ required: true })}
                                 />
                                False
                                </label>
                                
                                
                                <ErrorMessage errors={errors} name="ShowHalfSplits">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('EventListOrderIndex.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('EventListOrderIndex.message')
                                    })}>
                            <label> 
                                <span className="label">EventListOrderIndex</span>
                                <input name="EventListOrderIndex" type="text" 
                                    placeholder={ 'EventListOrderIndex...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="EventListOrderIndex">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">FemaleWRFinishTime</span>
                                <input name="FemaleWRFinishTime" type="text" 
                                    placeholder={ 'FemaleWRFinishTime...'}
                                    disabled
                                    value={FemaleWRValue}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
    
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="FemaleWRFinishTime">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">MaleWRFinishTime</span>
                                <input name="MaleWRFinishTime" type="text" 
                                    placeholder={ 'MaleWRFinishTime...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    disabled
                                    value={MaleWRValue}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
    
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="MaleWRFinishTime">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('PhotoPartnerURL.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('PhotoPartnerURL.message')
                                    })}>
                            <label> 
                                <span className="label">PhotoPartnerURL</span>
                                <input name="PhotoPartnerURL" type="text" 
                                    placeholder={ 'PhotoPartnerURL...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    ref={register({
                                        // validate: value => value ? true : 'no value'
                                        validate: true
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="PhotoPartnerURL">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceAthleteCategories.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceAthleteCategories.message')
                                    })}>
                            <label> 
                                <span className="label">RaceAthleteCategories (AG, MA, VI, EL)</span>
                                <input name="RaceAthleteCategories" type="text" 
                                    placeholder={ 'RaceAthleteCategories...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceAthleteCategories">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        {/* <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceDivisionType.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceDivisionType.message')
                                    })}>
                            <label> 
                                <span className="label">RaceDivisionType</span>
                                <input name="RaceDivisionType" type="text" 
                                    placeholder={ 'RaceDivisionType...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceDivisionType">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div> */}


                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceDivisionType.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceDivisionType.message')
                                    })}>

                                <span className="label">RaceDivisionType</span>
                                <label><input style={{display:'inline', width: 20}} name="RaceDivisionType" type="radio" value="1" ref={register({ required: true })} defaultChecked disabled/>
                                (1) M/F 01-19, 20-24, 25-29, 30-34, 35-39, 40-44, 45-49, 50-54, 55-59, 60-64, 65-69, 70-74, 75-79, 80-84, 85-89, 90-94, 95-99, 100+
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDivisionType" type="radio" value="2" ref={register({ required: true })}
                                 />
                                (2) M/F 01-17, 18-39, 40-44, 45-49, 50-54, 55-59, 60-64, 65-69, 70-74, 75-79, 80+
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDivisionType" type="radio" value="3" ref={register({ required: true })}
                                 />
                                (3) M/F 01-19, 20-24, 25-29, 30-34, 35-39, 40-44, 45-49, 50-54, 55-59, 60-64, 65-69, 70-74, 75-79, 80+
                                </label>
                                
                                
                                <ErrorMessage errors={errors} name="RaceDivisionType">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceLabelForFilter.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceLabelForFilter.message')
                                    })}>
                            <label> 
                                <span className="label">RaceLabelForFilter</span>
                                <input name="RaceLabelForFilter" type="text" 
                                    placeholder={ 'RaceLabelForFilter...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceLabelForFilter">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
             


                        <div className="buttons">
                            <button value="continue" type="submit">Save</button>
                        </div>
                        
                    </form>
                  
                </div>
            </div>

            <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>

    )
}


const mapState = ({settings, racesByOrgId})=>({settings, racesByOrgId});

export default withRouter(connect(mapState)(RaceNew));