import {auth, db} from '../config/firebase'

import {SET_LOGS} from '../reducers/logsReducer'

export const setLogs = logs => ({ type:SET_LOGS, logs });

export const requestLogs = (isSuperAdmin, eventSeriesList) => {
    return dispatch => {
        // console.log('[requestLogs]',eventSeriesList)

      if(auth.currentUser && !auth.currentUser.isAnonymous){


        // ******************************
        // FUTURE: WE MAY WANT TO BUCKET THE EVENTSERIESCODES 
        // IN CASE THERE ARE MORE THAN 10
        // FIRESTORE CURRENTLY DOES NOT ALLOW MORE THAN 10 INCLUDES IN SINGLE CALL
        // THE PROBLEM THOUGH IS THEN WE NEED TO SORT AND REVERSE TO 

        // const eventSeriesList=['cim','mauiom'];
        // const pagesList=['welcome','results-summary'];
  
        // db.collection('logs').orderBy('created','desc').get().then(snap=>{
        // db.collection('logs').orderBy('created','desc').limit(2000).get().then(snap=>{
     
        // db.collection('logs').where('payload.pageName','in',evenpagesListtSeriesList).orderBy('created','desc').get().then(snap=>{
          
          // let seriesLists = []; // buckets of series codes split by 10

          // let currBucketIndex = 0;
          // eventSeriesList.forEach((seriesCode, i)=>{
          //   if(!seriesLists[currBucketIndex]) seriesLists[currBucketIndex] = [];
          //   let bucket = seriesLists[currBucketIndex];

          //   bucket.push(seriesCode);
            
            
          //   if(bucket.length > 9) {
          //     currBucketIndex++;
          //   }
          // })

          // seriesLists.forEach(eventSeriesList => {
            let query = db.collection('logs').orderBy('created','desc');
          // console.log("isSuperAdmin",isSuperAdmin)
            if (!isSuperAdmin) {
              query = query.where('payload.eventSeries','in',eventSeriesList).where('type','==','page_view')
            }
              
            // db.collection('logs').where('payload.eventSeries','in',eventSeriesList).orderBy('created','desc').get().then(snap=>{
            query.get().then(snap=>{
              let logs = [];
              snap.docs.forEach( doc => {
                logs.push(doc.data());
              });
              logs.reverse();
              dispatch(setLogs(logs));
              // console.log('GOT LOGS:', logs.length)
            // dispatch({ type: SET_LOGS, logs });
            }).catch(err=>console.log(err));
            

          // });
        }



    }
}