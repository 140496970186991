import React, { Component } from "react";
import "./RaceListFilters.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
import TooltipText from '../Tooltip/TooltipText'
import { getSelectedRace, getSeriesRaces } from "../../re-selectors/getSelectedRace";

class RaceListFilters extends Component {


  componentDidMount(){

  }

  openRace = race => e => {
    if(this.props.requestsPending){ return; }
    this.props.history.push(`/select-athlete/${race.EventCode}/${race.RaceCode}`)
    // console.log("race",race);
    this.props.setSelectedRace(race);
    
  }

  render() {
    const { raceEvents, raceSelected } = this.props;
    // console.log("xxx races",races);

    let races = raceSelected && raceEvents[raceSelected.EventCode] && raceEvents[raceSelected.EventCode].races;

    // console.log('RaceListFilter races:', races)
    
    if(!races) return null;
    
    
    
    races.sort((a,b) => {// number sorter
      // console.log('SORT NUMBERS',colId,  direction, a[colId], b[colId])
      if(a.EventListOrderIndex < b.EventListOrderIndex) return -1;
      if(a.EventListOrderIndex > b.EventListOrderIndex) return 1;
      return 0;
    })
    // console.log('RaceListFilter races after:', races)
    
    return (
      <div className="RaceListFilters">
        
        <div className="line"/>
        {/* <ReactTooltip /> */}
        {/* { races && races.slice(0).reverse().map( race => ( */}
        { races && races.map( race => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={race.RaceCode} onClick={this.openRace(race)} 
            className={classnames("button", 
              {
                selected: (raceSelected && race.RaceCode === raceSelected.RaceCode)
              },
              {
                tooltip: (!raceSelected || race.RaceCode !== raceSelected.RaceCode)
              })}>
            <TooltipText>{ !raceSelected || race.RaceCode !== raceSelected.RaceCode ? 'Select Race' : null}</TooltipText>{race.RaceLabelForFilter}
          </div>
        ))}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  const { requestsPending } = state;

  return { requestsPending,
    raceSelected: getSelectedRace(state),
    raceEvents: getSeriesRaces(state),

  }};

const mapDispatchToProps = dispatch => ({
  setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceListFilters));

