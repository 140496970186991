// import firebase from 'firebase/app';
import steeltoe from 'steeltoe'
// import mongodb from '../apis/mongo'
import mongoapi from '../apis/mongoapi'

import {SET_STATUS, SET_DATA, SET_TOTAL_ITEMS} from '../reducers/athletesByFilterReducer'
import {getFilterKey} from '../re-selectors/athletesByFilterSelectors'

export const getAthletesByFilter = () => {
  return (dispatch, getState) => {
    
    // console.log('[getAthletesByFilter] ACTION')

      /* 
      TODO
        - get current filter described in raceAthleteFilter
        - generate the FILTERKEY based on the current raceAthleteFilter
        - check if we have data for this filterkey-page in athletesByFilter (this reducer)
        - if we don't have the data - make the request for the key-page
        - no need to do anything if we already have the data

        - athletesByFilter[FILTERKEY].totalItems - maybe check this here too (or maybe in pagination action), and request totalItems from mongodb
      */
  
      const { athletesByFilter, raceAthleteFilter, 
        // raceSelected, 
        settings,
        fbuser, favoriteAthletes } = getState();

        const { selectedRaceCode: RaceCode } = settings;
      /* 
        page: 1
        perPage: 50
        overallFilter: "All-Genders"
        divisionFilter: "All-DivisionType1s"
        locationFilter: "All-Locations"
        splitTypeFilter: "All-Splits"
      */

      // console.log('[athletesByFilter] raceSelected', raceSelected && raceSelected.RaceCode, raceSelected)
      // console.log('[athletesByFilter] athletesByFilter',athletesByFilter)
      if(!RaceCode
        //!raceSelected || !raceSelected.RaceCode
        ) {
        return;
      }
      // console.log('[athletesByFilter] RaceCode:',raceSelected.RaceCode)


      const { perPage, overallFilter, divisionFilter, locationFilter, splitTypeFilter, page, sorterCol, sorterColDir, onlyFavorite } = raceAthleteFilter;

      
    //   const {RaceCode} = raceSelected;
      
      const FILTERKEY = getFilterKey({
        RaceCode,
        perPage,
        overallFilter,
        divisionFilter,
        locationFilter,
        splitTypeFilter,
        sorterCol,
        sorterColDir,
        onlyFavorite, 
        fbuser
      });

      /* 
        raceAthleteFilter[FILTERKEY].pages[page]
      */
      // if(raceAthleteFilter && raceAthleteFilter[FILTERKEY].pages[page]){
      // https://github.com/jclem/steeltoe 
      if(steeltoe(athletesByFilter).get(`${FILTERKEY}.pages.${page}`)){
        // console.log("[athletesByFilter] we're already either requesting or got the data for", FILTERKEY)
        return; // we're already either requesting or got the data
      } else {
        // console.log("[athletesByFilter] we've got nothing yet")
      }

      let mongoIds;
      if(onlyFavorite && fbuser) {
        mongoIds = favoriteAthletes && favoriteAthletes[RaceCode] ? favoriteAthletes[RaceCode].map( Bib => `${RaceCode}|${Bib}`) : null;

        if(!mongoIds || !mongoIds.length) {
          // console.log('STOPPING request mongo for empty set of fav athletes')

          dispatch({
            type: SET_DATA,
            slug: FILTERKEY,
            pagenum: 1,
            status: 'received',
            data: []
          })

          dispatch({
            type: SET_TOTAL_ITEMS,
            slug: FILTERKEY,
            totalItems: 0,
            totalPages: 1
          })
          
          return;
        }
      }
      
      dispatch({ type: SET_STATUS, 
        slug: FILTERKEY,
        pagenum: page,
        status: 'pending'
      })


      if(onlyFavorite && fbuser) {
        mongoapi.run( mongodb=>{
          const coll = mongodb.collection("athletes-limited");

          // const raceCodes = Object.keys(favoriteAthletes);
          // const mongoIds = raceCodes.reduce((mongoIds, RaceCode) => {
          //   return [
          //     ...mongoIds,
          //     ...favoriteAthletes[RaceCode].map( Bib => `${RaceCode}|${Bib}`)
          //   ]
          // }, [])

          // if (!mongoIds) {
          //   mongoIds = [];
          // }




          // console.log("sorterCol", sorterCol);
          var sort = {};
          sort[sorterCol] = sorterColDir === "up" ? -1 : 1;
          sort["IndexFinish"] = 1;
          // console.log("sort",sort);
  
          const pipeline = [
            // { $match: filter },
            { $match: { "_id": { "$in": mongoIds } } },
            { "$sort": sort },
            // { "$sort": {"IndexFinish":1} },
            // { "$sort": { $concat: [ "$FirstName", "$LastName" ] } },
            // { "$skip" : (page-1) * perPage },
            // { "$limit" : perPage },
          ];
          
          // console.log("pipeline", pipeline);
          // db.aggregationSortDemo.aggregate( {$group: {_id: '$StudentId',"TotalOccurrences": {$sum: 1}}}, {$sort: {_id: -1}} ).pretty();
  
          dispatch({type:'INCREMENT_PENDING_REQUESTS'});
          // console.log("[athletesByFilter] MAKING THE ATHLETES MONGODB CALL!", filter)
          coll.aggregate(pipeline).toArray()
              .then(athletes => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'})
                  // console.log('[athletesByFilter] Got athletes aggr:', athletes);
                // this.setState({athletes});
                dispatch({
                  type: SET_DATA,
                  slug: FILTERKEY,
                  pagenum: page,
                  status: 'received',
                  data: athletes
                })

                dispatch({
                  type: SET_TOTAL_ITEMS,
                  slug: FILTERKEY,
                  totalItems: mongoIds.length,
                  totalPages: 1
                  // totalPages: Math.ceil(mongoIds.length / perPage)
                })
              
          });
  
          
          
          
        }) // mongodb api


      // if - only Favorite
      } else {

        
        mongoapi.run( mongodb=>{
          const coll = mongodb.collection("athletes-limited");
          
          let filter = {
          };
  
          filter.RaceCode = RaceCode;
  
  
          if(['M','F'].includes(overallFilter)){
            filter.Gender = overallFilter;
          }
  
      
          if(divisionFilter !== 'All-DivisionType1s'){
            filter.DivisionType1 = divisionFilter;
          }
  
          // if(divisionFilter !== 'All-DivisionType2s'){
          //   filter.DivisionType2s = divisionFilter;
          // }
          
          // if(!['All-DivisionType1s','All-DivisionType2s'].includes(divisionFilter)){
          //   // filter.DivisionType2s = divisionFilter;
          //   if(divisionFilter === 'All-DivisionType1s'){
          //     filter.DivisionType1s = 'All-DivisionType1s';
          //   } 
          // }
  
          if(locationFilter !== 'All-Locations' && locationFilter !== '**'){
            const [ city, stateCode, countryCode ] = locationFilter.split('*');
            // console.log("city,stateCode,countryCode",city,stateCode,countryCode);
            // filter.CountryTwoCharCode = countryCode;
            if (stateCode.length>0){
  
              filter.StateCode = stateCode;
            }
            if (countryCode.length>0){
  
              filter.CountryThreeCharCode = countryCode;
            }
          
          }
  
  
          // console.log("sorterCol", sorterCol);
          var sort = {};
          sort[sorterCol] = sorterColDir === "up" ? -1 : 1;
          sort["IndexFinish"] = 1;
          // console.log("sort",sort);
  
          const pipeline = [
            { $match: filter },
            { "$sort": sort },
            // { "$sort": {"IndexFinish":1} },
            // { "$sort": { $concat: [ "$FirstName", "$LastName" ] } },
            { "$skip" : (page-1) * perPage },
            { "$limit" : perPage },
          ];
          
          // console.log("pipeline", pipeline);
          // db.aggregationSortDemo.aggregate( {$group: {_id: '$StudentId',"TotalOccurrences": {$sum: 1}}}, {$sort: {_id: -1}} ).pretty();
  
          dispatch({type:'INCREMENT_PENDING_REQUESTS'});
          // console.log("[athletesByFilter] MAKING THE ATHLETES MONGODB CALL!", filter)
          coll.aggregate(pipeline).toArray()
              .then(athletes => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'})
                  // console.log('[athletesByFilter] Got athletes aggr:', athletes);
                // this.setState({athletes});
                dispatch({
                  type: SET_DATA,
                  slug: FILTERKEY,
                  pagenum: page,
                  status: 'received',
                  data: athletes
                })
              
          });
  
  
  
          // - athletesByFilter[FILTERKEY].totalItems - maybe check this here too (or maybe in pagination action), and request totalItems from mongodb
          // totalItems - undefined - need to make the request
          // totalItems - "pending" - string - 
          // totalItems - 0 - number
          // totalItems - 1234 - number
  
  
          // getting number of results
  
          const pipeline2 = [
              { $match: filter },
              { "$count": "IndexFinish" },
          ];
  
  
          if( typeof steeltoe(athletesByFilter).get(`${FILTERKEY}.totalItems`) === 'undefined' ){
            dispatch({type:'INCREMENT_PENDING_REQUESTS'});
            dispatch({
              type: SET_TOTAL_ITEMS,
              slug: FILTERKEY,
              totalItems: 'pending',
            });
            // console.log('[athletesByFilter] MAKING PAGINATION MONGODB CALL!')
            coll.aggregate(pipeline2).toArray()
                .then(results => {
                  dispatch({type:'DECREMENT_PENDING_REQUESTS'})
                  // console.log('Number of results:', results);
  
                  const totalItems = steeltoe(results).get(`0.IndexFinish`);
                  dispatch({
                    type: SET_TOTAL_ITEMS,
                    slug: FILTERKEY,
                    totalItems,
                    totalPages: Math.ceil(totalItems / perPage)
                  })
                    
            });
          
          }
          
  
        });// mongoapi
      } // else - not "only favorite"











    }
}
  