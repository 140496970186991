import React, { Component } from "react";
// import "./RaceFilters.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
// import TooltipText from '../Tooltip/TooltipText'

class RaceEventSeriesCodeFilters extends Component {


  componentDidMount(){
    if(this.props.races) {
      this.enableAllRacesOnMount();
    }
  }
  
  componentDidUpdate(prevProps){
    if(!prevProps.races && this.props.races){
      this.enableAllRacesOnMount();
    }
  }

  enableAllRacesOnMount = () => {
    this.props.setRaceEventSeriesCodeFilterOption(this.getEventSeriesCodes());
  }

  getEventSeriesCodes = () => {
    return this.props.races.reduce((seriesCodes, race) => {
      return seriesCodes.includes(race.EventSeriesCode) ? seriesCodes : [...seriesCodes, race.EventSeriesCode];
    }, []);
  }

  selectFilterOption = filterOption => e => {
    // if(this.props.requestsPending){ return; }
    const { raceEventSeriesCodeFilterOption } = this.props.settings;

    const clone = raceEventSeriesCodeFilterOption.slice(0);
    const index = clone.indexOf(filterOption);

    if (index > -1) {
        clone.splice(index, 1);
    } else {
        clone.push(filterOption);
    }
    this.props.setRaceEventSeriesCodeFilterOption(clone)
  }

  render() {
    const { races } = this.props;
    const { raceEventSeriesCodeFilterOption } = this.props.settings;

    const raceOptionFilters =  races ? this.getEventSeriesCodes(): [];// ['TSFM','BHM'];
    
    return (
      <div className="RaceStatusFilters">
        
        <div className="line"/>

        { raceOptionFilters.map( filterOption => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={filterOption} onClick={this.selectFilterOption(filterOption)} 
            className={classnames("button", { selected: raceEventSeriesCodeFilterOption.includes(filterOption)})}>

            {filterOption} 
          </div>
        ))}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = ({ settings, requestsPending }) => ({ settings,requestsPending })

const mapDispatchToProps = dispatch => ({
//   setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE_FILTER_STATUS', race}),
  setRaceEventSeriesCodeFilterOption: raceEventSeriesCodeFilterOption => dispatch({type:'SET_SETTING', raceEventSeriesCodeFilterOption}),
 
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceEventSeriesCodeFilters));

