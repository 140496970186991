import React, { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from 'classnames'

// import { optionCheckboxes } from './Step3'
// const optionCheckboxesThatNeedMapping = optionCheckboxes.filter(o => !!o.mayNeedMapping);

export const getCheckpoints = (timingDataset, discipline='run') => {
    if (discipline === 'swim') {
        // console.log("getCheckpoints SWIM timingDataset",timingDataset)
        
        const savedCheckpointKeys = Object.keys(timingDataset).filter(key=>key.startsWith('checkpointswim_'));
        // console.log("getCheckpoints SWIM savedCheckpointKeys",savedCheckpointKeys)
        savedCheckpointKeys.sort((keyA, keyB)=>{
            const a = +keyA.split('_')[1];
            const b = +keyB.split('_')[1];
            return a - b;
        });
    
    
        return savedCheckpointKeys.map(key => timingDataset[key]);
    } else if (discipline === 'bike') {

        const savedCheckpointKeys = Object.keys(timingDataset).filter(key=>key.startsWith('checkpointbike_'));
        savedCheckpointKeys.sort((keyA, keyB)=>{
            const a = +keyA.split('_')[1];
            const b = +keyB.split('_')[1];
            return a - b;
        });
    
    
        return savedCheckpointKeys.map(key => timingDataset[key]);
    } else if (discipline === 'run') {

        const savedCheckpointKeys = Object.keys(timingDataset).filter(key=>key.startsWith('checkpointrun_'));
        savedCheckpointKeys.sort((keyA, keyB)=>{
            const a = +keyA.split('_')[1];
            const b = +keyB.split('_')[1];
            return a - b;
        });
    
    
        return savedCheckpointKeys.map(key => timingDataset[key]);
    }
}




const Step4 = ({race, goToNextStep, goToPrevStep, timingDataset, currentTimingDatasetId, update, step}) => {
    const { handleSubmit } = useForm();
    const [ checkpointsSwim, setCheckpointsSwim] = useState([]);
    const [ checkpointsBike, setCheckpointsBike] = useState([]);
    const [ checkpointsRun, setCheckpointsRun] = useState([]);
    const [ errors, setErrors ] = useState([]);

    const savedCheckpointsSwim = getCheckpoints(timingDataset,'swim');
    const savedCheckpointsBike = getCheckpoints(timingDataset,'bike');
    const savedCheckpointsRun = getCheckpoints(timingDataset,'run');



    // return savedCheckpointsRun.map( item => {
    // savedCheckpoints.map( key => {
    //     let itemClone = savedCheckpoints[key].replace('_','.');
    //     console.log("itemClone=",itemClone);
    //     // itemClone.value
    //     return itemClone;
    // })
    // console.log("savedCheckpointKeys",savedCheckpointKeys)
    

    const validateForm = () => {
        const errors = savedCheckpointsRun.map((value,i) => {
            let error = null;

            if(typeof value !== 'string'){
                error = { message: 'not a string'};
                console.log(error);
                return error;
            }
            if(value.toLowerCase()!=="half"){

                if( !(/^\d*(\.)*\d*\s(mi|km)$/g).test(value) ){
                    error = { message: 'Incorrect checkpoint value' }
                }
    
                if( value.startsWith('0 ') ){
                    error = { message: 'Cannot start with 0'};
                }
            }

            
            return error;
        });
        setErrors(errors);
        return !!errors.find(error => !!error)
    }
    
    const onSubmit = () => {
        // console.log('STEP - save savedCheckpointsSwim:', checkpointsSwim);
        // console.log('STEP - save savedCheckpointsBike:', checkpointsBike);
        // console.log('STEP - save savedCheckpointsRun:', checkpointsRun);

        const formHasError = validateForm();

        if(formHasError){
            return;
        }
        

        let upd = {};
        checkpointsSwim.forEach((ch,i) => {
            upd[`checkpointswim_${i+1}`] = 'Swim' + ch.replace('.','_');
        })
        // console.log("checkpointsSwim",checkpointsSwim)

        checkpointsBike.forEach((ch,i) => {
            upd[`checkpointbike_${i+1}`] = 'Bike' + ch.replace('.','_');
        })
        // console.log("checkpointsBike",checkpointsBike)

        checkpointsRun.forEach((ch,i) => {
            upd[`checkpointrun_${i+1}`] = 'Run' + ch.replace('.','_');
        })
        // console.log("checkpointsRun",checkpointsRun)

        console.log("upd",upd)
        update(upd);

        goToNextStep();
    }

    const SwimDistanceCheckpoints = race.RaceDiscipline === 'Triathlon' ?  
        <>
            <label>Swim: Distance From Start</label>
                <ol>
                    {( step.completed ? savedCheckpointsSwim : checkpointsSwim).map((checkpoint,i) => {
                        return (
                            <li key={i} className={classNames({
                                invalid: !!errors[i]
                            })}>
                                <input type="text" 
                                    disabled={!!step.completed}
                                    onChange={e => {
                                        if(!e.target){
                                            console.log('opps no target');
                                            return;
                                        }
                                        // let value = e.target && e.target.value;
                                        // let value = e.target && e.target.value;
                                        // try {
                                        //     value = parseFloat(value);
                                        // } catch (err){ console.log( 'could not parseFloat', value); }
                                        // value = parseFloat(value);

                                        let value = e.target && e.target.value;
                                        try {
                                            const isMiles = value.toLowerCase().includes('mi');  
                                            const isKM = value.toLowerCase().includes('km');  
                                            const isHalf = value.toLowerCase().includes('half');
                                            let distance = '';
                                            if (isMiles) {
                                                distance = value.toLowerCase().replace("mi", "");
                                                distance = distance.trim();
                                                value = distance + ' mi';
                                            } else if (isKM) {
                                                distance = value.toLowerCase().replace("km", "");
                                                distance = distance.trim();
                                                value = distance + ' km';
                                            } else if (isHalf) {
                                                value = 'Half'
                                                // if (race.RaceDistance === 'Marathon') {
                                                //     value = 'Half'
                                                // } else if (race.RaceDistance === 'Half Marathon') {

                                                // } else if (race.RaceDistance === '10 KM') {

                                                // } else if (race.RaceDistance === '5 KM') {

                                                // }
                                            } 

                                            
                                        } catch (err){ console.log( 'could not parseFloat', value); }
                                        
                                        // console.log('VALUE', e.target, e.target&&e.target.value, '->', value);

                                        setCheckpointsSwim(checkpointsSwim => [...checkpointsSwim.slice(0,i), value, ...checkpointsSwim.slice(i+1)] )
                                    } }
                                    value={checkpoint.replace('_','.')}
                                />
                                
                                { !step.completed && <div className="button" onClick={e => setCheckpointsSwim(checkpointsSwim => [...checkpointsSwim.slice(0,i), ...checkpointsSwim.slice(i+1)] ) }>-</div> }
                            </li>
                        )
                    })}


                    <li key='Swim Finish'>
                        {race.RaceDistance==='Iron' && '2.4 mi'}
                        {race.RaceDistance==='Half-iron' && '1.2 mi'}
                        {race.RaceDistance==='Olympic' && '1.5 km'}
                        {race.RaceDistance==='Sprint' && '750 m'}

                    </li>
                </ol>
                { !step.completed && <div className="button" onClick={e => setCheckpointsSwim(setCheckpointsSwim => ([...setCheckpointsSwim, '']))}>+</div> }

                <hr/>
        </>
        : null;

    const BikeDistanceCheckpoints = race.RaceDiscipline === 'Triathlon' ?  
        <>
            <label>Bike: Distance From Start</label>
                <ol>
                    {( step.completed ? savedCheckpointsBike : checkpointsBike).map((checkpoint,i) => {
                        return (
                            <li key={i} className={classNames({
                                invalid: !!errors[i]
                            })}>
                                <input type="text" 
                                    disabled={!!step.completed}
                                    onChange={e => {
                                        if(!e.target){
                                            console.log('opps no target');
                                            return;
                                        }
                                        // let value = e.target && e.target.value;
                                        // let value = e.target && e.target.value;
                                        // try {
                                        //     value = parseFloat(value);
                                        // } catch (err){ console.log( 'could not parseFloat', value); }
                                        // value = parseFloat(value);

                                        let value = e.target && e.target.value;
                                        try {
                                            const isMiles = value.toLowerCase().includes('mi');  
                                            const isKM = value.toLowerCase().includes('km');  
                                            const isHalf = value.toLowerCase().includes('half');
                                            let distance = '';
                                            if (isMiles) {
                                                distance = value.toLowerCase().replace("mi", "");
                                                distance = distance.trim();
                                                value = distance + ' mi';
                                            } else if (isKM) {
                                                distance = value.toLowerCase().replace("km", "");
                                                distance = distance.trim();
                                                value = distance + ' km';
                                            } else if (isHalf) {
                                                value = 'Half'
                                                // if (race.RaceDistance === 'Marathon') {
                                                //     value = 'Half'
                                                // } else if (race.RaceDistance === 'Half Marathon') {

                                                // } else if (race.RaceDistance === '10 KM') {

                                                // } else if (race.RaceDistance === '5 KM') {

                                                // }
                                            } 

                                            
                                        } catch (err){ console.log( 'could not parseFloat', value); }
                                        
                                        // console.log('VALUE', e.target, e.target&&e.target.value, '->', value);

                                        setCheckpointsBike(checkpointsBike => [...checkpointsBike.slice(0,i), value, ...checkpointsBike.slice(i+1)] )
                                    } }
                                    value={checkpoint.replace('_','.')}
                                />
                                
                                { !step.completed && <div className="button" onClick={e => setCheckpointsBike(checkpointsBike => [...checkpointsBike.slice(0,i), ...checkpointsBike.slice(i+1)] ) }>-</div> }
                            </li>
                        )
                    })}


                    <li key='Bike Finish'>
                        {race.RaceDistance==='Iron' && '112 mi'}
                        {race.RaceDistance==='Half-iron' && '90 km'}
                        {race.RaceDistance==='Olympic' && '40 km'}
                        {race.RaceDistance==='Sprint' && '20 km'}

                    </li>
                </ol>
                { !step.completed && <div className="button" onClick={e => setCheckpointsBike(checkpointsBike => ([...checkpointsBike, '']))}>+</div> }

                <hr/>
        </> : null;

    const RunDistanceCheckpoints = (race.RaceDiscipline === 'Run' || race.RaceDiscipline === 'Triathlon') ?  
        <>
            <label>Run: Distance From Start</label>
                <ol>
                    {( step.completed ? savedCheckpointsRun : checkpointsRun).map((checkpoint,i) => {
                        return (
                            <li key={i} className={classNames({
                                invalid: !!errors[i]
                            })}>
                                <input type="text" 
                                    disabled={!!step.completed}
                                    onChange={e => {
                                        if(!e.target){
                                            console.log('opps no target');
                                            return;
                                        }
                                        // let value = e.target && e.target.value;
                                        // let value = e.target && e.target.value;
                                        // try {
                                        //     value = parseFloat(value);
                                        // } catch (err){ console.log( 'could not parseFloat', value); }
                                        // value = parseFloat(value);

                                        let value = e.target && e.target.value;
                                        try {
                                            const isMiles = value.toLowerCase().includes('mi');  
                                            const isKM = value.toLowerCase().includes('km');  
                                            const isHalf = value.toLowerCase().includes('half');
                                            let distance = '';
                                            if (isMiles) {
                                                distance = value.toLowerCase().replace("mi", "");
                                                distance = distance.trim();
                                                value = distance + ' mi';
                                            } else if (isKM) {
                                                distance = value.toLowerCase().replace("km", "");
                                                distance = distance.trim();
                                                value = distance + ' km';
                                            } else if (isHalf) {
                                                value = 'Half'
                                                // if (race.RaceDistance === 'Marathon') {
                                                //     value = 'Half'
                                                // } else if (race.RaceDistance === 'Half Marathon') {

                                                // } else if (race.RaceDistance === '10 KM') {

                                                // } else if (race.RaceDistance === '5 KM') {

                                                // }
                                            } 

                                            
                                        } catch (err){ console.log( 'could not parseFloat', value); }
                                        
                                        // console.log('VALUE', e.target, e.target&&e.target.value, '->', value);

                                        setCheckpointsRun(checkpointsRun => [...checkpointsRun.slice(0,i), value, ...checkpointsRun.slice(i+1)] )
                                    } }
                                    value={checkpoint.replace('_','.')}
                                />
                                
                                { !step.completed && <div className="button" onClick={e => setCheckpointsRun(checkpointsRun => [...checkpointsRun.slice(0,i), ...checkpointsRun.slice(i+1)] ) }>-</div> }
                            </li>
                        )
                    })}

                    <li key='Run Finish'>
                        {(race.RaceDistance==='Iron' || race.RaceDistance==='Marathon') && '26.219 mi'}
                        {(race.RaceDistance==='Half-iron' || race.RaceDistance==='Half Marathon') && '13.109 mi'}
                        {(race.RaceDistance==='15 KM') && '15 km'}
                        {(race.RaceDistance==='Olympic' || race.RaceDistance==='10 KM') && '10 km'}
                        {(race.RaceDistance==='Sprint' || race.RaceDistance==='5 KM') && '5 km'}

                    </li>
                </ol>
        </> : null;
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="checkpoints_container">

                { SwimDistanceCheckpoints }
                { BikeDistanceCheckpoints }
                { RunDistanceCheckpoints }
                
                

                { !step.completed && <div className="button" onClick={e => setCheckpointsRun(checkpointsRun => ([...checkpointsRun, '']))}>+</div> }
            </div>



            <div className="buttons">
                <button onClick={goToPrevStep}>Previous</button>
                <button type="submit">Next</button>
            </div>
        </form>
    )
}
export default Step4