import React, { Component } from "react";

import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import { initBeacon } from '../../actions/fbuser'
import {auth, db} from '../../config/firebase'

import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import * as orgActions from '../../actions/org';
import steeltoe from 'steeltoe';
import * as orgsByIdActions from '../../actions/orgsById';
import classNames from 'classnames'
// import landscape from "/images/city-generic/generic-02.svg";
import "./HomePagePublic.scss";


import Headroom from 'react-headroom';

const Option = ({title, onClick, primary, secondary, bold, icon, iconHover}) => (
  <div className={classNames("Option", {primary, secondary, bold})} onClick={onClick}>
      {icon && (
          <>
          <div className="icon"><img src={icon} alt='MyRace' /></div>
          <div className="icon hover"><img src={iconHover} alt='MyRace' /></div>
          </>
      )}
      <div className="title">{title}</div>
  </div>
)



class HomePagePublic extends Component {

  // _prevOrgId = null;
  componentDidUpdate(prevProps){
      const currentOrgId = this.getCurrentOrgId();
      // console.log('this._prevOrgId;', this._prevOrgId);
      // console.log('currentOrgId;', currentOrgId);
      // if(this._prevOrgId!==currentOrgId && currentOrgId){
      //     this._prevOrgId=currentOrgId;
      //     this.props.orgsByIdActions.requestOrgById(currentOrgId);
      // }
  }

  getCurrentOrgId = () => {
      const { settings, fbuser, accessRolesByUser } = this.props;
      if(!fbuser) return null;
      let currentOrgId = settings.currentOrgId;


      if(!currentOrgId){
          currentOrgId = steeltoe(accessRolesByUser).get(`${fbuser.uid}.orgs.0.orgId`);
      }
      return currentOrgId;
  }



  handleOption = optionName => e => {
    // console.log('selected:', optionName);
    const {history, location, fbuserActions} = this.props;
    // const {fbuserProfile, history, location, onCloseRequest, fbuserActions,
    //     openKeepInTouch,//: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),  
    //     openPartnerWithUs,//: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),  
    //     openRequestRace,//: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),  
    // } = this.props;

    switch(optionName) {
      case 'openSwitchOrgModal':
          this.props.openSwitchOrgModal();
          break;

      case 'athletesOnMyRace':
          history.push({
              pathname: '/myrace-analytics',
              state: { from: location }
          });
          break;
      case 'adBuilder':
          history.push({
              pathname: '/adBuilder',
              state: { from: location }
          });
          break;
      case 'raceAnalytics':
          history.push({
              pathname: '/your-race-analytics',
              state: { from: location }
          });
          break;
      case 'checkpointAnalytics':
          history.push({
              pathname: '/checkpoint-analytics',
              state: { from: location }
          });
          break;
      case 'racesView':
          history.push({
              pathname: '/races-admin',
              state: { from: location }
          });
          break;
      case 'selectEvent':
          history.push({
              pathname: '/events',
              state: { from: location }
          });
          break;
      case 'racesAddNewRace':
          history.push({
              pathname: '/races-admin/new',
              state: { from: location }
          });
          break;
      case 'profile':
          history.push({
              pathname: '/account/profile',
              state: { from: location }
          });
          break;
      case 'members':
          history.push({
              pathname: '/admin/members',
              // state: { from: location }
          });
          break;
      case 'logout':
          auth.signOut();
          history.push('/');
          break;
      case 'help':
          fbuserActions.initBeacon();
  
          // window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
          window.Beacon("open");
          window.Beacon("once", "close", ()=>window.Beacon('destroy'));
          // window.Beacon('348e8d61-f31b-49a6-8dd0-22b721d423f2');
          break;
      case 'feedback':
          window.hj('trigger', 'hotjar-feedback');
          break;
      default:
          // no-op
          break;

    } // switch

  }


  // returns array of strings, eg. ['owner','admin']
  rolesForCurrentOrgId = () => {
    const { fbuser, accessRolesByUser } = this.props;
    const currentOrgId = this.getCurrentOrgId();
    const accessRoles = steeltoe(accessRolesByUser).get(`${steeltoe(fbuser).get('uid')}.orgs`) || [];
    let accessRolesByUid = {};
    let accessRolesForThisOrgId = [];
    currentOrgId && accessRoles.length &&  accessRoles.forEach(accessRole => {
      const { orgId } = accessRole;
      if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
      accessRolesByUid[orgId].push(accessRole);

      if (orgId === currentOrgId) {
        accessRolesForThisOrgId.push(accessRole.role);
      }
    });
    return accessRolesForThisOrgId;
  }

  numberOfOrgsForCurrentUser = () => {
    const { fbuser, accessRolesByUser } = this.props;
    const currentOrgId = this.getCurrentOrgId();
    const accessRoles = steeltoe(accessRolesByUser).get(`${steeltoe(fbuser).get('uid')}.orgs`) || [];
    let accessRolesByUid = {};
    // let accessRolesForThisOrgId = [];
    currentOrgId && accessRoles.length && accessRoles.forEach(accessRole => {
      const { orgId } = accessRole;
      if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
      accessRolesByUid[orgId].push(accessRole);
    });
    return Object.keys(accessRolesByUid).length;
  }


  render() {

    const { fbuserProfile } = this.props;

    const currentOrgId = this.getCurrentOrgId();
    
    const orgName = steeltoe(this.props).get(`orgsById.${currentOrgId}.data.name`) || '...';

    // const accessRoles = steeltoe(accessRolesByUser).get(`${steeltoe(fbuser).get('uid')}.orgs`) || [];

    

    const accessRolesForThisOrgId = this.rolesForCurrentOrgId();
    
    // let accessRolesByUid = {};
    // let accessRolesForThisOrgId = [];
    // currentOrgId && accessRoles.length &&  accessRoles.forEach(accessRole => {
    //   const { orgId } = accessRole;
    //   if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
    //   accessRolesByUid[orgId].push(accessRole);

    //   if (orgId === currentOrgId) {
    //     accessRolesForThisOrgId.push(accessRole.role);
    //   }
    // })

    
    // const numOrgs = Object.keys(accessRolesByUid).length;
    // const numOrgs = accessRolesForThisOrgId.length;
    // const numOrgs = this.numberOfOrgsForCurrentUser();


    // const isOwner=false;
    // const isOwner = ['owner'].includes(accessRolesForThisOrgId);
    const isOwner = accessRolesForThisOrgId.includes('owner');
    // const isAdmin = accessRolesForThisOrgId.includes('admin');

    // console.log("accessRolesForThisOrgId",accessRolesForThisOrgId);

    let rolesMessage = null;
    if (isOwner) {
      rolesMessage = 'You are the owner of this organization (all access).'
    } else {
      rolesMessage = currentOrgId && accessRolesForThisOrgId && 'You have the following access: ' + accessRolesForThisOrgId.join(', ');
    }

    
    const page = "Portal Home";
    return (
      <div className="HomePagePublic">
        <Headroom>
          <Header title={`Welcome`} page={page}/>
        </Headroom>


        <div className="MainContent">
            <div className="container">

                {!fbuserProfile && 
                  <div className="InfoCard" >

                      <h2>Welcome to the MyRace Partner Portal</h2>
                      <p>This portal serves as a destination for all things related to the administration, support and analysis of your event(s).</p>
                      <h3>Event Organizers</h3>
                      <p>If you are an Event Organizer, get started by creating an account for your Organization. You will then be able to invite other members of the event including staff and partners such as your Program Directors, Marketing Team and Timing Partner.</p>
                      <h3>Event Staff &amp; Timing Partners, etc</h3>
                      <p>If you are not an Event Organizer but affiliated with an Event, reach out to the event organizer and ask to be invited to their organization.</p>
                      <p>Contact <a href="#" onClick={this.handleOption('help')}>support</a> if you have any questions.</p>


                  </div>
                
                }

                {fbuserProfile && 
                  <>
                    <div className="InfoCard"> 
                      <h2>Hi {fbuserProfile.firstname},</h2>
                      <h3>You are logged into the {orgName} • MyRace Portal!</h3>
                      <h3>{rolesMessage}</h3>
                      <h3>What would you like to do:</h3>

                    </div>

                    
                    
                    
                    <div className="InfoCardsGrid">
                    

                    { fbuserProfile.isEventOrg && 
                      <>
                        <div className="InfoCard" style={{
                            width: 100,
                        }}>
                          <h3>RACE ADMINISTRATION</h3>
                          <Option title="Add a New Race" onClick={this.handleOption('racesAddNewRace')} bold />
                          <Option title="View/Edit your Races" onClick={this.handleOption('racesView')} bold />
                          {/* <Option title="Update Existing Race" onClick={this.handleOption('racesUpdateRace')} bold /> */}

                        </div>
                        <div className="InfoCard" style={{
                            width: 100,
                        }}>
                          <h3>VIEW RACES / ATHLETES</h3>
                          <Option title="Select Event" onClick={this.handleOption('selectEvent')} bold />

                        </div>


                        <div className="InfoCard" style={{
                          width: 100,
                        }}>
                          <h3>ANALYTICS</h3>
                          {/* <p># users who've viewed your races: N/A</p>
                          <p>Total # of views across your races: N/A</p>
                          <p>Most popular race: N/A</p> */}

                          <Option title="Athletes on MyRace" onClick={this.handleOption('athletesOnMyRace')} bold />
                          <Option title="Race Analytics" onClick={this.handleOption('raceAnalytics')} bold />
                          <Option title="Checkpoint Analytics" onClick={this.handleOption('checkpointAnalytics')} bold />
                          <Option title="Athlete Anomalies" onClick={this.handleOption('athleteAnonalies')} bold />
                          

                        </div>

                        <div className="InfoCard" style={{
                            width: 100,
                        }}>
                          <h3>HELP</h3>
                          <Option title="Help" onClick={this.handleOption('help')} bold />
                          <Option title="Feedback" onClick={this.handleOption('feedback')} bold />
      

                        </div>


                        <div className="InfoCard" style={{
                            width: 100,
                        }}>
                          <h3>ADS (Coming Soon)</h3>
                          <p>Ads Revenue Summary</p>
                          {/* <Option title="Ads Revenue Summary (Future)" onClick={this.handleOption('adsRevenueSummary')} bold /> */}
                          {/* <Option title="Ads Race Permissions" onClick={this.handleOption('adsRacePermissions')} bold /> */}

                        </div>
                      </>
                    }

                    { !fbuserProfile.isEventOrg && 
                      <>
                        <div className="InfoCard" style={{
                            width: 100,
                        }}>
                          <h3>ADS BUILDER</h3>
                          <Option title="Build your Ads on MyRace" onClick={this.handleOption('adBuilder')} bold />
                          <Option title="Add a New Race" onClick={this.handleOption('racesAddNewRace')} bold />

                        </div>

                        <div className="InfoCard" style={{
                            width: 100,
                        }}>
                          <h3>HELP</h3>
                          <Option title="Help" onClick={this.handleOption('help')} bold />
                          <Option title="Feedback" onClick={this.handleOption('feedback')} bold />
      

                        </div>


                      </>
                    }



                    </div>


                    
                  </>
                }



              

                
            </div>
          
        </div>


        <div className="HomePagePublicFooterBackground flexgrow" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>
    );
  }
}



const mapStateToProps = ({settings, requestsPending, fbuserProfile, fbuser,orgsById, accessRolesByUser}) => ({settings, requestsPending, fbuserProfile, fbuser, orgsById,accessRolesByUser});


// const mapState = ({fbuser, fbuserProfile})=>({fbuser, fbuserProfile})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
//   setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
//   incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
//   decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
//   sponsorsActions: bindActionCreators(sponsorsActions, dispatch),

    // allRacesActions: bindActionCreators(allRacesActions, dispatch),
    
    fbuserActions: bindActionCreators({initBeacon}, dispatch),
    orgActions: bindActionCreators(orgActions, dispatch),
    orgsByIdActions: bindActionCreators(orgsByIdActions, dispatch),
    openSwitchOrgModal: ()=>dispatch({type:'SET_SETTING', isSwitchOrgModalOpen: true}),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePagePublic);





