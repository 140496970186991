import { createSelector } from 'reselect'
import steeltoe from 'steeltoe'

const getSettings = state => state.settings;
const getFbuser = state => state.fbuser;
const getAccessRolesByUser = state => state.accessRolesByUser;
const getOrgsById = state => state.orgsById;


export const getCurrentOrgId = createSelector(
    getSettings, getFbuser, getAccessRolesByUser,
    (settings, fbuser, accessRolesByUser) => {

        if(!fbuser || fbuser.isAnonymous) return null;
        let {currentOrgId} = settings;

        if(!currentOrgId){
            currentOrgId = steeltoe(accessRolesByUser).get(`${fbuser.uid}.orgs.0.orgId`);
        }
        return currentOrgId;
    }

);


export const getCurrentOrgName = createSelector(
    getCurrentOrgId, getOrgsById,
    (currentOrgId, orgsById) => {
        return steeltoe(orgsById).get(`${currentOrgId}.data.name`) || '';
    }

)