import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});



export const ORGS_BY_USER_SET_STATUS = "ORGS_BY_USER/SET_STATUS";
export const SET_ORGS = "ORGS_BY_USER/SET_ORGS";


export default (state = initialState.accessRolesByUser, action) => {
  switch (action.type) {

    case ORGS_BY_USER_SET_STATUS: {
        // expected: action.uid
        // expected: action.requestStatus

        return update(state, {
            [action.uid]: { $auto: {
                requestStatus: { $set: action.data },
            }}
        });
    }

    case SET_ORGS: {
        // expected: action.uid
        // expected: action.members

        return update(state, {
            [action.uid]: { $auto: {
                requestStatus: { $set: 'received' },
                orgs: { $set: action.orgs },
            }}
        });
    }

        
    default:
      return state;
  }
};
