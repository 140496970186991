import React, { Component } from "react";
import ReactDOM from 'react-dom'
import './SignUpModal.scss'
// import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as Sentry from '@sentry/browser';

import * as fbuserActions from '../../actions/fbuser'
import { incrementPendingRequests, decrementPendingRequests } from '../../actions/requestsPending'
// import * as fborgActions from '../../actions/fborg'

import classNames from 'classnames'
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

import iconEmailBlue from "../../images/mail-01.svg"
// import iconEmailRed from "../../images/mail-02.svg"
// import iconEmailBlack from "../../images/mail-03.svg"
// import iconCalendarBlue from "../../images/date-01.svg"
// import iconCalendarRed from "../../images/date-02.svg"
// import iconCalendarBlack from "../../images/date-03.svg"
import iconUserBlue from "../../images/user-04.svg"
// import iconUserRed from "../../images/user-05.svg"
// import iconUserBlack from "../../images/user-06.svg"

// import imgSignup from "../../images/signup.svg"

import { withRouter } from 'react-router-dom'

// import imgLock from '../../images/lock.svg';
// import imgUnlock from '../../images/unlock.svg';
import {RemoveScroll} from 'react-remove-scroll';
// import moment from "moment";
import firebase, {auth} from '../../config/firebase'
// import mongoapi from '../../apis/mongoapi'
import TooltipText from '../../components/Tooltip/TooltipText'
import SignupOrgBrandFiler from './SignupOrgBrandFilter';
// import steeltoe from 'steeltoe'

import imgPreviewReveal from '../../images/preview-2.svg';
import imgPreviewNoReveal from '../../images/preview-1.svg';



class PasswordField extends Component {
    state = {
        reveal: false
    }
    toggle = () => this.setState( s => ({ reveal: !s.reveal }))

    render(){
        const { reveal } = this.state;
        const { title, name, onChange, value, error, disabled }  = this.props;
        return (
            <div className={classNames("control", "PasswordField", {invalid: !!error})}>
                <label> 
                    <span className="label">{ title }</span>
                    { reveal ?
                    <input name={ name } type="text" 
                        onChange={onChange} 
                        disabled={disabled}
                        value={value} 
                        />
                    :
                    <input name={ name } type="password" 
                        onChange={onChange} 
                        disabled={disabled}
                        value={value} 
                        />
                    }



                </label>
                
                <div className="RevealButton" onClick={this.toggle}>
                {reveal ?
                    <img src={imgPreviewNoReveal} alt='reveeal' />
                    :
                    <img src={imgPreviewReveal} alt='reveal' />
                }
                </div>
            </div>
        )
    }
}

class SignUpModal extends Component {
    constructor(props){
        super(props);

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);

    }

    state = {
        email: '',
        errors: {},
        formSubmitted: false,
        // isValidEmailHash: false,
        modalActivated: false, // used for animation, adds "activated" class after mount
        lockShakes: false,
        step: 1, // step 2 is the expanded step
        firstname: '',
        lastname: '',
        password: '',
        orgName: '',
        title: '',
        phone: '',
        username: '',
        processing: false,
        existingProfileFound: false,
        uncertainState: false,
        invalidEmailAttempt: false
    }



    

    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        // window.document.body.removeChild(this.contentEl);
        // window.clearTimeout(this._timeoutIdOpen);
        // window.clearTimeout(this._timeoutIdClipboard);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.settings.isSignUpModalOpen !== this.props.settings.isSignUpModalOpen && this.props.settings.isSignUpModalOpen){
            this.setState({
                firstname: this.props.settings.inviteeFirstName, 
                lastname: this.props.settings.inviteeLastName,
            })
        }
    }

    // getAvailableUsername = (firstname, lastname) => {

    //     console.log("STARTING");
    //     return new Promise((resolve, reject)=>{
    //         let countAttempts = 0;
            
    //         let username = (firstname+lastname).replace(/[^0-9a-z]/gi, ''); 
    //         username = username.replace(/\s/g, ''); //remove spaces
            
    //         console.log('getAvailableUsername, about to check',username);

    //         const checkIfUsernameAvailable = () => {
    //             // console.log('checkIfUsernameAvailable', username)
    //             return new Promise((resol, rejec)=>{

    //                 countAttempts++;
    //                 db.collection('users').where('username', '==', username).limit(1).get()
    //                     .then(snap => {
    //                         console.log('SNAPSHOT users by username==', username, snap.size, snap);
    //                         if(snap.size){
    //                             // username unavailable
    //                             resol(false);
    //                         } else {
    //                             // username is free!
    //                             resol(true);
    //                         }
    //                     })
    //                     .catch(err => {
    //                         console.log('FIrestore error:', err)
    //                         Sentry.captureMessage('getAvailableUsername failed firestore call!', err);
    //                         resol(true);
    //                     })
    //             })
    //         }

    //         const keepCheckingUntilAvailable = async () => {
    //             // console.log('keepCheckingUntilAvailable')
    //             if(countAttempts > 5){
    //                 reject('Error getting available username')
    //             }
    //             const isAvailable = await checkIfUsernameAvailable();
    //             if(isAvailable){
    //                 console.log('Username is available:', username)
    //                 resolve(username)
    //             } else {
    //                 // console.log('Username is NOT available:', username)
    //                 username = firstname+lastname+(`${Date.now()}`.slice(-3));
    //                 keepCheckingUntilAvailable();
    //             }
    //         }
    //         keepCheckingUntilAvailable();

    //     })
    // }


    open = () => {
        if( this.props.disabled ) { return; }
        window.scrollTo(0, 0);

        
        // ReactTooltip.hide();
        this.props.openModal();
    }
    close = () => {
        const emptyState = {
            email: '',
            firstname: '',
            lastname: '',
            password: '',
            orgName: '',
            title: '',
            phone: '',
            username: '',

            errors: {},
            formSubmitted: false,
            // isValidEmailHash: false,
            modalActivated: false, // used for animation, adds "activated" class after mount
            lockShakes: false,
            step: 1, // step 2 is the expanded step
            userAnsweredHasEmailAccess: null,
        }
        this.setState(emptyState);
        
        this.props.closeModal();
        

    }


    createAccount = async (e) => {
        e.preventDefault();
        let { email, password, firstname, lastname,
            orgName, title, phone
        } = this.state;
        const {fbuserActions, settings} = this.props;

        const isEventOrg = settings.signupOrgBrandOption === 'Event Organizer';

        const validationFailed = this.validateFields();
        // console.log("validationFailed",validationFailed);
        // console.log("errors",errors);
        // console.log("Object.keys(errors)",Object.keys(errors));

        if(validationFailed
            //&& Object.keys(errors).length
            ) {
            return;
        }

        if(settings.inviteeEmail) {
            email = settings.inviteeEmail;
        }
        // console.log('CREATING USER...', email);

        

        let username;
        try {
            // console.log('try getAvailableUsername',firstname,lastname);
            
            // username = await this.getAvailableUsername(firstname.toLowerCase().charAt(0), lastname.toLowerCase())
            // console.log('returned from getAvailableUsername',username);

            incrementPendingRequests()
            
            
            const getAvailableUsername = firebase.functions().httpsCallable('getAvailableUsername');
            const usernameResult = await getAvailableUsername({ firstname, lastname })
            username = usernameResult.data.username;
            // console.log('returned from getAvailableUsername',username);

            decrementPendingRequests()

            
            
        } catch(err){
            console.log('getAvailableUsername failed',err)
            Sentry.captureMessage('getAvailableUsername failed!', err);
            decrementPendingRequests()
        }

        incrementPendingRequests()

        auth.createUserWithEmailAndPassword(email, password).then(
            (userCredential)=> {
                const { user } = userCredential;


                const data= {  email, firstname, lastname, title, phone, orgName, username, isEventOrg
                            // , signedUpDate  
                        };
                if( settings.inviteToken  ){
                    const { inviteToken} = settings;
                    // console.log("About to createUserFromInvite",data,inviteToken)
                    
                    fbuserActions.createUserFromInvite({
                        fbuser: user, 
                        data: {...data, inviteToken}
                        // : {  email, firstname, lastname, title, phone, orgName, inviteToken, username
                        //     // , signedUpDate  
                        // }
                    });
                    
                } else {
                    
                    // console.log("About to createOrgAndUser",data)
                    
                    fbuserActions.createOrgAndUser({
                        fbuser: user, 
                        data
                        // : {  email, firstname, lastname, title, phone, orgName, username
                        //     // , signedUpDate  
                        // }
                    });
                    
                }
               

                decrementPendingRequests()
                this.close();

        }).catch((error) => {
            console.warn('Error creating user:', error);
            this.setFormErrorMessage({ form: error.message })
            decrementPendingRequests()
        });

    }


    validateFields = () => {
        // const { email, password, username, firstname, lastname } = this.state;
        // if(!email || !password || !username || !firstname || !lastname) {
        //     return true;
        // }
        const fields= ['email', 'password', 'firstname', 'lastname', 'orgName', 'title', 'phone' ];
        let validationFailed = false;
        let errors = {}
        for(let i = 0; i<fields.length; i++){
            const field = fields[i];
            const error = this.getValidationErrors(field, this.state[field])
            errors[field] = error;
            if(error) {
                validationFailed = true;
            }
        }
        this.setFormErrorMessage(errors);
        return validationFailed;
    }
    updateField = field => e => {
      

        let errors = {
            [field]: this.getValidationErrors(field, e.target.value),
            form: null
        };

        this.setFormErrorMessage(errors);
        
        this.setState({ [field]: e.target && typeof e.target.value!=='undefined' ? e.target.value : e })
    }

    getValidationErrors = (field, value) => {
        if(['firstname','lastname','title','phone'].includes(field)){
            return !value ? `${field} is required` : null;
        }
        if( ['password','orgName'].includes(field)
            && value.length < 3){
            return 'Password is invalid';
        }

        // console.log("CHECKING IF EMAIL VALID")
        if( field==='email'  &&  !(/.+@.+\..+/g).test(value) ) {
            return 'Email is invalid';
        }
        
        return null;
    }

    setFormErrorMessage = (err) => { // err:  { field: errorMessage }
        const errors = this.state.errors || {};
        let nextErrors = {...errors, ...err};
        let errs = {};
        Object.keys(nextErrors).forEach(key=>{
            if(nextErrors[key]){
                errs[key] = nextErrors[key]
            }
        });
        this.setState({errors: errs});
    }
    
    
    updateEmailField = e => {

        this.setState({ email: e.target.value });
        // this.requestValidation();
    }




    renderStep1 = () => {


        const { errors, email, uncertainState, firstname, lastname, password, processing, orgName, phone, title  } = this.state;

        const {settings} = this.props;

        const isEventOrg = settings.signupOrgBrandOption === 'Event Organizer';


        return (
            <form className="Step1" onSubmit={this.onStep1Submitted}>
              
                {/* <div className="SignUpImage">
                    {/* import imgRunner from "../../images/runner.svg"
                    import imgStopWatch from "../../images/stopwatch-02.svg" * /}
                    <img src={imgSignup} className="runner" />
                    {/* <img src={imgRunner} className="runner" />
                    <img src={imgStopWatch} className="runner" /> * /}
                </div> */}

                <h1>Partner • Sign Up</h1>

                <div className="Label"><i/><span>Partner Sign-up</span><i/></div>
                <br/>

                <SignupOrgBrandFiler />


                <div className={classNames("control", { invalid: !!this.state.errors.org })}>
                    <label> 
                        <span className="label">Organization</span>
                        <input name="orgName" type="text" 
                            disabled={!!settings.invitedFromOrgName}
                            placeholder={isEventOrg ?  'Your Organization Name...' : 'Your Brand Name...'}
                            onChange={this.updateField('orgName')} 
                            value={settings.invitedFromOrgName ? settings.invitedFromOrgName : orgName} 
                            // style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>
                

                <div className={classNames("control", { invalid: !!this.state.errors.title })}>
                    <label> 
                        <span className="label">Title</span>
                        <input name="title" type="text" 
                            placeholder={ 'Your Title...'}
                            onChange={this.updateField('title')} 
                            value={title} 
                            // style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>
            

                <div className={classNames("control", { invalid: !!this.state.errors.firstname })}>
                    <label> 
                        <span className="label">First Name</span>
                        <input name="firstname" type="text" 
                            placeholder={ 'Your First Name...'}
                            onChange={this.updateField('firstname')} 
                            value={firstname} 
                            style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>
                

                <div className={classNames("control", { invalid: !!this.state.errors.lastname })}>
                    <label> 
                        <span className="label">Last Name</span>
                        <input name="lastname" type="text" 
                            placeholder={ 'Your Last Name...'}
                            onChange={this.updateField('lastname')} 
                            value={lastname} 
                            style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>




                <div className={classNames("control", { invalid: !!this.state.errors.phone })}>
                    <label> 
                        <span className="label">Phone</span>
                        <input name="phone" type="text" 
                            placeholder={ 'Your Phone...'}
                            onChange={this.updateField('phone')} 
                            value={phone} 
                            // style={{backgroundImage: `url(${iconUserBlue})`}}
                            />
                    </label>
                </div>
                


                <label className={classNames({ error: errors.email })}>
                <span className="label">Email</span>
                    {/* <div className={classNames("label ", { error: errors['email'] })}> Email Address* </div> */}
                    <div className={classNames("control", { invalid: !!errors.email })}>
                        <input
                            disabled={uncertainState || settings.inviteeEmail }
                            type="email"
                            // className={classNames({emailValid:isValidEmailHash})}
                            name="email"
                            value={ settings.inviteeEmail ? settings.inviteeEmail : email}
                            onChange={this.updateEmailField}
                            placeholder="Type in your email..."
                            style={{backgroundImage:
                            `url(${iconEmailBlue})`}}
                        />
                    </div>
                </label>




                <PasswordField title="Password" name="password" 
                    onChange={this.updateField('password')} 
                    error={errors.password}
                    disabled={processing}
                    value={password} />


                
                { !!errors.form && <p className="error"> {errors.form} </p>}


                <div className="buttons">
                    <div className="button secondary" value="cancel" onClick={this.close}>Cancel</div>
                    <button value="continue" type="submit" onClick={this.createAccount}>Continue</button>
                </div>
            
            </form>
        )
    }



    renderModalContent = () => {
        const { modalActivated, step } = this.state;
        // const { settings } = this.props;


        return (
            ReactDOM.createPortal(
                // <RemoveScroll>
                <div className={classNames("SignUpModalContent", `step_${step}`, {
                    // KeepInTouchModalFormThankYou: formSubmitted
                    activated: modalActivated
                })}>

                    <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                        <div className="background"/>
                        <TooltipText><em>Close</em></TooltipText>
                        <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                    </div>

                    {this.renderStep1()}


                </div>
                // </RemoveScroll>
            , this.coverEl)
        )
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="SignUpModalCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }

    render(){
        const { settings: {isSignUpModalOpen = false} } = this.props;

        return (
            <>

                { isSignUpModalOpen && this.renderModalContent() }
                { isSignUpModalOpen && this.renderModal() }
            </>
        )
    }
}

// const mapState = ({settings}) => ({settings});

const mapState = state => ({
    settings: state.settings,
    users: state.users,
  })


const mapDispatch = dispatch => ({
    incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
    decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
    openModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: true}),
    closeModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: false}),
    // setLoginPopupOpen: () => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: 'login' }),
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    // fborgActions: bindActionCreators(fborgActions, dispatch),
    setUsersProperty: ({ EmailHash, username, profile, avatarImageUrl }) => dispatch({ type: "SET_USERS_PROPERTY", EmailHash, username, profile, avatarImageUrl  }),
    incrementPendingRequests, // <- this.props.incrementPendingRequests()
    decrementPendingRequests 
})



export default withRouter(connect(mapState, mapDispatch)(SignUpModal));
