import React from "react";
import firebase from '../../../config/firebase'
import { withRouter } from "react-router-dom";
import { useForm, ErrorMessage } from "react-hook-form";
import steeltoe from 'steeltoe'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from "moment";
import { toast } from 'react-toastify';
import { setFieldsForRace as setFieldsForRaceInRedux } from '../../../actions/racesByOrgId'
// import { incrementPendingRequests, decrementPendingRequests } from '../../../actions/requestsPending'


const RaceEditMainForm = ({ match, settings, race, setFieldsForRaceInRedux }) => {
    const { register, errors, handleSubmit } = useForm();

    const { racecode } = match.params;

    const orgId = settings.currentOrgId;

    
    // const page = "Race Edit";
    const raceDateStart = race && moment(race.RaceDateStart);
    // const raceDateStartTime = raceDateStart && raceDateStart.format("dddd, MMMM Do YYYY, h:mm:ss a");
    const raceDateStartTime = raceDateStart && raceDateStart.format("MM/DD/YYYY hh:mm:ss");
    // console.log("raceDateStartTime",raceDateStartTime);
    // const raceYear = raceDateStart && raceDateStart.year();
    // console.log("race",race);


    const onSubmit = data => {
        console.log('submitted data:', data);
        // console.log('data.RaceDateStart:', data.RaceDateStart);
        // console.log('moment(data.RaceDateStart)', moment(data.RaceDateStart));
        // console.log('moment(data.RaceDateStart).toDate().toISOString():', moment(data.RaceDateStart).toISOString());


        // this.props.incrementPendingRequests();

        const tmpMomentRaceDateStart = moment(data.RaceDateStart);


        const updateData = {
            EventCode: data.EventCode,
            EventSeriesCode: data.EventSeriesCode,
            RaceDateStart: tmpMomentRaceDateStart.format(),
            // RaceCode: data.RaceCode,
            RaceCode: racecode,
            RaceDistance: data.RaceDistance,
            RaceSpecialType: data.RaceSpecialType,
            RaceTerrainOption: data.RaceTerrainOption,
            RaceDiscipline: data.RaceDiscipline,
            EventName: data.EventName,
            RaceName: data.RaceName,
            RaceWebsiteURL: data.RaceWebsiteURL,
            AdminStatus: data.AdminStatus,
            Location: data.Location,
            CountryTwoCharCode: data.CountryTwoCharCode,
            DefaultRaceCodeForEvent: (data.DefaultRaceCodeForEvent.toLowerCase() === 'true'),
            ShowHalfSplits: (data.ShowHalfSplits.toLowerCase() === 'true'),
            EventListOrderIndex: parseInt(data.EventListOrderIndex),
            FemaleWRFinishTime: parseInt(data.FemaleWRFinishTime),
            MaleWRFinishTime: parseInt(data.MaleWRFinishTime),
            PhotoPartnerURL: data.PhotoPartnerURL,
            RaceAthleteCategories: data.RaceAthleteCategories,
            RaceDivisionType: parseInt(data.RaceDivisionType),
            RaceLabelForFilter: data.RaceLabelForFilter,
            RaceLandscapeImagePath: data.RaceLandscapeImagePath
        }
        
        const funcUpdateRace = firebase.functions().httpsCallable('updateRace');
        funcUpdateRace({ orgId, updateData }).then(result=>{
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // this.props.decrementPendingRequests();
            console.log('funcUpdateRace result:', racecode, result.data.data);
            toast("Race Saved!");
            setFieldsForRaceInRedux({ orgId, RaceCode: racecode, payload: updateData })
            // dispatch({ type: SET_DATA, racecode, data: result.data.data  });
        }).catch(e => {
            // this.props.decrementPendingRequests();
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.warn('error making request in funcUpdateRace',e)
        });
        
    }

    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">Race Date Start</span>
                                <input name="RaceDateStart" type="text" 
                                   value={raceDateStartTime}
                                   disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Date Start'
                                    })}
                                />
                                
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">Event Series Code</span>
                                <input name="EventSeriesCode" type="text" 
                                   value={race.EventSeriesCode}
                                   disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Event Series Code'
                                    })}
                                />
                                
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    disabled: true
                                    })}>
                            <label> 
                                <span className="label">Event Code</span>
                                <input name="EventCode" type="text" 
                                    disabled
                                    value={race.EventCode}
                                    ref={register({
                                        validate: value => value ? true : 'no Event Code'
                                    })}
                                    
                                />

                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">Race Discipline Type</span>
                                <input name="RaceDiscipline" type="text" 
                                   defaultValue={race.RaceDiscipline}
                                   disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Discipline Type'
                                    })}
                                />
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    disabled: true
                                    })}>
                            <label> 
                                <span className="label">Race Distance</span>
                                <input name="RaceDistance" type="text" 
                                   defaultValue={race.RaceDistance}
                                   disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Distance'
                                    })}
                                />
                                
                                
                            </label>
                        </div>
     

                        <div className={classNames("control", {
                                    disabled: true
                                    })}>
                            <label> 
                                <span className="label">Special Race Type</span>
                                <input name="RaceSpecialType" type="text" 
                                    placeholder={ 'N/A'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceSpecialType}
                                    disabled
                                    ref={register({
                                        // validate: value => value ? true : 'no Event Name'
                                        validate: true
                                    })}
                                    // ref={register({
                                    //     validate: value => value ? true : 'no value'
                                    //     // validate: {
                                    //     //     positive: value => parseInt(value, 10) > 0,
                                    //     //     lessThanTen: value => parseInt(value, 10) < 10
                                    //     //   }
                                    // })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceSpecialType">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    disabled: true
                                    })}>
                            <label> 
                                <span className="label">Race Terrain Option</span>
                                <input name="RaceTerrainOption" type="text" 
                                    placeholder={ 'RaceTerrainOption...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceTerrainOption}
                                    disabled
                                    ref={register({
                                        // validate: value => value ? true : 'no Event Name'
                                        validate: true
                                    })}
                                    // ref={register({
                                    //     validate: value => value ? true : 'no value'
                                    //     // validate: {
                                    //     //     positive: value => parseInt(value, 10) > 0,
                                    //     //     lessThanTen: value => parseInt(value, 10) < 10
                                    //     //   }
                                    // })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceTerrainOption">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">Race Code</span>
                                <input name="RaceCode" type="text" 
                                   value={race.RaceCode}
                                   disabled
                                   ref={register({
                                        validate: value => value ? true : 'no Race Code'
                                    })}
                                />
                                
                                
                            </label>
                        </div>


                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceName.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceName.message')
                                    })}>
                            <label> 
                                <span className="label">Event Name</span>
                                <input name="EventName" type="text" 
                                    placeholder={ 'EventName...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.EventName}
                                    ref={register({
                                        validate: value => value ? true : 'no Event Name'
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="EventName">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceWebsiteURL.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceWebsiteURL.message')
                                    })}>
                            <label> 
                                <span className="label">Website URL</span>
                                <input name="RaceWebsiteURL" type="text" 
                                    placeholder={ 'Website URL...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceWebsiteURL}
                                    ref={register({
                                        validate: value => value ? true : 'no Website URL'
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceWebsiteURL">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceName.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceName.message')
                                    })}>
                            <label> 
                                <span className="label">Race Name</span>
                                <input name="RaceName" type="text" 
                                    placeholder={ 'RaceName...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceName}
                                    ref={register({
                                        validate: value => value ? true : 'no Race Name'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceName">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('AdminStatus.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('AdminStatus.message')
                                    })}>

                                <span className="label">Publish Status</span>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Draft" ref={register({ required: true })} 
                                defaultChecked={race.AdminStatus === 'Draft'}
                                />
                                Draft
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Private" ref={register({ required: true })}
                                defaultChecked={race.AdminStatus === 'Private'}
                                 />
                                Private
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Public-Unofficial" ref={register({ required: true })}
                                defaultChecked={race.AdminStatus === 'Public-Unofficial'}
                                 />
                                Public (Unofficial)
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Public" ref={register({ required: true })}
                                defaultChecked={race.AdminStatus === 'Public'}
                                 />
                                Public (Official)
                                </label>
                                {/* <label><input style={{display:'inline', width: 20}} name="AdminStatus" type="radio" value="Archived" ref={register({ required: true })}
                                defaultChecked={race.AdminStatus === 'Archived'}
                                 />
                                Archived
                                </label> */}
                                
                                <ErrorMessage errors={errors} name="AdminStatus">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>




                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('Location.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('Location.message')
                                    })}>
                            <label> 
                                <span className="label">Location</span>
                                <input name="Location" type="text" 
                                    placeholder={ 'Location...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.Location}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="Location">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('CountryTwoCharCode.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('CountryTwoCharCode.message')
                                    })}>
                            <label> 
                                <span className="label">CountryTwoCharCode</span>
                                <input name="CountryTwoCharCode" type="text" 
                                    placeholder={ 'CountryTwoCharCode...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.CountryTwoCharCode}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="CountryTwoCharCode">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        
                     

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('DefaultRaceCodeForEvent.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('DefaultRaceCodeForEvent.message')
                                    })}>

                                <span className="label">Default RaceCode for Event</span>
                                <label><input style={{display:'inline', width: 20}} name="DefaultRaceCodeForEvent" type="radio" value="true" ref={register({ required: true })} 
                                defaultChecked={race.DefaultRaceCodeForEvent === true}
                                />
                                True
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="DefaultRaceCodeForEvent" type="radio" value="false" ref={register({ required: true })}
                                defaultChecked={race.DefaultRaceCodeForEvent === false}
                                />
                                False
                                </label>
                                
                                
                                <ErrorMessage errors={errors} name="DefaultRaceCodeForEvent">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('ShowHalfSplits.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('ShowHalfSplits.message')
                                    })}>

                                <span className="label">Show Half Splits</span>
                                <label><input style={{display:'inline', width: 20}} name="ShowHalfSplits" type="radio" value="true" ref={register({ required: true })} 
                                defaultChecked={race.ShowHalfSplits === true}
                                />
                                True
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="ShowHalfSplits" type="radio" value="false" ref={register({ required: true })}
                                defaultChecked={race.ShowHalfSplits === false}
                                />
                                False
                                </label>
                                
                                
                                <ErrorMessage errors={errors} name="ShowHalfSplits">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('EventListOrderIndex.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('EventListOrderIndex.message')
                                    })}>
                            <label> 
                                <span className="label">EventListOrderIndex</span>
                                <input name="EventListOrderIndex" type="text" 
                                    placeholder={ 'EventListOrderIndex...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.EventListOrderIndex}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="EventListOrderIndex">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">FemaleWRFinishTime</span>
                                <input name="FemaleWRFinishTime" type="text" 
                                    placeholder={ 'FemaleWRFinishTime...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    disabled
                                    defaultValue={race.FemaleWRFinishTime}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="FemaleWRFinishTime">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    disabled:true
                                    })}>
                            <label> 
                                <span className="label">MaleWRFinishTime</span>
                                <input name="MaleWRFinishTime" type="text" 
                                    placeholder={ 'MaleWRFinishTime...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    disabled
                                    defaultValue={race.MaleWRFinishTime}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="MaleWRFinishTime">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('PhotoPartnerURL.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('PhotoPartnerURL.message')
                                    })}>
                            <label> 
                                <span className="label">PhotoPartnerURL</span>
                                <input name="PhotoPartnerURL" type="text" 
                                    placeholder={ 'PhotoPartnerURL...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.PhotoPartnerURL}
                                    ref={register({
                                        // validate: value => value ? true : 'no value'
                                        validate: value => true
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="PhotoPartnerURL">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceAthleteCategories.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceAthleteCategories.message')
                                    })}>
                            <label> 
                                <span className="label">RaceAthleteCategories (AG, MA, VI, EL)</span>
                                <input name="RaceAthleteCategories" type="text" 
                                    placeholder={ 'RaceAthleteCategories...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceAthleteCategories}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceAthleteCategories">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                        {/* <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceDivisionType.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceDivisionType.message')
                                    })}>
                            <label> 
                                <span className="label">RaceDivisionType</span>
                                <input name="RaceDivisionType" type="text" 
                                    placeholder={ 'RaceDivisionType...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceDivisionType}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceDivisionType">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div> */}

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceDivisionType.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceDivisionType.message')
                                    })}>

                                <span className="label">RaceDivisionType</span>
                                <label><input style={{display:'inline', width: 20}} name="RaceDivisionType" type="radio" value="1" ref={register({ required: true })} 
                                defaultChecked={race.RaceDivisionType === 1}
                                />
                                {/* (1) M/F 01-19, 20-24, 25-29, 30-34, 35-39, 40-44, 45-49, 50-54, 55-59, 60-64, 65-69, 70-74, 75-79, 80-84, 85-89, 90-94, 95-99, 100+ */}
                                (1) M/F 01-19, 20-24, ... 85-89, 90-94, 95-99, 100+
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDivisionType" type="radio" value="2" ref={register({ required: true })}
                                defaultChecked={race.RaceDivisionType === 2}
                                />
                                {/* (2) M/F 01-17, 18-39, 40-44, 45-49, 50-54, 55-59, 60-64, 65-69, 70-74, 75-79, 80+ */}
                                (2) M/F 01-17, 18-39, 40-44, ... 75-79, 80+
                                </label>
                                <label><input style={{display:'inline', width: 20}} name="RaceDivisionType" type="radio" value="3" ref={register({ required: true })}
                                defaultChecked={race.RaceDivisionType === 3}
                                />
                                {/* (3) M/F 01-19, 20-24, 25-29, 30-34, 35-39, 40-44, 45-49, 50-54, 55-59, 60-64, 65-69, 70-74, 75-79, 80+ */}
                                (3) M/F 01-19, 20-24, ... 75-79, 80+
                                </label>
                                
                                
                                <ErrorMessage errors={errors} name="RaceDivisionType">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                

                        </div>

                        <div className={classNames("control", {
                                    invalid: !!steeltoe(errors).get('RaceLabelForFilter.message'),
                                    // valid: !!firstname
                                    valid: !steeltoe(errors).get('RaceLabelForFilter.message')
                                    })}>
                            <label> 
                                <span className="label">RaceLabelForFilter</span>
                                <input name="RaceLabelForFilter" type="text" 
                                    placeholder={ 'RaceLabelForFilter...'}
                                    // onChange={(e)=>{
                                    //     // updateField('MyRaceStatus')(e);
                                    //     triggerValidation('MyRaceStatus');
                                    // }}
                                    defaultValue={race.RaceLabelForFilter}
                                    ref={register({
                                        validate: value => value ? true : 'no value'
                                        // validate: {
                                        //     positive: value => parseInt(value, 10) > 0,
                                        //     lessThanTen: value => parseInt(value, 10) < 10
                                        //   }
                                    })}
                                />
                                
                                <ErrorMessage errors={errors} name="RaceLabelForFilter">
                                    {({ message }) => <p>Something wrong with this field: {message}</p>}
                                </ErrorMessage>
                                
                            </label>
                        </div>
                    

                        

                        <div className="buttons">
                            <button value="continue" type="submit">Save</button>
                        </div>
                        
                    </form>
    )
}


const mapState = ({settings, racesByOrgId})=>({settings, racesByOrgId});
const mapDispatch = ({ setFieldsForRaceInRedux, 
    //incrementPendingRequests, // <- this.props.incrementPendingRequests()
    // decrementPendingRequests 
});


export default withRouter(connect(mapState, mapDispatch)(RaceEditMainForm));
