import firebase, {auth} from '../config/firebase'
import { SET_STATUS, SET_DATA, SET_CHECKPOINTS, SET_FIELDS_FOR_RACE } from '../reducers/racesByOrgIdReducer'
import { getImagesForRaces } from './raceImages'

export const setCheckpoints = ({orgId, RaceCode, payload}) => ({
    type: SET_CHECKPOINTS,
    orgId, RaceCode, payload
});
export const setFieldsForRace = ({orgId, RaceCode, payload}) => ({
    type: SET_FIELDS_FOR_RACE,
    orgId, RaceCode, payload
});

export const requestRacesByOrgId = (orgId) => {
    return (dispatch, getState) => {
        // console.log('[requestRacesByOrgId]', orgId)

        return new Promise((resolve,reject)=>{

            if(!orgId || (!auth.currentUser && !auth.currentUser.isAnonymous)){
                reject();
                return;
            }

            dispatch({type:'INCREMENT_PENDING_REQUESTS'});

            dispatch({ type: SET_STATUS, orgId, requestStatus: 'pending' });

            const funcRequestRacesByOrgId = firebase.functions().httpsCallable('requestRacesByOrgId');
            funcRequestRacesByOrgId({ orgId }).then(result=>{
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                // console.log('funcRequestRacesByOrgId result:', orgId, result.data.data);

                
                const races = result.data.data;
                
                dispatch({ type: SET_DATA, orgId, data: races  });

                dispatch(getImagesForRaces({ races }));

                resolve(races);
                
            }).catch(e => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                console.warn('error making request in funcRequestRacesByOrgId',e);
                reject(e);
            });

        });


        // const handleError = e => {
        //     dispatch({type:'DECREMENT_PENDING_REQUESTS'});
        //     console.error("Error reading Org document: ", e);
        //     dispatch({ type: SET_STATUS, orgId, requestStatus: 'error' });
        // };

        // const handleSuccess = snap => {
        //     dispatch({type:'DECREMENT_PENDING_REQUESTS'});
        //     // console.log("Got Org by orgId data!", orgId, snap);

        //     const data = {...snap.data(), orgId: snap.orgId};
            
        //     dispatch({ type: SET_DATA, orgId, data  });
        // }

        
        // db
        //     .collection("orgs")
        //     .doc(orgId)
        //     .get()
        //     .then(handleSuccess)
        //     .catch(handleError);
        
    }
}