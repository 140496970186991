import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

export const SET_LOGS = "LOGS/SET_LOGS";



export default (state = initialState.logs, action) => {
  switch (action.type) {

    case SET_LOGS: {
        return action.logs;
    }

    default:
      return state;
  }
};
