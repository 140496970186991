import React, { Component, useEffect } from "react";
import ReactDOM from 'react-dom'
import './SwitchOrgModal.scss'
// import ReactTooltip from 'react-tooltip'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from "redux";
import * as orgsByIdActions from '../../actions/orgsById';
import * as fbuserActions from '../../actions/fbuser'
// import * as fborgActions from '../../actions/fborg'
import {db} from '../../config/firebase'
import classNames from 'classnames'
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';
import { getCurrentOrgName } from "../../re-selectors/getCurrentOrgId";

// import iconUserBlue from "../../images/user-04.svg"



import { withRouter } from 'react-router-dom'


import {RemoveScroll} from 'react-remove-scroll';
// import mongoapi from '../../apis/mongoapi'
import TooltipText from '../Tooltip/TooltipText'
import steeltoe from 'steeltoe'



class SwitchOrgModal extends Component {
    constructor(props){
        super(props);

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);

    }

    state = {
        // email: '',
        // errors: {},
        // formSubmitted: false,
        // isValidEmailHash: false,
        modalActivated: false, // used for animation, adds "activated" class after mount
       
        // processing: false,

    }


    componentDidMount(){
        
    }

    getOrgIds = () => {

        // Get the User ID
        const currentuseruid = steeltoe(this.props).get('fbuser.uid');
        // Get their access roles
        const accessRoles = steeltoe(this.props).get(`accessRolesByUser.${currentuseruid}.orgs`) || [];
        
        let accessRolesByUid = {};
        accessRoles.length && accessRoles.forEach(accessRole => {
          const { orgId } = accessRole;
          if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
          accessRolesByUid[orgId].push(accessRole);
        });
        return Object.keys(accessRolesByUid);
    }




    

    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        // window.document.body.removeChild(this.contentEl);
        // window.clearTimeout(this._timeoutIdOpen);
        // window.clearTimeout(this._timeoutIdClipboard);
    }


    open = () => {
        if( this.props.disabled ) { return; }
        window.scrollTo(0, 0);
        // ReactTooltip.hide();
        this.props.openModal();

        
        
    }
    close = () => {
        const emptyState = {
            email: '',

            errors: {},
            formSubmitted: false,
            modalActivated: false, // used for animation, adds "activated" class after mount
            
        }
        this.setState(emptyState);

        this.props.setSettings({ justLoggedIn: false });
        
        this.props.closeModal();
        

    }

    setCurrentOrg = (orgId) => async e => {
        this.props.setCurrentOrgId(orgId);

        try {
            await db
                .collection("users")
                .doc(this.props.fbuser.uid)
                .update({
                    currentOrgId: orgId
                });
        } catch (err) {
            console.log('ERROR saving currentOrgId', err)
        }

        this.props.setSettings({ justLoggedIn: false });

        
        this.props.closeModal();
    }


    switchOrg = (e) => {
        e.preventDefault();
        

    }




    renderModalContent = () => {
        const { modalActivated, step } = this.state;
        // const { settings } = this.props;

        const orgIds = this.getOrgIds();

        return (
            ReactDOM.createPortal(
                <RemoveScroll>
                <div className={classNames("SwitchOrgModalContent", `step_${step}`, {
                    // KeepInTouchModalFormThankYou: formSubmitted
                    activated: modalActivated
                })}>

                    <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                        <div className="background"/>
                        <TooltipText><em>Close</em></TooltipText>
                        <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                    </div>

                    { orgIds.map(orgId => {
                        return <OrgButton key={orgId} orgId={orgId} onClick={this.setCurrentOrg(orgId)}/>
                    })}



                </div>
                </RemoveScroll>
            , this.coverEl)
        )
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="SwitchOrgModalCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }

    render(){
        // const { children, settings: {isSwitchOrgModalOpen = false} } = this.props;
        const { settings: {isSwitchOrgModalOpen = false} } = this.props;

        return (
            <>

                { isSwitchOrgModalOpen && this.renderModalContent() }
                { isSwitchOrgModalOpen && this.renderModal() }
            </>
        )
    }
}

const OrgButton = ({orgId, onClick}) => {
    const orgsById = useSelector(s => s.orgsById);
    const orgName = steeltoe(orgsById).get(`${orgId}.data.name`);
    return (
        <div style={{
            border: '1px solid black',
            padding: 5,
            margin: 10,
        }} onClick={onClick}>
            { orgName || orgId }
        </div>
    )
};

// const mapState = ({settings}) => ({settings});

const mapState = state => ({
    settings: state.settings,
    users: state.users,
    fbuser: state.fbuser,
    accessRolesByUser: state.accessRolesByUser,
  })


const mapDispatch = dispatch => ({
    incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
    decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
    openModal: ()=>dispatch({type:'SET_SETTING', isSwitchOrgModalOpen: true}),
    closeModal: ()=>dispatch({type:'SET_SETTING', isSwitchOrgModalOpen: false}),
    setCurrentOrgId: currentOrgId => dispatch({ type: 'SET_SETTING', currentOrgId }),
    // setLoginPopupOpen: () => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: 'login' }),
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    // fborgActions: bindActionCreators(fborgActions, dispatch),
    orgsByIdActions: bindActionCreators(orgsByIdActions, dispatch),
    setUsersProperty: ({ EmailHash, username, profile, avatarImageUrl }) => dispatch({ type: "SET_USERS_PROPERTY", EmailHash, username, profile, avatarImageUrl  }),
    setSettings: settings => dispatch({ type: 'SET_SETTING', ...settings }),
})

export default withRouter(connect(mapState, mapDispatch)(SwitchOrgModal));
