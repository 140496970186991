import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ScrollerItems from './ui/ScrollerItems'
import {decrementPendingRequests, incrementPendingRequests} from '../../../../actions/requestsPending'
import firebase from '../../../../config/firebase'
import { connect } from 'react-redux'

const Step6 = ({goToNextStep, goToPrevStep, timingDataset, currentTimingDatasetId, update, decrementPendingRequests, incrementPendingRequests}) => {
    const { handleSubmit } = useForm();
    const [scrollerKey, setScrollerKey] = useState(Date.now());
   

    const onSubmit = data => {
        console.log('STEP 5 - submitted data:', data);

        // const { TimingDataOption1 } = data;

        update(data);

        goToNextStep();
    }


    const addSuggestedValues = () => {
        // const confirmed = window.confirm('Are you sure?');
        // if(!confirmed) return;


        incrementPendingRequests()
        
        setScrollerKey(null);
        const funcAddressCheck = firebase.functions().httpsCallable('addressCheck');
        funcAddressCheck({ 
            timingDatasetId: currentTimingDatasetId
        }).then(result=>{
            console.log('funcAddressCheck result:', result.data);
            decrementPendingRequests();
            setScrollerKey(Date.now());
            // window.location.reload();
        }).catch(e => {
            decrementPendingRequests()

            console.warn('error calling backend function',e)
        });
        
    }
    
    return (
        <>
            
            <div className="buttons">
                <button onClick={addSuggestedValues} disabled={!scrollerKey}>ADDRESS CHECK</button>
            </div>


            { scrollerKey ? <ScrollerItems key={scrollerKey} {...{timingDataset, currentTimingDatasetId, fields: [
                {name:'Bib', title: 'Bib'},
                {name:'Gender', title: 'Gender'},
                // {name:'FirstName', title: 'First Name', editable: true},
                // {name:'LastName', title: 'Last Name', editable: true},
                // {name:'Age', title: 'Age'},
                // {name:'Gender', title: 'Gender'},
                {name:'PrimaryBracket', title: 'PrimaryBracket'},
                {name:'IsElite', title: 'Elite'},
                {name:'IsPro', title: 'Pro'},
                {name:'IsVisuallyImpaired', title: 'VI'},
                {name:'IsAthenaClydesdale', title: 'ATH/CLY'},
                {name:'City', title: 'City', editable: true},
                // {name:'StateFullName', title: 'State Full', editable: true},
                {name:'StateCode', title: 'State Code', editable: true},
                {name:'ProvinceCode', title: 'Province Code', editable: true},
                // {name:'ProvinceFullName', title: 'Province Full Name', editable: true},
                {name:'OtherRegionCode', title: 'Other Region Code', editable: false},
                // {name:'MilitaryCodeFullName', title: 'Military Code Full Name', editable: false},
                {name:'MilitaryCode', title: 'Military Code', editable: false},
                {name:'CountryFullName', title: 'Country Full', editable: true},
                {name:'CountryTwoCharCode', title: 'Country Two Char', editable: true},
                {name:'CountryThreeCharCode', title: 'Country Three Char', editable: true},
            ]}}/> : null}


            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="buttons">
                    <button onClick={goToPrevStep} disabled={!scrollerKey}>Previous</button>
                    <button type="submit" disabled={!scrollerKey}>Next</button>
                </div>
            </form>
        </>
    )
}
export default connect(null,{decrementPendingRequests, incrementPendingRequests})(Step6)