import React from "react";
import steeltoe from 'steeltoe'
import { useForm } from "react-hook-form";



const Step2 = ({goToNextStep, goToPrevStep, timingDataset}) => {
    const { handleSubmit } = useForm();

    const onSubmit = data => {
        console.log('STEP 2 - submitted data:', data);

        goToNextStep();
    }

    const numRows = steeltoe( timingDataset).get('csvNumRows');
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            { numRows && numRows > 0 ? 
            <>
                <p>Number of athletes/times: { numRows } </p>
                {/* <p>Header: { steeltoe( timingDataset).get('csvHeaderRow') } </p> */}
            </> : 
            <p>Loading data file into MyRace...</p>
            }



            <div className="buttons">
                <button onClick={goToPrevStep}>Previous</button>
                <button disabled={!(numRows && numRows > 0)} type="submit">Next</button>
            </div>
        </form>
    )
}
export default Step2