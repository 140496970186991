import { SET_IMAGE_URL } from '../reducers/raceImagesReducer'
import { storage } from '../config/firebase'

export const setImageUrl = ({RaceCode, main }) => {
    return ({
        type: SET_IMAGE_URL,
        RaceCode, main
    })
}


export const getImagesForRaces = ({ races }) => {
    return async dispatch => {

        if(!races || !races.length) {
            return;
        }

        for (let i=0; i < races.length ; i++) {
            const race = races[i];

            let raceImageURL = '';
            try {
                raceImageURL = await getRaceImage(race);
            } catch(err){
                // console.log('Could not get image URL for race', race.RaceCode);
            }
            dispatch(setImageUrl({ RaceCode: race.RaceCode, main: raceImageURL }))
        }
    }
}




function getRaceImage(race){
    const imageRef = storage.ref().child(`${race.RaceCode}/images/racelogo`);
    return imageRef.getDownloadURL();
    // return new Promise((resolve,reject)=>{
    //     const imageRef = storage.ref().child(`${race.RaceCode}/images/racelogo`);
    //     imageRef.getDownloadURL()
    //         .then(url=>{
    //             console.log('Image url:', url);
    //             this.setState({ uploadedImage: url });
    //             resolve(url);
    //         })
    //         .catch(err=>{
    //             console.log('Error getting url for storage image', err);
    //             // err.code === "storage/object-not-found"
    //             reject(err);
    //         })
    // })
}