import { createSelector } from 'reselect'
import moment from 'moment'

// const getCurrentTimingDatasetId = state => state.settings.currentTimingDatasetId
const getLogs = state => state.logs;

const getRaceEventSeriesCodeFilterOption = state => state.settings.raceEventSeriesCodeFilterOption;
const isSuperAdmin = state => (state.fbuserProfile && state.fbuserProfile.email === 'angelo@myrace.com') ? true : false;

export const getLogsByDay = createSelector(
  [getLogs, getRaceEventSeriesCodeFilterOption],
  (logs, raceEventSeriesCodeFilterOption) => {

    let hours = {};
    let days = {};
    let pages = {};
    let raceCodes = {};


    const maxHours = 24;
    let curHours = 0;

    
    logs.forEach(log => {

      // CHECK AND INCLUDE ONLY THOSE LOGS THAT ARE PART OF THE EVENTSERIES WE ARE INTERESTED IN
      if( !isSuperAdmin && !raceEventSeriesCodeFilterOption.includes(log.payload.eventSeries) ){
        return;
      }
    
      /* 
        Let's build arrays for each of the dimensions:
        hours (by hour)
        days (by day)
        pages (by page)
        raceCode (by raceCode)
      */
     
      const hourDay = moment(log.created.seconds*1000).format('MM-DD');
      const hourHour = moment(log.created.seconds*1000).format('HH');
      if (hourHour == '00'){
        if(!hours[hourDay]){
          curHours ++;
          if (curHours <= maxHours){
            hours[hourDay] = [];
          }
        }
        if (curHours <= maxHours){
  
          hours[hourDay].push(log);
        }
      } else {
        if(!hours[hourHour]){
          curHours ++;
          if (curHours <= maxHours){
            hours[hourHour] = [];
          }
        }
        if (curHours <= maxHours){
  
          hours[hourHour].push(log);
        }
      }

      const date = moment(log.created.seconds*1000).format('MM-DD-YYYY');
      if(!days[date]){
        days[date] = [];
      }
      days[date].push(log);

      const pageName = log.payload && log.payload.pageName && log.payload.pageName.toLowerCase();
      if (pageName) {
        if(!pages[pageName]){
          pages[pageName] = [];
        }
        pages[pageName].push(log);
      }
      
      const raceCode = log.payload && log.payload.raceCode && log.payload.raceCode.toLowerCase();
      if (raceCode) {
        if(!raceCodes[raceCode]){
          raceCodes[raceCode] = [];
        }
        raceCodes[raceCode].push(log);
      }

    });


    /* 
      WE HAVE THE INDIVIDUAL LOGS CATEGORIZED BY HOUR, DAY, PAGE, RACECODE
      
        hours = { 
          '04-09': [{}, {}],  - array of logs for this hour
          '01': [{}, {}],  - array of logs for this hour
          '02': [{}, {}],  - array of logs for this hour
          ...
          ...
          '23': [{}, {}],  - array of logs for this hour
          '04-10': [{}, {}, {}],
        };

        days = { 
          '2020-04-09': [{}, {}],  - array of logs for this day
          '2020-04-10': [{}, {}, {}],
        };

        pages = { 
          'home': [{}, {}],  - array of logs for this page
          'welcome': [{}, {}, {}],
        };

        raceCodes = { 
          'cim-ma-2019': [{}, {}],  - array of logs for this page
          'mvm-ma-2010': [{}, {}, {}],
        };
      
      NOW, LET'S BUILD THE ARRAYS THAT WILL BE USED IN THE CHARTS
      NOTE THAT WE MAY NEED TO MANUFACTURE A LOG FOR THOSE DAYS THAT LOGS WERE NOT FOUND (DEFAULT numberOfLogs TO ZERO)

    */

   

    let logsByHourArray = Object.entries(hours).map(([hour, logs]) => {
      return {
        hour,
        numberOfLogs: logs.length,
      };
    });


    let logsByDayArray = Object.entries(days).map(([date, logs], index) => {
      return {
        date,
        numberOfLogs: logs.length,
      };
    });

    /*
    
    daysArray = [
      { date: '2020-04-09', numberOfLogs: 2, },
      { date: '2020-04-10', numberOfLogs: 3 },
    ]
    */

    let allDaysLogs = [];

    if(logsByDayArray && logsByDayArray.length){
      const earliestTimestamp = logsByDayArray.reduce((minTimestamp, log)=>{
        const timestamp = (new Date(log.date)).getTime();
        // const minTimestamp = (new Date(log.date)).getTime();
        return timestamp < minTimestamp ? timestamp : minTimestamp;
      }, (new Date(logsByDayArray[0].date)).getTime());

      const earliestDate = normalizeDate(new Date(earliestTimestamp));

      // console.log('earliestDate:', earliestDate);
      
      let allDays = [earliestDate];
      const today = normalizeDate(new Date());
      let counter = 0;
      while( allDays[allDays.length-1] !== today && counter < 10000) {
        const nextDay = tomorrow(allDays[allDays.length-1]);
        allDays.push(nextDay);
        counter++;
      }
      // console.log('allDays:', allDays);


      // logsByDayArray = { date, numberOfLogs };
      
      
      allDays.forEach((date,i) => {
        const log = logsByDayArray.find(log => log.date === date);
        if(log) {
          allDaysLogs.push(log);
        } else {
          allDaysLogs.push({ date, numberOfLogs: 0});
        }

      })
      
    }

    

    let logsByPageArray = Object.entries(pages).map(([pageName, logs]) => {
      return {
        pageName,
        numberOfLogs: logs.length,
      };
    });

    /*
    
    pagesArray = [
      { pageName: 'home', numberOfLogs: 2 },
      { pageName: 'welcome', numberOfLogs: 3 },
    ]

    */

    let logsByRaceCodeArray = Object.entries(raceCodes).map(([raceCode, logs]) => {
      return {
        raceCode,
        numberOfLogs: logs.length,
      };
    });

    /*
    
    pagesArray = [
      { pageName: 'home', numberOfLogs: 2 },
      { pageName: 'welcome', numberOfLogs: 3 },
    ]

    */

    

      return ({
        logsByHour: logsByHourArray,
        // logsByDay: logsByDayArray, 
        logsByDay: allDaysLogs, 
        logsByPage: logsByPageArray,
        logsByRaceCode: logsByRaceCodeArray});

      // return [ ...[{ date: '2020-04-07', numberOfLogs: 1 },{ date: '2020-04-08', numberOfLogs: 5 },], ...daysArray];


  }
)


const normalizeDate = date => {
  let mm = date.getMonth()+1;
  if(mm<10) mm = '0'+mm;
  let dd = date.getDate();
  if(dd<10) dd = '0'+dd;
  return `${mm}-${dd}-${date.getFullYear()}`;
};

const yesttomorr = (today, add) => {
  let date = new Date(today);
  date.setDate(date.getDate() + add);
  return normalizeDate(date) ;
}

const yesterday = today => yesttomorr(today, -1);
const tomorrow = today => yesttomorr(today, 1);

