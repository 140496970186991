import React from "react";

import { useForm } from "react-hook-form";



export const optionCheckboxesAndGroups = [
    {
        name: 'PrimaryBracket',
        title: 'Primary Bracket/Code',
        description: `Timing File has a 'Primary Bracket' or 'Code' column (i.e. Elite Men, Elite Women, Professional M/F, M/F Visually Impaired)`,
        raceDisciplines: ['All'],
        default: true,
        mayNeedMapping: true,
    },{
        name: 'CountryFullName',
        title: 'Country Full Name',
        description: 'Timing File has a field that lists the athlete full country name',
        raceDisciplines: ['All'],
        mayNeedMapping: true,
        groupName: 'Country'
    },{
        name: 'CountryTwoCharCode',
        title: 'Country Two Char Code',
        description: 'Timing File has a field that lists the athlete two character country code',
        raceDisciplines: ['All'],
        mayNeedMapping: true,
        groupName: 'Country'
    },{
        name: 'CountryThreeCharCode',
        title: 'Country Three Char Code',
        description: 'Timing File has a field that lists the athlete three character country code',
        raceDisciplines: ['All'],
        mayNeedMapping: true,
        groupName: 'Country'
    },{
        name: 'StateFullName',
        title: 'State Full Name',
        description: 'Timing File has a field that lists the athlete full state name',
        raceDisciplines: ['All'],
        mayNeedMapping: true,
        groupName: 'StateRegion'
    },{
        name: 'StateCode',
        title: 'State Code',
        description: 'Timing File has a field that lists the athlete state code',
        raceDisciplines: ['All'],
        mayNeedMapping: true,
        groupName: 'StateRegion'
    },
    {
        name: 'T1Leg1',
        title: 'T1 has two splits',
        description: `The T1 transition has two splits, time to get to transition area and the transition itself`,
        raceDisciplines: ['Triathlon'],
        default: true,
        mayNeedMapping: true,
    },
    // },{
    //     name: 'ProvinceFullName',
    //     title: 'Province Full Name',
    //     description: 'Timing File has a separate field that lists the athlete province full name',
    //     mayNeedMapping: true,
    // },{
    //     name: 'ProvinceCode',
    //     title: 'Province Code',
    //     description: 'Timing File has a separate field that lists the athlete province code',
    //     mayNeedMapping: true,
    // }
    // },{
    //     name: 'IsElite',
    //     title: 'Is Elite',
    //     description: 'Timing File has a separate field that lists the athlete province code',
    //     mayNeedMapping: false,
    // },{
    //     name: 'IsVisuallyImpaired',
    //     title: 'Is VI',
    //     description: 'Timing File has a separate field that lists the athlete province code',
    //     mayNeedMapping: false,
    // }
];


const Step3 = ({race, goToNextStep, goToPrevStep, timingDataset, currentTimingDatasetId, update, step}) => {
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        console.log('STEP 2 - submitted data:', data);


        let upd = {};
        optionCheckboxesAndGroups.forEach(opt => {
            if (opt.raceDisciplines.includes('All') || (race && opt.raceDisciplines.includes(race.RaceDiscipline))){
                upd[`_needs_mapping_${opt.name}`] = data[`_needs_mapping_${opt.name}`];
            }
        })

        update(upd);

        goToNextStep();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            { optionCheckboxesAndGroups.map(opt => {

                if (opt.raceDisciplines.includes('All') || (race && opt.raceDisciplines.includes(race.RaceDiscipline))){
                    return (
                        <div key={opt.name}>
                        <label>
                            <input 
                                disabled={!!step.completed}
                                style={{display:'inline', width: 20}} 
                                name={`_needs_mapping_${opt.name}`}
                                type="checkbox" 
                                ref={register} 
                                defaultChecked={!!timingDataset[`_needs_mapping_${opt.name}`]}
                                /> { opt.title }
                                <br/>
                                {opt.description} 
                                <br/><br/>
                        </label>
                        </div>
                    )
                }
            }) }




            <div className="buttons">
                <button onClick={goToPrevStep}>Previous</button>
                <button type="submit">Next</button>
            </div>
        </form>
    )
}
export default Step3