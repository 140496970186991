import React, { Component } from "react";
import SecuredRoute from './SecuredRoute/SecuredRoute';
import * as Sentry from '@sentry/browser';
import * as orgActions from './actions/org';
import * as racesByOrgIdActions from './actions/racesByOrgId';
import * as logsActions from './actions/logs';
import { bindActionCreators } from "redux";
// import SecuredRoute from './SecuredRoute/SecuredRoute';
// import ReactTooltip from 'react-tooltip'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import { HomePagePublic, EventsPage, AdBuilder, MyRaceAnalytics, YourRaceAnalytics, CheckpointAnalytics, ValidateInvite, MembersAdminPage, AccountProfile, ConfirmEmail, ResetPassword, SelectRace, RaceEdit, RaceNew, SelectAthlete} from "./pages";
// import styled from 'styled-components';


// import { db, auth, storage } from './config/firebase'
import { db, auth } from './config/firebase'
import steeltoe from 'steeltoe'
import SignUpModal from './components/Login/SignUpModal'
import SwitchOrgModal from './components/Login/SwitchOrgModal'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./App.scss";
import Spinner from './components/Spinner/Spinner'
import ListenerServerPingAlive from './components/Utils/ListenerServerPingAlive'
import LogsRequestor from './components/Utils/LogsRequestor'

import classNames from 'classnames'
import {
  Switch,
  Route
} from "react-router-dom";
import OrgsByIdGetter from "./components/Utils/OrgsByIdGetter";

// const CloseButton = ({ closeToast }) => <i className="Toastify__close-button" onClick={closeToast}>×</i>;

class App extends Component {

  
  componentDidMount() {
    this.setupSentry();
    this.setupFirebaseAuth();
    this.setupBeacon();
    this.setupHotJar();
  }

  componentDidUpdate(prevProps){
    // if( !steeltoe(prevProps).get('fbuserProfile.username') && steeltoe(this.props).get('fbuserProfile.username') ){
    //     this.getAvtrImg();
    // }

    const currentuseruid = steeltoe(this.props).get('fbuser.uid');
    // const currentuserIsAnonymous = steeltoe(this.props).get('fbuser.isAnonymous');


    const { settings, accessRolesByUser } = this.props;

    const currentUser = steeltoe(accessRolesByUser).get(currentuseruid);
    // console.log('current user orgs:', currentUser);
    
    if(currentUser && currentUser.orgs && (!settings.currentOrgId || settings.justLoggedIn) ){
      // console.log('App: orgs:', currentUser.orgs)
      
      
      let orgIds = this.getOrgIdsFromAccessRoles(currentUser.orgs);
      // console.log('App: orgs:', orgIds);
      if(orgIds.length===1){
        // set currentOrgId in settings
        // this.props.setCurrentOrgId(currentUser.orgs[0].orgId);
        this.props.setCurrentOrgId(orgIds[0]);
      }else if(orgIds.length>1){ // <- orgs is actually list of accessRoles

        // ask for the currentOrgId
        this.props.openSwitchOrgModal();
      }
    }

  }

  getOrgIdsFromAccessRoles = (accessRoles) => {
    let orgIds = [];
    accessRoles.length && accessRoles.forEach(accessRole => {
      if(!orgIds.includes(accessRole.orgId)) orgIds.push(accessRole.orgId);
    });
    return orgIds;
  }


  setupSentry = () => {
    Sentry.init({dsn: "https://6ed2485e23724487b138a7e30b412a13@sentry.io/1832772"});
  }

  setupBeacon = () => {

    // eslint-disable-next-line
    !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
    // window.Beacon('config', { color: "#008dfb" })    
    // window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');

  }

  setupHotJar = () => {

    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    !isLocalhost && (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1306196,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }


  setupFirebaseAuth = () => {
    auth.onAuthStateChanged( user => {
      // console.log('[onAuthStateChanged]', user)
      this.props.setFbUser(user);



      user && Sentry.setUser(
        { "id":user.uid,
          "email": user.email
        });

      // user && LogRocket.identify(user.uid, {
      //     // name: 'James Morrison',
      //     email: user && user.email,
        
      //     // Add your own custom user variables here, ie:
      //     // emailHash: user && user.emailHash
      //   });

      // user && Sentry.captureMessage('MyRace User Logged in!', 'debug');
      
      // this.getAvtrImg();

      if(user){
        this.startListeningToProfileChanges(user);
        this.startListeningToAccessRolesChanges(user);
        // this.startListeningToInvitesChanges(OrgId);
        // this.props.logsActions.requestLogs();
      } else {
        this.stopListeningToProfileChanges();
        this.stopListeningToAccessRolesChanges();
        // this.stopListeningToInvitesChanges();

      }
    })
  }


  startListeningToProfileChanges = (user) => {
    const {uid} = user;

    // this.checkUserProfileDoc(user);

    

    if(this._unsubFromProfileChanges) {
      return;
    }
    this._unsubFromProfileChanges = db
      .collection('users')
      .doc(uid)
      .onSnapshot(this.onProfileChange);

    if(!steeltoe(this.props.accessRolesByUser).get(`${uid}.requestStatus`)){
      this.props.orgActions.requestOrgs(uid);
    }
  }

  stopListeningToProfileChanges = () => {
    if(typeof this._unsubFromProfileChanges === 'function'){
      this._unsubFromProfileChanges();
      this._unsubFromProfileChanges = null;
      this.props.updateProfile(null);
    }
  }


  startListeningToAccessRolesChanges = (user) => {
    const {uid} = user;

    if(this._unsubFromAccessRolesChanges) {
      return;
    }

    const onAccessRolesChange = snap => {
      let accessRoles = [];
      snap.forEach( doc => {
          accessRoles.push({...doc.data(), id: doc.id});
      });
      // dispatch({ type: SET_ORGS, uid, orgs: accessRoles  });
      this.props.orgActions.setOrgsByUid({ uid, orgs: accessRoles });
    };
    
    this._unsubFromAccessRolesChanges = db.collection("accessRoles").where("uid", "==", uid)
      .onSnapshot(onAccessRolesChange);
  }


  stopListeningToAccessRolesChanges = () => {
    if(typeof this._unsubFromAccessRolesChanges === 'function'){
      this._unsubFromAccessRolesChanges();
      this._unsubFromAccessRolesChanges = null;
    }
  }






  onProfileChange = (doc) => {
    // console.log('PROFILE CHANGED:', doc, doc.data());
    // console.log("FBUSER",this.props.fbuser)
    // const {fbuser} = this.props;
    // console.log(doc.data())

    const profile = doc.data();

    if(!profile){
      console.log('NO PROFILE');
      return;
    }

    // this.props.updateProfile()
    this.props.updateProfile(profile);



    // console.log("setting current OrgId",profile.currentOrgId)
    
    this.props.setCurrentOrgId(profile.currentOrgId);
  }


  render() {
    const {requestsPending, accessRolesByUser, fbuser} = this.props;

    const currentuseruid = steeltoe(this.props).get('fbuser.uid');

    
    const accessRoles = steeltoe(this.props).get(`accessRolesByUser.${currentuseruid}.orgs`) || [];
    
    let accessRolesByUid = {};
    accessRoles.length &&  accessRoles.forEach(accessRole => {
      const { orgId } = accessRole;
      if(!accessRolesByUid[orgId]) accessRolesByUid[orgId] = [];
      accessRolesByUid[orgId].push(accessRole);
    })

    const numOrgs = Object.keys(accessRolesByUid).length;


    return (
      <div className={classNames("App", {
        'requests-pending': requestsPending > 0
      })}>


          <Spinner />
          <ToastContainer />
          <LogsRequestor />
          <OrgsByIdGetter />
          
          <ListenerServerPingAlive>
            <Switch>
              <Route exact path="/" component={HomePagePublic} />
              
              <Route exact path='/validateinvite/:token' component={ValidateInvite} />

              <Route exact path='/login/confirmemail' component={ConfirmEmail} />
              <Route exact path='/login/resetpassword' component={ResetPassword} />

              <SecuredRoute exact path={`/events`} component={EventsPage} />
              <SecuredRoute exact path={`/adbuilder`} component={AdBuilder} />
              <SecuredRoute exact path={`/races-admin`} component={SelectRace} />} />
              <SecuredRoute exact path={`/races-admin/new`} component={RaceNew} />} />
              <SecuredRoute exact path={`/races-admin/edit/:racecode`} component={RaceEdit} />} />
              <SecuredRoute exact path={`/myrace-analytics`} component={MyRaceAnalytics} />} />
              <SecuredRoute exact path={`/your-race-analytics`} component={YourRaceAnalytics} />} />
              <SecuredRoute exact path={`/checkpoint-analytics`} component={CheckpointAnalytics} />} />
              <SecuredRoute exact path={`/account/profile`} component={AccountProfile} />} />
              <SecuredRoute exact path={`/admin/members`} component={MembersAdminPage} />} />
              <SecuredRoute exact path={`/admin/org/:orgId/members`} component={MembersAdminPage} />} />

              <SecuredRoute exact path='/select-athlete/:eventCode' component={SelectAthlete} />
              <SecuredRoute exact path='/select-athlete/:eventCode/:raceCode' component={SelectAthlete} />
              <SecuredRoute exact path='/select-athlete/:eventCode/:raceCode/:athleteFilter' component={SelectAthlete} />


              <Route render={() => <div>Sorry, this page does not exist</div>} />

            </Switch>
          </ListenerServerPingAlive>
  
          <SignUpModal />
          <SwitchOrgModal />

      
      </div>
    );
  }
}
const mapStateToProps = ({fbuser, fbuserProfile, accessRolesByUser, settings}) => ({
  fbuser, fbuserProfile, accessRolesByUser, settings
})
const mapDispatchToProps = (dispatch) => ({
  updateProfile: payload => dispatch({ type:'SET_PROFILE_DOC', payload }),
  setFbUser: user => dispatch({ type: 'SET_FB_USER', user }),
  setCurrentOrgId: currentOrgId => dispatch({ type: 'SET_SETTING', currentOrgId }),
  openSwitchOrgModal: ()=>dispatch({type:'SET_SETTING', isSwitchOrgModalOpen: true}),
  orgActions: bindActionCreators(orgActions, dispatch),
  racesByOrgIdActions: bindActionCreators(racesByOrgIdActions, dispatch),
  logsActions: bindActionCreators(logsActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

