import React, { Component } from "react";
import { useForm } from "react-hook-form";
import ReactDOM from 'react-dom'
import './EditModal.scss'
// import ReactTooltip from 'react-tooltip'
// import { connect } from 'react-redux'
// import { bindActionCreators } from "redux";
// import * as orgsByIdActions from '../../../../../actions/orgsById';
// import * as fbuserActions from '../../../../../actions/fbuser'
// import * as fborgActions from '../../actions/fborg'
import {db} from '../../../../../config/firebase'
import classNames from 'classnames'
import clearIcon from '../../../../../images/close-4.svg';
import clearIconHover from '../../../../../images/close-3.svg';



// import { withRouter } from 'react-router-dom'


import {RemoveScroll} from 'react-remove-scroll';
// import mongoapi from '../../apis/mongoapi'
// import TooltipText from '../Tooltip/TooltipText'




function EditModalFormWrapper(props){
    const form = useForm();
    return <EditModal {...props} form={form} />
}


class EditModal extends Component {
    constructor(props){
        super(props);

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);

    }

    state = {
        modalActivated: false, // used for animation, adds "activated" class after mount
       
    }

    onSubmit = async submittedData => {
        console.log('Edit Modal Form - submitted data:', submittedData);

        const { fields, data, timingDataset, currentTimingDatasetId, updateItemByIdLocally } = this.props;

        // const { TimingDataOption1 } = data;

        const saveData = {};

        Object.keys(submittedData).forEach( key => {
            const field = fields.find( f => f.name === key);
            if(field.editable){

                let newValue = submittedData[key].trim();
                const originalValue = data[timingDataset[`map_field_${field.name}`]];

                if(newValue === originalValue) {
                    newValue = null;
                }

                saveData[`_edited_${key}`] = newValue;
            }
        })

        const docRef = db.collection('timingSetSteps').doc(currentTimingDatasetId).collection('csvItems').doc(data.id);

        try {
            await docRef.update(saveData);

            updateItemByIdLocally(data.id, saveData);
            
            console.log('Doc updated!');
            
        } catch( err){
            console.log('could not update firestore doc', err);
        }

        this.close();


    }

    

    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
    }


    open = () => {
        if( this.props.disabled ) { return; }
        window.scrollTo(0, 0);
        // ReactTooltip.hide();
        this.props.openModal();

        
        
    }
    close = () => {
        const emptyState = {
            modalActivated: false, // used for animation, adds "activated" class after mount
        }
        this.setState(emptyState);

        // this.props.setSettings({ justLoggedIn: false });
        
        this.props.closeModal();
    }


    renderModalContent = () => {
        const { modalActivated } = this.state;

        const { data, fields, timingDataset, form } = this.props;

        const { register, handleSubmit, watch, setValue } = form;
        
        return (
            ReactDOM.createPortal(
                <RemoveScroll>
                    <div className={classNames("EditModalContent", {
                        activated: modalActivated
                    })}>
                        <form onSubmit={handleSubmit(this.onSubmit)}>

                            <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                                <div className="background"/>
                                <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                                <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                            </div>

                            {/* { JSON.stringify(data) }  */}

                            { fields.map(field => {

                                const originalValue = data[timingDataset[`map_field_${field.name}`]];
                                const editedValue = data[`_edited_${field.name}`];

                                const refInputValue = watch(field.name);
                                
                                if(!field.editable){
                                    return (
                                        <p key={field.name}><strong>{field.title}:</strong> {originalValue}</p>
                                    )
                                }
                                return (
                                    <div key={field.name}>
                                        <label>
                                            {field.title}
                                            <input
                                                type="text"
                                                name={field.name}
                                                ref={register} 
                                                defaultValue={ typeof editedValue==='string'? editedValue : originalValue }
                                            />
                                            {
                                            (typeof refInputValue ==='string' && refInputValue!==originalValue ) ||
                                            (typeof refInputValue !=='string' && typeof editedValue ==='string' && editedValue!==originalValue )

                                            ? (
                                                <button onClick={e => setValue(field.name, originalValue)}>revert</button>
                                            ): null}
                                        </label>
                                    </div>
                                )
                            })}
                            {/* <button type="submit">Save</button> */}
                            <input type="submit" />
                        </form>
                    </div>
                </RemoveScroll>
            , this.coverEl)
        )
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="EditModalCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }

    render(){
        const { isOpen = false } = this.props;

        return (
            <>
                { isOpen && this.renderModalContent() }
                { isOpen && this.renderModal() }
            </>
        )
    }
}


export default EditModalFormWrapper;
